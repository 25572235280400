import Resizer from 'react-image-file-resizer';

export const resizeFile = (file, maxWidth = 800, maxHeight = 800) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      70,
      0,
      (uri) => {
        const resizedFile = new File([uri], file?.name, {
          type: file?.type,
          lastModified: file?.lastModified,
        });
        resolve(resizedFile);
      },
      'blob'
    );
  });
