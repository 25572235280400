import { Divider } from "@mui/material";
import React from "react";
import { ImagePlaceholderIcon } from "../../common/icons";

const SelectTemplate = ({ selectedCard, setSelectedCard, setCurrentStep }) => {
  const handleSelectedCard = (value) => {
    setSelectedCard(value);
  };

  return (
    <div className="flex flex-col gap-3">
      <p className="text-sm">Select a template below:</p>
      <div className="border grid grid-cols-4 gap-10 rounded-lg p-6">
        <div
          className="flex flex-col gap-3"
          onClick={() => handleSelectedCard(0)}
        >
          <div
            className={`border ${
              selectedCard === 0 ? "border-[#4c8c4a]" : ""
            } flex flex-col p-6 rounded-lg gap-3 h-[330px] overflow-y-auto`}
          >
            <p className="font-bold text-[16px]">Hello,</p>
            <p className="text-sm">
              This week at ...., we're thrilled to bring you the latest updates
              and insights. From groundbreaking product launches to invaluable
              industry trends, stay ahead with our curated content. Don't miss
              out on exclusive offers just for our subscribers!
            </p>
            <Divider />
            <p className="text-sm">Best,</p>
            <p className="text-sm">Your Name</p>
          </div>
          <p
            className={`text-lg ${
              selectedCard === 0 ? "text-[#4c8c4a]" : ""
            } font-bold flex justify-center`}
          >
            Newsletter
          </p>
        </div>
        <div
          className="flex flex-col gap-3"
          onClick={() => handleSelectedCard(1)}
        >
          <div className="flex flex-col gap-3">
            <div
              className={`border ${
                selectedCard === 1 ? "border-[#4c8c4a]" : ""
              } flex flex-col p-6 rounded-lg gap-3 h-[330px] overflow-y-auto`}
            >
              <p className="font-bold text-[16px]">
                See what’s new with [Org name]!
              </p>
              <p className="text-sm">
                Dive into what's new and noteworthy at [Your Org Name] this
                month.
              </p>
              <div className="flex flex-row gap-2">
                <ImagePlaceholderIcon className="w-[166px] h-[131px]" />
                <div className="flex flex-col gap-3">
                  <p className="text-xs font-bold">New Product Launches</p>
                  <p className="text-xs">We've expanded our offerings!</p>
                  <button
                    type="button"
                    className="text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    Explore
                  </button>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <p className="text-sm font-bold">New Product Launches</p>
                <ImagePlaceholderIcon />
              </div>
            </div>
            <p
              className={`text-lg ${
                selectedCard === 1 ? "text-[#4c8c4a]" : ""
              } font-bold flex justify-center`}
            >
              Sharing updates
            </p>
          </div>
        </div>
        <div
          className="flex flex-col gap-3"
          onClick={() => handleSelectedCard(2)}
        >
          <div
            className={`border ${
              selectedCard === 2 ? "border-[#4c8c4a]" : ""
            } flex flex-col p-6 rounded-lg gap-3 h-[330px] overflow-y-auto`}
          >
            <p className="font-bold text-[16px]">
              Introducing the new product line!
            </p>
            <p className="text-sm">Revive, Restore, Reimagine - Start Now!</p>
            <ImagePlaceholderIcon />
            <button
              type="button"
              className="text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            >
              Shop now
            </button>
          </div>
          <p
            className={`text-lg ${
              selectedCard === 2 ? "text-[#4c8c4a]" : ""
            } font-bold flex justify-center`}
          >
            Sell Products
          </p>
        </div>
        <div
          className="flex flex-col gap-3"
          onClick={() => handleSelectedCard(3)}
        >
          <div
            className={`border ${
              selectedCard === 3 ? "border-[#4c8c4a]" : ""
            } flex flex-col p-6 rounded-lg gap-3 h-[330px] overflow-y-auto`}
          >
            <p className="font-bold text-[16px]">Don’t Miss Out!</p>
            <p className="text-sm">Limited Time Only!</p>
            <ImagePlaceholderIcon />
            <p className="text-sm">
              Perfect timing to rejuvenate your cherished items!
            </p>
            <button
              type="button"
              className="text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            >
              Learn More
            </button>
          </div>
          <p
            className={`text-lg ${
              selectedCard === 3 ? "text-[#4c8c4a]" : ""
            } font-bold flex justify-center`}
          >
            Announce a Sale
          </p>
        </div>
      </div>
      <div className="flex flex-row justify-end">
        <button
          type="button"
          className={`text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2`}
        >
          Cancel
        </button>
        <button
          disabled={selectedCard === -1 ? true : false}
          type="button"
          className={`text-gray-900  ${
            selectedCard == -1 ? "bg-[#e3e3e3]" : "bg-[#4c8c4a] text-white"
          } border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2`}
          onClick={() => setCurrentStep(2)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectTemplate;
