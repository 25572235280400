import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BasicTabs, { CustomTabPanel } from "../common/components/Tabs";
import MappingField from "../components/POS/MappingField";
import { COBLRSHOP_PRODUCT_FIELDS, POS_TAB_LIST } from "../constants";
import EntityPage from "../components/EntityPage";
import Service from "../components/POS/EmbededTab/Service";
import Product from "../components/POS/EmbededTab/Product";
import Option from "../components/POS/EmbededTab/Option";
import CategoryType from "../components/POS/EmbededTab/CategoryType";

const POS = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get("tab") || POS_TAB_LIST[0].value;
  const [currentTab, setCurrentTab] = useState(initialTab);
  const [csvDataMapping, setCsvDataMapping] = useState([]);

  useEffect(() => {
    const tabValue = searchParams.get("tab") || POS_TAB_LIST[0].value;
    setCurrentTab(tabValue);

    if (!searchParams.has("tab")) {
      searchParams.set("tab", POS_TAB_LIST[0].value);
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [location.search, searchParams, navigate]);

  const handleTabChange = (newValue) => {
    const newTab = POS_TAB_LIST[newValue].value;
    setCurrentTab(newTab);
    searchParams.set("tab", newTab);
    navigate({ search: searchParams.toString() });
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target.result;
      const csvFields = csvData?.split("\n")[0]?.split(",");
      setCsvDataMapping(
        csvFields.map((item) => ({
          csvField: item,
          coblrshopField: COBLRSHOP_PRODUCT_FIELDS.includes(item) ? item : "",
          isMapField: COBLRSHOP_PRODUCT_FIELDS.includes(item),
        }))
      );
    };

    reader.readAsText(file);
  };

  const handleCheckedBox = (value, index) => {
    setCsvDataMapping((prevMapping) => {
      return prevMapping.map((item, i) => {
        if (i === index) {
          return { ...item, isMapField: value };
        }
        return item;
      });
    });
  };

  const handleDropdownChange = (value, index) => {
    setCsvDataMapping((prevMapping) => {
      return prevMapping.map((item, i) => {
        if (i === index) return { ...item, coblrshopField: value };

        if (item.coblrshopField === value) return { ...item, coblrshopField: "" };
        return item;
      });
    });
  };

  const renderTabPanel = (Component, index) => (
    <CustomTabPanel value={POS_TAB_LIST.findIndex(tab => tab.value === currentTab)} index={index}>
      {csvDataMapping.length === 0 ? (
        <Component onCSVUpload={uploadFile} />
      ) : (
        <MappingField
          csvFields={csvDataMapping}
          handleCheckedBox={handleCheckedBox}
          handleDropdownChange={handleDropdownChange}
          handleBack={() => setCsvDataMapping([])}
        />
      )}
    </CustomTabPanel>
  );

  return (
    <EntityPage title="Services & Products">
      <Box>
        <BasicTabs
          list={POS_TAB_LIST.map(tab => tab.label)}
          setValue={handleTabChange}
          value={POS_TAB_LIST.findIndex(tab => tab.value === currentTab)}
        />
        {renderTabPanel(Service, 0)}
        {renderTabPanel(Product, 1)}
        <CustomTabPanel value={POS_TAB_LIST.findIndex(tab => tab.value === currentTab)} index={2}>
          <Option />
        </CustomTabPanel>
        <CustomTabPanel value={POS_TAB_LIST.findIndex(tab => tab.value === currentTab)} index={3}>
          <CategoryType />
        </CustomTabPanel>
      </Box>
    </EntityPage>
  );
};

export default POS;
