import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, IconButton, Modal, Typography } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useSelector } from "react-redux";
import { Plus } from "../common/icons";
import { getRequest, updateRequest } from "../api";
import { OrderStatus } from "../constants/OrderStatus";
import { isAllProducts } from "../utils";
import MenuButton from "../components/Button/Menu";
import { POS_CREATE_ORDER } from "../constants/FrontendRoutes";
import MoveOrderModal from "../components/OrderOverview/MoveOrderModal";
import TrackNumberModal from "../components/OrderOverview/TrackNumberModal";
import LabelModal from "../components/Order/LabelModal";
import { selectCurrentUser } from "../redux/authSlice";
import { ORDERS, STORES } from "../constants/BackendRoutes";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { OrderOverviewTable } from "../components/OrderOverview/OrderOverviewTable";
import { OrderOverviewHeader } from "../components/OrderOverview/OrderOverviewHeader";

function OrderOverview() {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const [quoteOrders, setQuoteOrders] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [delayed, setDelayed] = useState([]);
  const [repaired, setRepaired] = useState([]);
  const [delivered, setDelivered] = useState([]);
  const [isMoveOrderModalOpen, setIsMoveOrderModalOpen] = useState(false);
  const [trackNumberModal, setTrackNumberModal] = useState(false);
  const [destinationName, setDestinationName] = useState("");
  const [isLabelModalOpen, setIsLabelModalOpen] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [user, setUser] = useState("");
  const [dragOrder, setDragOrder] = useState();
  const [movedCard, setMovedCard] = useState({
    startStatus: "",
    endStatus: "",
    source: {},
    destination: {},
  });

  const navigate = useNavigate();

  const {
    NEW_ORDER,
    SHIPPING,
    IN_PROGRESS,
    DELAYED,
    REPAIRED,
    DELIVERED,
    QUOTE,
  } = OrderStatus;

  const fetchRepairOrders = async () => {
    try {
      if (currentUser) {
        const data = await getRequest(
          `${STORES}/${currentUser?.stores[0].id}${ORDERS}`,
          {},
          "user.addresses,order_line_items,shipping_labels"
        );
        if (data?.length > 0) {
          setUser(data[0]?.user);
          setQuoteOrders(data?.filter((order) => order.status === QUOTE));
          setNewOrders(data?.filter((order) => order.status === NEW_ORDER));
          setShipping(data?.filter((order) => order.status === SHIPPING));
          setInProgress(data?.filter((order) => order.status === IN_PROGRESS));
          setDelayed(data?.filter((order) => order.status === DELAYED));
          setRepaired(data?.filter((order) => order.status === REPAIRED));
          setDelivered(data?.filter((order) => order.status === DELIVERED));
        }
      }
    } catch (err) {
      dispatch(addToast(err.message || "Something went Wrong!"));
      console.error(err);
    }
  };

  const orderLists = [
    { orders: newOrders, type: NEW_ORDER },
    { orders: shipping, type: SHIPPING },
    { orders: inProgress, type: IN_PROGRESS },
    { orders: delayed, type: DELAYED },
    { orders: repaired, type: REPAIRED },
    { orders: delivered, type: DELIVERED },
    { orders: quoteOrders, type: QUOTE },
  ];

  const sortOrdersByDate = (orders, dateField, orderCategory) => {
    let sortedOrders = [...orders];
    sortedOrders.sort((a, b) => {
      if (a[dateField] === null) return 1;
      if (b[dateField] === null) return -1;
      return new Date(a[dateField]) - new Date(b[dateField]);
    });
    setList(orderCategory, sortedOrders);
  };

  const sortAllOrdersByDate = (dateField) => {
    orderLists.forEach((orderList) =>
      sortOrdersByDate(orderList.orders, dateField, orderList.type)
    );
  };

  const sortAllOrdersByPriority = () => {
    orderLists.forEach((orderList) =>
      sortOrdersByRushFee(orderList.orders, orderList.type)
    );
  };

  const sortOrdersByRushFee = (orders, orderCategory) => {
    let sortedOrders = [...orders];
    sortedOrders.sort((a, b) => {
      if (a.rush_fee > 0 && b.rush_fee <= 0) {
        return -1;
      } else if (a.rush_fee <= 0 && b.rush_fee > 0) {
        return 1;
      } else {
        return 0;
      }
    });
    setList(orderCategory, sortedOrders);
  };

  const handleSortingChange = (sortingCriteria) => {
    if (sortingCriteria === "Completion Date") {
      sortAllOrdersByDate("estimated_completion");
    } else if (sortingCriteria === "Order Date") {
      sortAllOrdersByDate("created_at");
    } else if (sortingCriteria === "Priority") {
      sortAllOrdersByPriority();
    }
  };

  const handleIsMoveOrderModal = () => {
    setDestinationName("");
    setIsMoveOrderModalOpen(false);
  };

  const checkCanCardMove = (card, destination) => {
    if (card?.placement_type === "in_store" && destination === "shipping") {
      dispatch(addToast("We can not move in store order to shipping"));
      return false;
    } else if (
      isAllProducts(card) &&
      card.status !== destination &&
      destination !== "delivered"
    ) {
      dispatch(
        addToast(
          "Product only orders can only be moved to the delivered status"
        )
      );
      return false;
    } else return true;
  };

  const onCancelDrag = () => {
    const result = move(
      getList(movedCard.endStatus),
      getList(movedCard.startStatus),
      movedCard.destination,
      movedCard.source
    );
    setList(movedCard.endStatus, result[movedCard.endStatus]);
    setList(movedCard.startStatus, result[movedCard.startStatus]);
    setIsMoveOrderModalOpen(false);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    const startStatus = source.droppableId;
    const endStatus = destination.droppableId;

    if (endStatus === "quote") return;

    const draggedOrder = getList(startStatus).find(
      (order) => order.id.toString() === draggableId
    );
    setDragOrder(draggedOrder);

    if (!checkCanCardMove(draggedOrder, endStatus)) return;

    if (startStatus === endStatus) {
      const items = reorder(
        getList(startStatus),
        source.index,
        destination.index
      );
      setList(startStatus, items);
    } else {
      setIsMoveOrderModalOpen(true);
      setDestinationName(endStatus);

      const result = move(
        getList(startStatus),
        getList(endStatus),
        source,
        destination
      );
      setMovedCard({
        startStatus,
        endStatus,
        source,
        destination,
      });
      setList(startStatus, result[startStatus]);
      setList(endStatus, result[endStatus]);
    }
  };

  const handleCardSave = async ({ endStatus, dragOrderId = 0, sendEmail }) => {
    const data = { order: { status: endStatus }, is_send_email: sendEmail };
    if (endStatus === "shipping") {
      data["order"]["shipping_date"] = new Date();
    } else if (endStatus === "in_progress") {
      data["order"]["in_progress_date"] = new Date();
    }
    try {
      await updateRequest(
        `${STORES}/${currentUser?.stores[0]?.id}${ORDERS}/${dragOrderId}`,
        data
      );
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const getList = (status) => {
    switch (status) {
      case NEW_ORDER:
        return newOrders;
      case SHIPPING:
        return shipping;
      case IN_PROGRESS:
        return inProgress;
      case DELAYED:
        return delayed;
      case REPAIRED:
        return repaired;
      case DELIVERED:
        return delivered;
      default:
        return [];
    }
  };

  const setList = (status, items) => {
    switch (status) {
      case NEW_ORDER:
        setNewOrders(items);
        break;
      case SHIPPING:
        setShipping(items);
        break;
      case IN_PROGRESS:
        setInProgress(items);
        break;
      case DELAYED:
        setDelayed(items);
        break;
      case REPAIRED:
        setRepaired(items);
        break;
      case DELIVERED:
        setDelivered(items);
        break;
      case QUOTE:
        setQuoteOrders(items);
      default:
        break;
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const handleNotification = (value) => {
    handleCardSave({
      endStatus: movedCard.endStatus,
      dragOrderId: dragOrder.id,
      sendEmail: value,
    });
  };

  useEffect(() => {
    fetchRepairOrders();
  }, [currentUser]);

  return (
    <div className="bg-[#f8f8f8]">
      <div className="flex flex-col gap-5 max-w-[1440px] mx-auto py-5">
        <div className="flex justify-between items-center px-5">
          <div className="flex gap-2 items-center">
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <div className="text-zinc-900 text-2xl font-bold font-['Montserrat'] leading-[33.60px]">
                Order Overview
              </div>
            </div>
          </div>
          <IconButton>
            <NotificationsNoneIcon sx={{ color: "#939291" }} />
          </IconButton>
        </div>
        <Modal
          open={isMoveOrderModalOpen}
          onClose={() => handleIsMoveOrderModal()}
        >
          <MoveOrderModal
            handleNotification={(value) => handleNotification(value)}
            handleClose={() => handleIsMoveOrderModal()}
            openTrackNumberModal={() => setTrackNumberModal(true)}
            handleBack={onCancelDrag}
            order={dragOrder}
            destinationName={destinationName}
          />
        </Modal>
        <Modal
          open={trackNumberModal}
          onClose={() => setTrackNumberModal(false)}
        >
          <TrackNumberModal
            handleClose={() => setTrackNumberModal(false)}
            openLabelModal={() => setIsLabelModalOpen(true)}
            orderId={dragOrder?.id}
            fetchRepairOrders={fetchRepairOrders}
          />
        </Modal>
        <Modal
          open={isLabelModalOpen}
          onClose={() => setIsLabelModalOpen(false)}
        >
          <LabelModal
            handleClose={() => setIsLabelModalOpen(false)}
            customer={user}
            isRepairedOrder={
              [OrderStatus.REPAIRED, OrderStatus.DELIVERED].includes(
                movedCard.endStatus
              )
                ? true
                : false
            }
            orderId={dragOrder?.id}
          />
        </Modal>
        <div className="flex flex-col gap-5">
          <Divider />
          <div className="px-5">
            <OrderOverviewHeader
              setSelectedStatus={setSelectedStatus}
              selectedStatus={selectedStatus}
              newOrders={newOrders}
              shipping={shipping}
              inProgress={inProgress}
              delayed={delayed}
              repaired={repaired}
              delivered={delivered}
            />
          </div>
          <div className="flex flex-col gap-3 py-4 rounded-lg bg-white">
            <div className="flex flex-row justify-between w-full px-5">
              <MenuButton
                title="Sort by:"
                variant="outlinedSecondary"
                options={["Order Date", "Priority", "Completion Date"]}
                onChange={(value) => handleSortingChange(value)}
                mobileIcon={true}
              />
              <Button
                variant="containedPrimary"
                onClick={() => navigate(POS_CREATE_ORDER)}
                startIcon={<Plus />}
              >
                Add new order
              </Button>
            </div>
            <OrderOverviewTable
              onDragEnd={onDragEnd}
              selectedStatus={selectedStatus}
              quoteOrders={quoteOrders}
              newOrders={newOrders}
              shipping={shipping}
              inProgress={inProgress}
              delayed={delayed}
              repaired={repaired}
              delivered={delivered}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderOverview;
