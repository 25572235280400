import React from "react";

const GreenButton = (props) => {
  return (
    <div {...props}>
      <button className="all-[unset] inline-flex items-start relative flex-[0_0_auto] rounded-[8px] bg-green-700">
        <div className="inline-flex items-center justify-center gap-[8px] px-[16px] py-[10px] relative flex-[0_0_auto]rounded-[8px] overflow-hidden shadow-shadow-xs">
          <div className="relative w-fit mt-[-1.00px] font-body font-[number:var(--body-font-weight)] text-[#FFFFFF] text-[length:var(--body-font-size)] tracking-[var(--body-letter-spacing)] leading-[var(--body-line-height)] whitespace-nowrap [font-style:var(--body-font-style)]">
            {props.text}
          </div>
        </div>
      </button>
    </div>
  );
};
export default GreenButton;
