import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
  name: "toast",
  initialState: {
    showToast: false,
    toastMessage: "",
  },
  reducers: {
    addToast: (state, action) => {
      state.toastMessage = action.payload;
      state.showToast = true;
    },
    emptyToast: (state) => {
      state.toastMessage = "";
      state.showToast = false;
    },
  },
});

export const { addToast, emptyToast } = toastSlice.actions;
export default toastSlice.reducer;
