import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmationModal = ({
  open,
  onClose = () => {},
  onConfirm = () => {},
  title,
  message,
  description,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor,
  cancelButtonColor,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <Box
        className="bg-white rounded-lg shadow-md p-10 mx-auto"
        style={{
          width: '90%',
          maxWidth: '600px',
          outline: 'none',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
        }}
      >
        <Box className="flex justify-between items-start pb-2">
          <Typography
            id="confirmation-dialog-title"
            variant="h5"
            component="span"
            fontWeight="bold"
            style={{ maxWidth: '80%' }}
          >
            {title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ marginTop: '-8px' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="mt-4">
          {description && (
            <Typography variant="body1" className="mt-2">
              {description}
            </Typography>
          )}
          <Typography color="error" variant="body1" className="mt-4">
            {message}
          </Typography>
        </Box>
        <Box className="flex justify-end mt-10 space-x-2">
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ borderColor: cancelButtonColor, color: cancelButtonColor, minWidth: '120px' }}
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={onConfirm}
            variant="containedPrimary"
            style={{ backgroundColor: confirmButtonColor, color: 'white', minWidth: '120px' }}
          >
            {confirmButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  description: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  cancelButtonColor: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  title: 'Are you sure you want to delete this category type?',
  message: 'This action is permanent and cannot be undone.',
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
};

export default ConfirmationModal;
