import { Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, updateRequest } from "../api";
import EntityPage from "../components/EntityPage";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";
import { selectCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";

const EditOption = () => {
  const { id } = useParams();
  const [option, setOption] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectCurrentUser);

  useEffect(() => {
    if (userData) {
      fetchOptionData();
    }
  }, [userData]);

  const fetchOptionData = async () => {
    try {
      const data = await getRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/options/${id}`
      );

      if (data) {
        setOption(data);
      } else {
        dispatch(addToast("Invalid option data."));
      }
    } catch (error) {
      dispatch(addToast("Failed to fetch option data."));
    }
  };

  const handleInputChange = (name, value) => {
    const updatedOption = { ...option, [name]: value };
    setOption(updatedOption);
  };

  const handleUpdateOption = async () => {
    try {
      const { name, price, brand, cost_per_item } = option;

      const res = await updateRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/options/${id}`,
        { option: { name, price, brand, cost_per_item } }
      );

      if (res.status === 200) {
        dispatch(addToast("Option updated successfully."));
        navigate(-1);
      }
    } catch (error) {
      dispatch(addToast("Failed to update option."));
    }
  };

  if (!option) return null;

  return (
    <EntityPage title="Edit Option" breadcrumbs stickyBottomBar>
      <FormPage>
        <FieldGroup label="Option Details">
          <FieldItem>
            <Typography width="30%" variant="body">
              Option Name
            </Typography>
            <TextInput
              name="optionName"
              value={option.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Brand
            </Typography>
            <TextInput
              name="brand"
              value={option.brand}
              onChange={(e) => handleInputChange("brand", e.target.value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Cost per item
            </Typography>
            <TextInput
              name="price"
              type="number"
              value={option.cost_per_item}
              onChange={(e) =>
                handleInputChange("cost_per_item", e.target.value)
              }
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Price
            </Typography>
            <TextInput
              name="price"
              type="number"
              value={option.price}
              onChange={(e) => handleInputChange("price", e.target.value)}
            />
          </FieldItem>
        </FieldGroup>

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button variant="containedPrimary" onClick={handleUpdateOption}>
              Update
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default EditOption;
