import React, { useState } from "react";
import BasicTabs, { CustomTabPanel } from "../../common/components/Tabs";
import { ORDER_HISTORY_TAB_LIST } from "../../constants";
import { useNavigate, useLocation } from "react-router-dom";
import OrderAccordion from "./OrderAccordion";

const OrderHistory = ({ userData }) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const orders = userData?.orders || [];
  const quoteOrders = orders.filter((order) => order.status === "quote");
  const activeOrders = orders.filter((order) =>
    ["new_order", "shipping", "in_progress", "delayed"].includes(order.status)
  );
  const pastOrders = orders.filter((order) =>
    ["repaired", "delivered"].includes(order.status)
  );

  const initialTab = searchParams.get("tab") || ORDER_HISTORY_TAB_LIST[0].value;
  const [currentTab, setCurrentTab] = useState(initialTab);

  const handleTabChange = (newValue) => {
    const newTab = ORDER_HISTORY_TAB_LIST[newValue].value;
    setCurrentTab(newTab);
    searchParams.set("tab", newTab);
    navigate({ search: searchParams.toString() });
  };

  return (
    <section className="bg-white px-[32px] py-[24px] flex flex-col gap-[16px] rounded-[8px]">
      <div className="font-['Montserrat'] text-[24px] font-[700] text-[#272523]">
        Order history
      </div>
      <BasicTabs
        list={ORDER_HISTORY_TAB_LIST.map((tab) => tab.label)}
        setValue={handleTabChange}
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
      />
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={0}
      >
        <div className="flex flex-col gap-[24px]">
          {orders.map((order) => (
            <OrderAccordion order={order} />
          ))}
        </div>
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={1}
      >
        <div className="flex flex-col gap-[24px]">
          {quoteOrders.map((order) => (
            <OrderAccordion order={order} />
          ))}
        </div>
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={2}
      >
        <div className="flex flex-col gap-[24px]">
          {activeOrders.map((order) => (
            <OrderAccordion order={order} />
          ))}
        </div>
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={3}
      >
        <div className="flex flex-col gap-[24px]">
          {pastOrders.map((order) => (
            <OrderAccordion order={order} />
          ))}
        </div>
      </CustomTabPanel>
    </section>
  );
};

export default OrderHistory;
