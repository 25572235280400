import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4C8C4A"
    },
    secondary: {
      main: "#C9C8C8"
    },
    error: {
      main: "#dc3545" // currently (red) should be defined in future
    },
    warning: {
      main: "#ffc107" // currently (yellow) should be defined in future
    },
    info: {
      main: "#17a2b8" // currently (cyan) should be defined in future
    },
    success: {
      main: "#28a745" // currently (green) should be defined in future
    },
    abc: {
      main: "#272523" // currently (green) should be defined in future
    },
    text: {
      primary: "#272523",
      secondary: "#939291",
      disabled: "#999999", // should be defined in future
      hint: "#bbbbbb" // should be defined in future
    },
    background: {
      white: "#FFF",
      primary: "#4c8c4a1a",
      secondary: "#939291"
    },
    border: {
      primary: "#4C8C4A",
      secondary: "#C9C8C8"
    }
  },
  typography: {
    fontFamily: "Questrial, Roboto, Arial, sans-serif",
    title: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "140%"
    },
    subTitle: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "140%"
    },
    subHeading2: {
      fontFamily: "SF Pro Text",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "140%"
    },
    info: {
      fontFamily: "Questrial",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "140%"
    },
    body: {
      fontFamily: "Questrial",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "140%"
    },
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          height: "42px"
        },
        containedPrimary: {
          fontSize: "16px",
          padding: "10px 16px",
          border: "1px solid #4C8C4A",
          backgroundColor: "#4C8C4A",
          color: "#fff",
          "&:hover": {
            border: "1px solid #447E42",
            backgroundColor: "#447E42"
          },
          "&.Mui-disabled": {
            border: "1px solid",
            backgroundColor: "#c9c8c880",
            color: "#939291"
          }
        },
        outlinedPrimary: {
          fontSize: "16px",
          padding: "10px 16px",
          border: "1px solid #4C8C4A",
          color: "#4C8C4A",
          "&:hover": {
            backgroundColor: "rgba(76, 140, 74, 0.1)"
          },
          "&.Mui-disabled": {
            borderColor: "#E0E0E0",
            color: "#A0A0A0"
          }
        },
        outlinedSecondary: {
          fontSize: "16px",
          padding: "10px 16px",
          border: "1px solid #C9C8C8",
          color: "#272523",
          "&:hover": {
            backgroundColor: "rgba(201, 200, 200, 0.1)"
          },
          "&.Mui-disabled": {
            borderColor: "#E0E0E0",
            color: "#A0A0A0"
          }
        },
        outlinedTertiary: {
          fontSize: "16px",
          padding: "10px 16px",
          border: "1px solid #D0D5DD",
          color: "#272523",
          "&:hover": {
            borderColor: "black",
            backgroundColor: "transparent"
          },
          "&.Mui-disabled": {
            borderColor: "#E0E0E0",
            color: "#A0A0A0"
          }
        },
        textPrimary: {
          fontSize: "16px",
          padding: "10px 16px",
          border: "none",
          backgroundColor: "transparent",
          color: "#4C8C4A",
          width: "fit-content",
          "&:hover": {
            backgroundColor: "transparent"
          },
          "&.Mui-disabled": {
            color: "#939291"
          }
        },
        textSecondary: {
          fontSize: "16px",
          padding: "0px",
          margin: "16px",
          textTransform: "none",
          textDecoration: "underline",
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
            color: "#4C8C4A",
            "& .MuiSvgIcon-root": {
              color: "#4C8C4A"
            }
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: "#939291",
          "&.Mui-selected": {
            color: "#272523"
          },
          "&.Mui-disabled": {
            color: "#999999"
          }
        }
      }
    }
  }
});

export default theme;
