import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import InputMask from "react-input-mask";
import Selector from "../Form/Field/AutoComplete";
import { COUNTRIES, US_STATES, COMPANY_SIZE } from "../../constants";
import { validateEmail, validatePhoneNumber } from "../../utils";
import { selectCurrentUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";

const SetupCompany = ({ organizationData, setOrganizationData, handleBack, handleNext, activeStep }) => {
  const [submitEnable, setSubmitEnable] = useState(false);

  const userData = useSelector(selectCurrentUser);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectorChange = (field) => (_, value) => {
    handleChange({ target: { name: field, value: value?.value } });
  };

  useEffect(() => {
    const isPhoneNumberValid = validatePhoneNumber(organizationData.phone_number);
    const isEmailValid = validateEmail(organizationData.email);

    const addressFields = ["line", "city", "zipcode", "state", "country"];
    const areAllAddressFieldsFilled = addressFields.every(
      (field) => organizationData[field] !== ""
    );

    const isFormComplete =
      isEmailValid &&
      isPhoneNumberValid &&
      organizationData.name !== "" &&
      areAllAddressFieldsFilled;

    setSubmitEnable(isFormComplete);
  }, [organizationData]);

  useEffect(() => {
    if (userData?.organization) {
      const organization = userData?.organization
      setOrganizationData((prevData) => ({
        ...prevData,
        email: organization.email,
        name: organization.name,
        phone_number: organization.phone_number,
      }));
    }
  }, [userData?.organization])

  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <p className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 5
        </p>
      )}
      <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Please tell us more about your business
      </p>

      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Company name
          </p>
          <TextField
            id="companyName"
            placeholder="Please enter"
            variant="outlined"
            name="name"
            value={organizationData.name}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Company size
          </p>
          <Selector
            id="employee_count"
            name="employee_count"
            label="Please Select Company Size"
            size="large"
            options={COMPANY_SIZE}
            onChange={handleSelectorChange("employee_count")}
            value={COMPANY_SIZE.find(
              (size) => size.value === organizationData.employee_count
            )}
          />
        </div>
      </div>
      <hr className="my-3" />
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Billing address *
          </p>
          <TextField
            id="line"
            placeholder="Please enter"
            variant="outlined"
            name="line"
            value={organizationData.line}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Zipcode *
          </p>
          <InputMask
            mask="99999"
            value={organizationData.zipcode}
            onChange={handleChange}
          >
            {() => (
              <TextField
                type="tel"
                name="zipcode"
                placeholder="12345"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            City *
          </p>
          <TextField
            id="city"
            placeholder="Please enter"
            variant="outlined"
            name="city"
            value={organizationData.city}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            State *
          </p>
          <Selector
            name="state"
            label="Please select"
            size="large"
            options={US_STATES}
            onChange={handleSelectorChange("state")}
            value={US_STATES.find(
              (state) => state.value === organizationData.state
            )}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Country *
          </p>
          <Selector
            name="country"
            label="Please select"
            size="large"
            options={COUNTRIES}
            onChange={handleSelectorChange("country")}
            value={COUNTRIES.find(
              (country) => country.value === organizationData.country
            )}
          />
        </div>
      </div>

      <hr className="my-3" />

      <div className="flex flex-col gap-2">
        <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          Contact email
        </p>
        <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
          *Please add an email here if different from the email you used to sign
          up
        </p>
        <TextField
          id="contactEmail"
          placeholder="Please enter"
          variant="outlined"
          name="email"
          value={organizationData.email}
          onChange={handleChange}
        />
      </div>

      <div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Phone number *
          </p>
          <InputMask
            mask="(999) 999-9999"
            value={organizationData.phone_number}
            onChange={handleChange}
          >
            {() => (
              <TextField
                id="phone_number"
                name="phone_number"
                type="tel"
                placeholder="(123) 456-7890"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mt-4 ml-auto w-[90%]">
        <div></div>
        <Button
          variant="outlinedSecondary"
          className="w-full"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          disabled={!submitEnable}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SetupCompany;
