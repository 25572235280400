import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  IconButton,
  Divider,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { selectCurrentUser } from "../../../redux/authSlice";
import { DISCOUNT_TYPES, POS_NEW_ORDER_STEPS } from "../../../constants";
import { postRequest } from "../../../api";
import { addToast } from "../../../redux/toastSlice";
import { LeftArrowIcon } from "../../../common/icons";
import CartBadge from "./CartBadge";

const NewDiscount = ({ cart, setCart, currentStep, setCurrentStep }) => {
  const [newDiscountDetails, SetNewDiscountDetails] = useState({
    name: "",
    method_value: DISCOUNT_TYPES.percentage,
    value: null,
  });

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const handleCreateDiscount = async () => {
    try {
      const response = await postRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/discounts`,
        { discount: newDiscountDetails }
      );

      if (response.status == 201) {
        dispatch(addToast("Discount successfully added."));
        setCurrentStep(POS_NEW_ORDER_STEPS.ADD_DISCOUNT);
      }
    } catch (error) {
      dispatch(addToast(error.message));
    }
  };

  const handleChange = (event) => {
    SetNewDiscountDetails({
      ...newDiscountDetails,
      [event.target.name]:
        event.target.name === "value"
          ? parseFloat(event.target.value)
          : event.target.value,
    });
  };

  return (
    <Box className="relative flex flex-col w-full h-full bg-[#F8F8F8] lg:bg-white justify-between">
      <MediaQuery minWidth={1024}>
        <Box
          className="absolute flex items-center gap-1 top-[2.5%] left-[2.5%] cursor-pointer"
          onClick={() => {
            setCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER);
          }}
        >
          <LeftArrowIcon width={18} height={19} />
          <span>Back</span>
        </Box>
      </MediaQuery>
      <Box className="flex sm:h-auto flex-col justify-between h-full w-full sm:max-w-[75%] xl:max-w-[60%] sm:m-auto gap-4">
        <div className="flex flex-col gap-4 lg:mx-5">
          <CartBadge
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <Box className="flex flex-col sm:flex-row justify-between gap-2 sm:items-center">
            <Typography variant="body">Discount name</Typography>
            <TextField
              name="name"
              className="w-full sm:w-[70%]"
              placeholder="Please enter"
              value={newDiscountDetails.name}
              onChange={handleChange}
            />
          </Box>
          <Box className="flex flex-col sm:flex-row justify-between gap-2 sm:items-center">
            <Typography variant="body">Discount type</Typography>
            <TextField
              select
              name="method_value"
              className="w-full sm:w-[70%]"
              placeholder="Please select"
              value={newDiscountDetails.method_value}
              onChange={handleChange}
            >
              <MenuItem value={DISCOUNT_TYPES.percentage}>Percentage</MenuItem>
              <MenuItem value={DISCOUNT_TYPES.amount}>USD amount</MenuItem>
            </TextField>
          </Box>
          <Box className="flex flex-col sm:flex-row justify-between gap-2 sm:items-center">
            <Typography variant="body">
              {newDiscountDetails.method_value == "percentage"
                ? "Percent off"
                : "USD amount"}
            </Typography>
            <TextField
              type="number"
              name="value"
              className="w-full sm:w-[70%]"
              placeholder="Please enter"
              value={newDiscountDetails.value}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {newDiscountDetails.method_value ===
                    DISCOUNT_TYPES.percentage
                      ? "%"
                      : "$"}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </div>
        <div className="flex flex-col-reverse justify-end items-center gap-2 lg:mx-5 lg:mb-5 sm:my-5">
          <Button
            variant="outlinedSecondary"
            onClick={() => setCurrentStep(POS_NEW_ORDER_STEPS.ADD_DISCOUNT)}
            fullWidth
          >
            Cancel
          </Button>
          <Button
            variant="containedPrimary"
            disabled={!newDiscountDetails.name || !newDiscountDetails.value}
            onClick={handleCreateDiscount}
            fullWidth
          >
            Save
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default NewDiscount;
