import React from "react";

const FormPage = ({ children }) => {
  return (
    <div className="max-w-[640px] mt-5 mb-5">
      <form>
        {children}
      </form>
    </div>
  );
};

export default FormPage;
