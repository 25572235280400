import React from "react";

const Chip = ({ status }) => {
  const defaultStyles = {
    bgColor: "bg-gray-200",
    textColor: "text-gray-500",
    text: "Default",
  };

  const statusStyles = {
    delayed: {
      bgColor: "bg-[#f8e2c9]",
      textColor: "text-[#ca8a68]",
      text: "Delayed",
    },
    in_progress: {
      bgColor: "bg-[#d8ede0]",
      textColor: "text-[#5a8a6e]",
      text: "In progress",
    },
    shipping: {
      bgColor: "bg-[#e8daf8]",
      textColor: "text-[#6a549e]",
      text: "Shipping",
    },
    new_order: {
      bgColor: "bg-[#f3f0e8]",
      textColor: "text-[#bf975f]",
      text: "New",
    },
    repaired: {
      bgColor: "bg-[#e8e8e8]",
      textColor: "text-[#272523]",
      text: "Repaired",
    },
    quote: {
      bgColor: "bg-[#e8e8e8]",
      textColor: "text-[#272523]",
      text: "Quote",
    },
    delivered: {
      bgColor: "bg-[#e8e8e8]",
      textColor: "text-[#272523]",
      text: "Delivered",
    },
    early_adopters_price: {
      bgColor: "bg-orange-100",
      textColor: "text-orange-500",
      text: "Early adopters price!",
    },
    new_customer: {
      bgColor: "bg-[#D8EDE0]",
      textColor: "text-[#5A8A6E]",
      text: "New customer",
    },
  };

  const mergedStyles = {
    ...defaultStyles,
    ...(statusStyles[status] || {}),
  };

  const { bgColor, textColor, text } = mergedStyles;

  return (
    <div
      className={`px-2 py-1 ${bgColor} rounded-2xl justify-center items-center inline-flex w-fit`}
    >
      <div
        className={`text-center ${textColor} text-xs font-normal font-['Questrial'] leading-none`}
      >
        {text}
      </div>
    </div>
  );
};

export default Chip;
