import {
  Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { deleteRequest, getRequest } from "../../../api";
import { Plus } from "../../../common/icons";
import { POS, POS_CREATE_CATEGORY_TYPE } from "../../../constants/FrontendRoutes";
import { selectCurrentUser } from "../../../redux/authSlice";
import { addToast } from "../../../redux/toastSlice";
import ActionMenu from "../../Button/ActionMenu";
import CustomTable from "../../Table";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { selectAllCategories } from "../../../redux/storeSlice";

const columns = [
  {
    field: "mainCategory",
    label: "Main Category",
    collapsible: true,
    sortable: true,
    width: "200px",
  },
  {
    field: "categoryType",
    label: "Category Type",
    collapsible: false,
    sortable: true,
    searchable: true,
    width: "200px",
  },
  {
    field: "actions",
    label: "",
    collapsible: false,
    width: "10px",
    component: ActionMenu,
  },
];

const transformData = (
  allCategories,
  data,
  handleRemove = () => {},
  handleEdit = () => {},
  handleEditCategory = () => {},
) => {
  if (!data) return [];
  const groupedData = data?.reduce((acc, item) => {
    const category = item.category;

    if (!acc[category]) {
      acc[category] = {
        id: allCategories.find((item) => item?.name === category)?.id,
        mainCategory: category,
        collapse: [],
        actionsProps: {
          listOfActions: [{ label: "Edit", handler: handleEditCategory }],
        },
      };
    }
    acc[category].collapse.push({
      id: item.id,
      categoryType: item.name,
      actionsProps: {
        listOfActions: [
          { label: "Edit", handler: handleEdit },
          { label: "Remove", handler: handleRemove },
        ],
      },
    });
    return acc;
  }, {});

  return Object.values(groupedData);
};

function ActionItems() {
  const navigate = useNavigate();

  return (
    <Button
      variant="containedPrimary"
      onClick={() => navigate(POS_CREATE_CATEGORY_TYPE)}
      startIcon={<Plus />}
    >
      Add Category Types
    </Button>
  );
}

const CategoryType = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector(selectCurrentUser);
  const allCategories = useSelector(selectAllCategories)

  useEffect(() => {
    if (userData) {
      handleGetCategoryTypes();
    }
  }, [userData]);

  const handleGetCategoryTypes = async () => {
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/sub_categories`
      );
      setRows(transformData(allCategories, response, handleRemove, handleEdit, handleEditCategory));
    } catch (error) {
      dispatch(
        addToast("Failed to fetch category types", { appearance: "error" })
      );
    }
  };

  const handleRemove = (id) => {
    setCurrentId(id);
    setOpen(true);
  };

  const handleEdit = (id) => {
    navigate(`${POS}/edit/category-type/${id}`);
  };

  const handleEditCategory = (id) => {
    navigate(`${POS}/edit/category/${id}`);
  };

  const confirmRemove = async () => {
    try {
      await deleteRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/sub_categories/${currentId}`
      );
      setRows((prevRows) =>
        prevRows
          .map((row) => {
            const updatedCollapse = row.collapse.filter(
              (subRow) => subRow.id !== currentId
            );
            return { ...row, collapse: updatedCollapse };
          })
          .filter((row) => row.collapse.length > 0)
      );
      dispatch(addToast("Category type removed successfully."));
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    } finally {
      setOpen(false);
      setCurrentId(null);
    }
  };

  return (
    <div className="flex flex-col justify-between">
      <CustomTable
        columns={columns}
        rows={rows}
        sortable
        searchable
        searchType="Collapsed"
        actions={<ActionItems />}
      />

      <ConfirmationModal open={open} onClose={() => setOpen(false)} onConfirm={confirmRemove} />
    </div>
  );
};

export default CategoryType;
