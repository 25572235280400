import React from "react";
import OrderInfoCard from "./OrdersInfoCard";
import {
  BulletListIcon,
  DangerSignIcon,
  LeftArrowIcon,
  NotificationAlertIcon,
  SunArraysIcon,
} from "../../common/icons";
import { sumBy } from "../../utils";

export const OrderOverviewHeader = ({
  setSelectedStatus,
  selectedStatus,
  newOrders,
  inProgress,
  shipping,
  delayed,
  repaired,
  delivered,
}) => {
  return (
    <>
      <div className="font-['Questrial'] text-[16px] font-normal">
        Order summary
      </div>
      <div className="flex gap-3 items-center">
        {selectedStatus !== "" && (
          <div className="flex flex-row" onClick={() => setSelectedStatus("")}>
            <LeftArrowIcon />
            <p className="font-[questrial] text-xs font-normal">
              View all orders
            </p>
          </div>
        )}
        {(selectedStatus === "" || selectedStatus === "newOrders") && (
          <OrderInfoCard
            LeftIcon={
              <NotificationAlertIcon
                style={{ width: 35, height: 35 }}
                className="bg-[#f9f8f4] rounded-full"
              />
            }
            bgColor="bg-[#f3f0e8]"
            headerText="New Orders"
            mHeaderText="New"
            count={newOrders.length}
            onClick={() => setSelectedStatus("newOrders")}
          />
        )}
        {(selectedStatus === "" || selectedStatus === "inProgress") && (
          <OrderInfoCard
            LeftIcon={
              <SunArraysIcon
                style={{ width: 35, height: 35 }}
                className="bg-[#ecf6f0] rounded-full"
              />
            }
            bgColor="bg-[#d8ede0]"
            headerText="In Progress"
            mHeaderText="In Progress"
            count={inProgress.length}
            info="orders"
            onClick={() => setSelectedStatus("inProgress")}
          />
        )}
        {(selectedStatus === "" || selectedStatus === "delayed") && (
          <OrderInfoCard
            LeftIcon={
              <DangerSignIcon
                style={{ width: 35, height: 35 }}
                className="bg-[#fcf1e4] rounded-full"
              />
            }
            bgColor="bg-[#f8e2c9]"
            headerText="Delayed"
            mHeaderText="Delayed"
            count={delayed.length}
            info="orders"
            onClick={() => setSelectedStatus("delayed")}
          />
        )}
        {selectedStatus === "" && (
          <OrderInfoCard
            LeftIcon={
              <BulletListIcon
                style={{ width: 35, height: 35 }}
                className="bg-[#f4f4f4] rounded-full"
              />
            }
            bgColor="bg-[#e8e8e8]"
            headerText="Total active orders"
            mHeaderText="Total Active"
            count={`$
            ${(
              sumBy(newOrders, "net_total") +
              sumBy(shipping, "net_total") +
              sumBy(inProgress, "net_total") +
              sumBy(delayed, "net_total") +
              sumBy(repaired, "net_total") +
              sumBy(delivered, "net_total")
            ).toFixed(2)}`}
          />
        )}
      </div>
    </>
  );
};
