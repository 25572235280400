import * as React from "react";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const NumberInput = React.forwardRef(function CustomNumberInput(
  { quantity, setQuantity, ...props },
  ref
) {
  const handleChange = (event) => {
    const parsedValue = parseInt(event.target.value, 10);
    if (!isNaN(parsedValue)) {
      setQuantity(parsedValue);
    } else if (event.target.value === "") {
      setQuantity(1);
    }
  };

  const handleButtonClick = (direction) => {
    setQuantity((prev) => {
      if (direction === "decrement" && prev <= 1) {
        return prev;
      }
      return prev + (direction === "increment" ? 1 : -1);
    });
  };

  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: () => (
          <StyledButton
            className="increment"
            onClick={() => handleButtonClick("increment")}
          >
            <AddIcon fontSize="small" />
          </StyledButton>
        ),
        decrementButton: () => (
          <StyledButton
            className="decrement"
            onClick={() => handleButtonClick("decrement")}
          >
            <RemoveIcon fontSize="small" />
          </StyledButton>
        ),
      }}
      slotProps={{
        input: {
          onInput: handleChange,
        },
        button: {
          // No onClick handler needed here, handled by slots
        },
      }}
      {...props}
      value={quantity}
      ref={ref}
    />
  );
});

export default function QuantityInput({ quantity, setQuantity }) {

  return (
    <NumberInput
      aria-label="Quantity Input"
      min={1}
      defaultValue={1}
      quantity={quantity}
      setQuantity={setQuantity}
    />
  );
}

const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const green = {
  500: "#4C8C4A",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 8px;
`
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  height: 48px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };
  margin: 0;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;
  border-radius: 0;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[700] : blue[200]
    };
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
  background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  width: 48px;
  height: 48px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  margin: 0;

  &:hover {
    cursor: pointer;
    background: ${green[500]};
    border-color: ${green[500]};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
    border-radius: 0 8px 8px 0;
  }

  &.decrement {
    order: -1;
    border-radius: 8px 0 0 8px;
  }
`
);
