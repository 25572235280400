import { Button, Dialog, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Selector from '../Form/Field/AutoComplete';
import CloseIcon from '@mui/icons-material/Close';
import { TEAM_MEMBER_ROLES } from '../../constants';
import { PlusCircle } from '../../common/icons';
import { TEAM_MEMBER_FIELDS } from '../../pages/Onboarding';
import { validateEmail } from '../../utils';

const TeamModal = ({ currentTeam, maxTeam, open, setOpen, isAddTeam, userData, onConfirm }) => {
  const [AddTeamMember, setAddTeamMember] = useState(isAddTeam);
  const [currentTeamCount, setCurrentTeamCount] = useState(currentTeam);
  const [teamMembers, setTeamMembers] = useState([TEAM_MEMBER_FIELDS]);

  const resetTeamMembers = () => {
    if (!isAddTeam && userData) {
      setTeamMembers([userData]);
    } else {
      setTeamMembers([{ name: '', email: '', role: '' }]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setTeamMembers([{ name: '', email: '', role: '' }]);
  };

  const handleInputChange = (index, name, value) => {
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[index][name] = value;
    setTeamMembers(updatedTeamMembers);
  };

  const handleRemove = (index) => {
    const updatedTeamMembers = teamMembers.filter((_, i) => i !== index);
    setTeamMembers(updatedTeamMembers);
    setCurrentTeamCount(prev => prev - 1);
  }

  const handleMore = () => {
    if (currentTeamCount < maxTeam) {
      setCurrentTeamCount((prev) => prev + 1);
      setTeamMembers([...teamMembers, { name: '', email: '', role: '' }]);
    }
  };

  const handleSubmit = async () => {
    const response = await onConfirm(teamMembers);
    if (response === true) setTeamMembers([{ name: '', email: '', role: '' }])
  };

  const isAllFieldsFilled = teamMembers.every(
    (member) =>
      member.name.trim() !== '' &&
      member.email.trim() !== '' &&
      member.role &&
      validateEmail(member.email),
  );

  useEffect(() => {
    setAddTeamMember(isAddTeam);

    if (!isAddTeam && userData) {
      setTeamMembers([userData]);
    } else {
      setTeamMembers([{ name: '', email: '', role: '' }]);
    }
  }, [isAddTeam, userData]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: '20px',
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div className="flex flex-col gap-3 m-8">
        <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          {AddTeamMember ? 'Add Team Member' : 'Edit User Detail'}
        </div>

        {AddTeamMember && (
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Enter your team member’s information below, and we will send their invites through
            email.
          </p>
        )}

        {teamMembers &&
          teamMembers.map((member, index) => (
            <div key={index} className="flex flex-col gap-4">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-row justify-between items-center">
                  <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Employee’s Name
                  </p>
                  {index!==0 && <IconButton onClick={() => handleRemove(index)}>
                    <CloseIcon />
                  </IconButton>}
                </div>

                <TextField
                  placeholder="Jane Doe"
                  variant="outlined"
                  value={member?.name}
                  onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                />
              </div>

              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex flex-col gap-2 w-full">
                  <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Employee’s Email
                  </p>
                  <TextField
                    placeholder="example@gmail.com"
                    variant="outlined"
                    disabled={!isAddTeam}
                    value={member?.email}
                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Employee’s role
                  </p>
                  <Selector
                    name="state"
                    label="Please select"
                    size="large"
                    options={TEAM_MEMBER_ROLES}
                    value={TEAM_MEMBER_ROLES.find((role) => role?.value === member?.role) || null}
                    onChange={(_, value) => handleInputChange(index, 'role', value?.value)}
                  />
                </div>
              </div>
            </div>
          ))}
        {AddTeamMember && (
          <Button
            variant="textPrimary"
            startIcon={
              <PlusCircle
                fill={!isAllFieldsFilled && currentTeamCount < maxTeam ? 'gray' : 'green'}
              />
            }
            onClick={handleMore}
            disabled={!isAllFieldsFilled && currentTeamCount < maxTeam}
            sx={{ width: 'fit-content', marginLeft: 'auto' }}
          >
            Add More
          </Button>
        )}

        <div className="flex justify-end mt-10 space-x-2">
          <Button variant="outlinedSecondary" className="w-full" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="containedPrimary"
            className="w-full"
            disabled={!isAllFieldsFilled}
            onClick={handleSubmit}
          >
            {!AddTeamMember ? 'Save' : 'Send Invite'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default TeamModal;
