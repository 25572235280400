import axios from "axios";
import { GOOGLE_ADDRESS_VALIDATOR } from "../constants/thirdPartyApiUrls";
const { REACT_APP_GOOGLE_SECRET_KEY } = process.env;

export default async function validateAddress(address) {
  const url = `${GOOGLE_ADDRESS_VALIDATOR}?key=${REACT_APP_GOOGLE_SECRET_KEY}`;
  try {
    const result = await axios.post(url, address);
    const { hasUnconfirmedComponents } = result.data.result.verdict;
    return {
      data: result,
      status: !hasUnconfirmedComponents,
    };
  } catch (err) {
    return {
      error: err,
      status: false,
    };
  }
}
