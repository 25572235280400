import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardForm from "./CardForm";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { postRequest } from "../../api";
import { setLoading } from "../../redux/loadingSlice";

const { REACT_APP_STRIPE_PUBLISHABLE_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SetupPaymentMethod = ({ onPaymentMethodAttached }) => {
  const [clientSecret, setClientSecret] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        dispatch(setLoading(true));

        const response = await postRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/stripe/setup_intents`, {}
        );

        if (response && response.status === 200)
          setClientSecret(response.data.clientSecret);
      } catch (error) {
        dispatch(addToast(error || "Error."));
      } finally {
        dispatch(setLoading(false))
      }
    };

    fetchClientSecret();
  }, [dispatch]);

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      spacingUnit: "2px",
      borderRadius: "8px",
    },
    rules: {
      ".Input": {
        color: "#424770",
        padding: "12px",
        marginBottom: "8px",
      },
      ".Input:focus": {
        borderColor: "#B3B29A",
        boxShadow: "0 0 0 4px rgba(179, 178, 154, 0.25) inset",
      },
      ".Error": {
        color: "rgba(0, 0, 0, 0.26)",
        marginTop: "2px",
        marginBottom: "2px",
      },
    },
  };

  return (
    <Box className="flex flex-col w-full bg-white justify-between">
      <div className="flex flex-col gap-2 mx-8 mt-8">
        <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          Provide payment method
        </p>
        <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          Payment method you provide here will be used to charge the money against plan
        </p>
      </div>
      <Box className="flex flex-col bg-white w-full max-w-lg mx-auto my-8">
        {!!clientSecret && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, appearance }}
          >
            <CardForm onPaymentMethodAttached={onPaymentMethodAttached} />
          </Elements>
        )}
      </Box>
    </Box>
  );
};

export default SetupPaymentMethod;
