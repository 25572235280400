import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import LoginPNG from "../images/login.png";
import Logo from "../images/Logo.svg";
import Envelope from "../images/envelope.svg"
import { LOGIN, SIGNUP } from "../constants/FrontendRoutes";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";

const PasswordEmailSent = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  return (
    <div className="bg-gray-100 flex h-screen">
      <div className="w-full lg:w-2/5 my-auto">
        <div className="flex flex-col gap-7 w-[80%] md:w-[45%] lg:w-[70%] mx-auto">
          <img
            alt="logo"
            src={Logo}
            className="w-11 h-auto absolute top-[10%]"
          />
          <div>
            <img
              alt="logo"
              src={Envelope}
              className="w-24 h-auto mx-[50px] mb-[44px] "
            />
            <h2 className="text-[#272523] mb-[8px] text-[24px] font-bold font-['Montserrat'] leading-[33.60px]">
              Reset your password
            </h2>
            <span className="text-[#272523] text-[16px] font-normal font-['Questrial'] leading-snug flex items-center justify-between">
              We have sent an email to {email} with instructions to reset your
              password.
            </span>
          </div>
          <Button variant="outlined" color="secondary" href={LOGIN}>
            Back to log in
          </Button>
          <div className="border-[0.5px] border-b-neutral-200"></div>
        </div>
        <span className="flex justify-center my-8 items-center gap-2">
          <div className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
            New to Coblr?
          </div>
          <Link
            to={SIGNUP}
            className="text-right text-green-700 text-xs font-normal font-['SF Pro Display'] leading-tight tracking-tight"
          >
            Sign up now
          </Link>
        </span>
      </div>
      <div className="hidden lg:block w-3/5">
        <img
          src={LoginPNG}
          alt="Coblar"
          className="w-full h-screen object-cover"
        />
      </div>
    </div>
  );
};

export default PasswordEmailSent;
