import React, { useEffect, useState } from 'react';
import { TEAM_MEMBER_ROLES, USER_ROLES_ENUM, USER_ROLES } from '../../constants';
import Selector from '../Form/Field/AutoComplete';
import { PlusCircle } from '../../common/icons';
import { Button } from '@mui/material';
import MenuOptionButton from '../Button/MenuOptionButton';
import CrownIcon from '../../images/CrownIcon.svg';
import AddOnModal from '../Modal/AddOnModal';
import ConfirmationModal from '../Modal/ConfirmationModal';
import TeamModal from '../Modal/TeamModal';
import { useDispatch } from 'react-redux';
import { deleteRequest, getRequest, postRequest, updateRequest } from '../../api';
import { addToast } from '../../redux/toastSlice';
import { fetchUserData } from '../../redux/authActions';

const deleteUserProps = {
  title: 'Are you sure you want to delete this user?',
  description:
    'This user will lost access to their coblr account, all data related to the user will be removed.',
};

const ROLE_HIERARCHY = [USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.REPAIR_TEAM];

const default_team_member_limit = parseInt(process.env.REACT_APP_STRIPE_USERS_DEFAULT_LIMIT, 10);

const TeamMember = ({ userData, setOpenPlanModal }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [currentTeamCount, setCurrentTeamCount] = useState(0);
  const [maxTeam, setMaxTeam] = useState(default_team_member_limit);
  const [isHovered, setIsHovered] = useState(false);
  const [addOnModel, setAddOnModel] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isAddTeam, setIsAdTeam] = useState(null);
  const [isPaymentMethod, setIsPaymentMethod] = useState();
  const [subscription, setSubscription] = useState();
  const dispatch = useDispatch();

  const buttonData = [
    { name: 'edit', function: (id) => openEditModal(id), disabled: false },
    { name: 'delete', function: (id) => openDeleteModal(id), disabled: false },
  ];

  const getStoreStaffMembers = async () => {
    try {
      let response = await getRequest(
        `stores/${userData?.stores[0]?.id}/users`,
        {
          filters: { role: ROLE_HIERARCHY },
        },
        'stores_users',
      );
      const filteredUsers = response.filter((user) => user.id !== userData.id);
      const sortedUsers = filteredUsers.sort((a, b) => {
        return (
          ROLE_HIERARCHY.indexOf(a.stores_users?.[0].role) -
          ROLE_HIERARCHY.indexOf(b.stores_users?.[0].role)
        );
      });

      setTeamMembers([userData, ...sortedUsers]);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async () => {
    try {
      await deleteRequest(`stores/${userData.stores[0].id}/remove_team_member/${userId.id}`);
      dispatch(addToast('User Deleted Successfully'));
      setIsDeleteModalOpen(false);
      dispatch(fetchUserData(userData?.id));
      getStoreStaffMembers();
    } catch (error) {
      setIsDeleteModalOpen(false);
      dispatch(addToast(error));
      console.error(error);
    }
  };

  const editUserDetail = async (users) => {
    try {
      const updatedUser = users.map((user) => ({
        name: user.name,
        role: USER_ROLES_ENUM[user.role],
      }));

      await updateRequest(`stores/${userData.stores[0].id}/update_team_member/${userId.id}`, {
        user: {
          name: updatedUser[0].name,
          role: updatedUser[0].role,
        },
      });
      dispatch(addToast('User Details Updated Successfully'));

      setIsTeamModalOpen(false);
      getStoreStaffMembers();
    } catch (error) {
      setIsTeamModalOpen(false);
      dispatch(addToast(error));
      console.error(error);
    }
  };

  const AddTeamMember = async (team_member) => {
    try {
      const updatedData = team_member.map((user) => ({
        ...user,
        role: USER_ROLES_ENUM[user.role],
      }));

      const response = await postRequest(`stores/${userData.stores[0].id}/add_team_members`, {
        team_members: updatedData,
      });

      if (response.status === 201) {
        dispatch(addToast('Team Member Added'));
        dispatch(fetchUserData(userData?.id));
      }

      setIsTeamModalOpen(false);
      getStoreStaffMembers();
      return true;
    } catch (error) {
      dispatch(addToast(error));
      console.error(error);
      return false;
    }
  };

  const handleAddOnSubscription = () => {
    if (!isPaymentMethod) {
      dispatch(addToast('Add Payment Method'));
      setOpenPlanModal(true);
      return;
    }

    if (!subscription) {
      dispatch(addToast('Cannot Add Additional Members on this Plan. Subscribe to a Paid Plan.'));
      setOpenPlanModal(true);
      return;
    }

    setAddOnModel(true);
  };

  const openEditModal = (id) => {
    const role = id?.stores_users?.find(su => su.store_id === userData?.stores[0]?.id).role;
    setUserId({ id: id.id, name: id.name, email: id.email, role });
    setIsAdTeam(false);
    setIsTeamModalOpen(true);
  };

  const openDeleteModal = (id) => {
    setUserId(id);
    setIsDeleteModalOpen(true);
  };

  const openAddMemberModal = () => {
    setIsAdTeam(true);
    setIsTeamModalOpen(true);
  };

  useEffect(() => {
    getStoreStaffMembers();
  }, []);

  useEffect(() => {
    if (userData) {
      setIsPaymentMethod(userData?.organization?.payment_method_id ? true : false);
      setSubscription(userData?.organization?.subscription ? true : false);
      if (userData?.organization?.subscription?.add_on)
        setMaxTeam(
          default_team_member_limit +
            userData?.organization?.subscription?.add_on?.initial_quantity,
        );
      setCurrentTeamCount(userData?.organization?.users_count);
    }
  }, [userData?.organization]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full text-stone-800">
      <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Team Members
      </div>

      <div className="flex flex-col gap-2">
        <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          Manage your team member’s details and user types here. Permissions allow owners and
          workers access to:
        </div>

        <div className="flex flex-col gap-2 font-thin text-sm">
          <ul className="list-disc pl-4">
            <li>Admin (Full Access to the entire product)</li>
            <li>
              Manager (Access to everything except financial data, employee personal details. Can
              see Dashbaord for their store, but won't see Reports)
            </li>
            <li>
              Repair Team (Access to order overview, order details, and messages. Cannot access
              reports, customer list, or point of sale)
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        <div className="rounded-lg border border-stone-300 flex-col justify-start items-start inline-flex w-full">
          <div className="flex flex-row items-start justify-center w-full p-4 rounded-tl-lg rounded-tr-lg">
            <div className="sm:basis-1/2 font-light">Team Member</div>
            <div className="basis-1/2 font-light hidden sm:block">User Type</div>
          </div>
          <hr className="border-[0.5px] border-stone-300 w-full" />

          {teamMembers.map((user) => (
            <React.Fragment>
              <div className="flex flex-col sm:flex-row items-center justify-center w-full p-2 lg:p-4 ">
                <div className="flex flex-row sm:basis-1/2 w-full items-start">
                  <div className="flex flex-col">
                    <div className="font-normal text-xs lg:text-lg whitespace-normal">
                      {user.name}
                      {userData.email === user.email && (
                        <span className="text-xs font-normal lg:text-lg"> (You)</span>
                      )}
                    </div>
                    <div className="font-light text-xs lg:text-base whitespace-normal">
                      {user.email}
                    </div>
                  </div>
                  <div className="sm:hidden flex justify-end w-full">
                    {userData.email !== user.email && (
                      <MenuOptionButton data={buttonData} id={user} />
                    )}
                  </div>
                </div>

                <div className="flex sm:flex-row sm:basis-[30%] w-full sm:w-full items-start sm:items-center pt-2 sm:pt-0 ">
                  <Selector
                    isDisabled={true}
                    options={TEAM_MEMBER_ROLES}
                    value={TEAM_MEMBER_ROLES.find(
                      (role) => role?.value === user?.stores_users?.find(su => su.store_id === userData?.stores[0]?.id).role,
                    )}
                  />
                </div>

                <div className="hidden sm:flex sm:basis-[20%] sm:justify-end w-full">
                  {userData.email !== user.email && (
                    <MenuOptionButton data={buttonData} id={user} />
                  )}
                </div>
              </div>
              <hr className="border-[0.5px] border-stone-300 w-full" />
            </React.Fragment>
          ))}

          <div className="flex items-center justify-center p-2 hover:bg-gray-700/10 w-full rounded-b-lg">
            <Button
              variant="textPrimary"
              startIcon={<PlusCircle fill={!isHovered ? 'gray' : 'green'} />}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              sx={{
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'green',
                },
              }}
              onClick={maxTeam <= currentTeamCount ? handleAddOnSubscription : openAddMemberModal}
            >
              Add team members
              {maxTeam <= currentTeamCount ? (
                <div className="ml-1 px-2 py-1 bg-orange-100 rounded-2xl justify-center items-center inline-flex w-fit">
                  <img alt="logo" src={CrownIcon} className="w-4 h-auto " />
                  <div className="text-center text-orange-500 text-xs font-normal font-['Questrial'] leading-none">
                    Add-On
                  </div>
                </div>
              ) : (
                <div className="ml-1 px-2 py-1 bg-green-100 rounded-2xl justify-center items-center inline-flex w-fit">
                  <div className="text-center text-green-500 text-xs font-normal font-['Questrial'] leading-none">
                    {`${
                      isNaN(maxTeam - currentTeamCount) ? '' : maxTeam - currentTeamCount
                    } available seats`}
                  </div>
                </div>
              )}
            </Button>
          </div>
        </div>
      </div>
      <AddOnModal
        open={addOnModel}
        setOpen={setAddOnModel}
        organization={userData?.organization}
        count={userData?.organization?.subscription?.add_on?.current_quantity || 0}
      />
      <ConfirmationModal
        title={deleteUserProps.title}
        description={deleteUserProps.description}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={deleteUser}
      />
      <TeamModal
        maxTeam={maxTeam}
        currentTeam={currentTeamCount}
        open={isTeamModalOpen}
        setOpen={setIsTeamModalOpen}
        isAddTeam={isAddTeam}
        userData={userId}
        onConfirm={!isAddTeam ? editUserDetail : AddTeamMember}
      />
    </div>
  );
};

export default TeamMember;
