import axios from "axios";
import {
  retrieveTokenFromLocalStorage,
  destroyDataFromLocalStorage,
} from "../utils/localStorage";
import { API_BASE } from "../constants/BackendRoutes";
import { LOGIN, SIGNUP } from "../constants/FrontendRoutes";

const axiosInstance = axios.create({
  baseURL: API_BASE,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async function (config) {
    const url = config?.url;
    if (
      !(
        url.includes("login") ||
        url.includes("signup") ||
        url.includes("order_line_item_details") ||
        url.includes("update_order_status_and_shipping_info") ||
        url.includes("password")
      )
    ) {
      const accessToken = retrieveTokenFromLocalStorage();
      if (accessToken) {
        config.headers["Authorization"] = accessToken;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      destroyDataFromLocalStorage();
      const url = window.location.href;
      if (!(url.includes(LOGIN) || url.includes(SIGNUP))) {
        window.location.href = LOGIN;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
