import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import { LetterIcon } from "../../common/icons";
import { updateRequest } from "../../api";
import { addToast } from "../../redux/toastSlice";
import { selectCurrentUser } from "../../redux/authSlice";
import { useMediaQuery } from "react-responsive";

const OrderDetailsFooter = ({ user, order, setShowGreenTick }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isMdUp = useMediaQuery({ query: "(max-width: 767px)" });

  const [notes, setNotes] = useState({
    customerNotes: "",
    internalNotes: "",
  });

  const isButtonDisabled = () =>
    notes.customerNotes === order.customer_notes &&
    notes.internalNotes === order.internal_notes;

  const updateNotes = async () => {
    try {
      const res = await updateRequest(
        `${`stores/${currentUser?.stores[0].id}/orders/`}${order.id}`,
        {
          customer_notes: notes.customerNotes,
          internal_notes: notes.internalNotes,
        }
      );

      if (res.status == 200) {
        dispatch(addToast("Order is updated"));
        setShowGreenTick(true);

        setTimeout(() => {
          setShowGreenTick(false);
        }, 3000);
      }
    } catch (error) {
      dispatch(
        addToast(error.message || "Something went Wrong\nOrder is not update!")
      );
    }
  };

  const customerAddress = (customer) => {
    if (!customer?.addresses?.length) return "";
    const { zipcode, state, line } = customer?.addresses[0];
    return `${line}, ${state}, ${zipcode}`;
  };

  useEffect(() => {
    setNotes({
      customerNotes: order.customer_notes,
      internalNotes: order.internal_notes,
    });
  }, [order]);

  return (
    <Box className="max-w-[1550px] w-full flex flex-col gap-4">
      <Box className="flex md:flex-row flex-col gap-7 justify-center">
        <Card
          sx={{
            boxShadow: "none",
            "@media (min-width: 768px)": {
              boxShadow:
                "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)", // Shadow for md screens and above
            },
          }}
          className="md:w-1/3"
        >
          <CardContent
            sx={{
              padding: 0,
              "@media (min-width: 768px)": {
                padding: "16px",
              },
            }}
            className="flex flex-col gap-4"
          >
            <Typography style={theme.typography.title}>
              Customer detail
            </Typography>
            <Box className="flex flex-col gap-1">
              <Typography
                component="h2"
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Customer #{user.id}
              </Typography>
              <Typography
                className="md:block hidden"
                style={theme.typography.title}
              >
                {user.name}
              </Typography>
              <Typography className="md:hidden">{user.name}</Typography>
            </Box>
            <Box className="flex flex-col gap-1">
              <Typography
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Phone number:
              </Typography>
              <Typography
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.primary,
                }}
              >
                {user.phone_number}
              </Typography>
            </Box>
            <Box className="flex flex-col gap-1">
              <Typography
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Shipping address:
              </Typography>

              <Typography
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.primary,
                }}
              >
                {customerAddress(user)}
              </Typography>
            </Box>
            <Box className="hidden md:block">
              <Button
                variant="contained"
                sx={{
                  bgcolor: "white",
                  color: "#272523",
                  fontSize: "16px",
                  "&:hover": {
                    bgcolor: "white",
                  },
                  fullWidth: true,
                }}
                startIcon={<LetterIcon />}
              >
                <Link to={`messages`}>Message</Link>
              </Button>
            </Box>
            <Box className="md:hidden">
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: "100%",
                  fontSize: "16px",
                  fullWidth: true,
                }}
                startIcon={<LetterIcon />}
              >
                <Link to={`messages`}>Message</Link>
              </Button>
            </Box>
          </CardContent>
        </Card>

        <Card
          sx={{
            boxShadow: "none",
            "@media (min-width: 768px)": {
              boxShadow:
                "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)", // Shadow for md screens and above
            },
          }}
          className="md:w-2/3"
        >
          <CardContent
            sx={{
              padding: 0,
              "@media (min-width: 768px)": {
                padding: "16px",
              },
            }}
          >
            <Box className="flex flex-col gap-2">
              <Typography style={theme.typography.title}>
                Order Notes
              </Typography>
              <Box className="flex flex-col md:flex-row w-full gap-2">
                <Box className="flex flex-col gap-1 md:w-1/2">
                  <Typography style={theme.typography.body}>
                    Order Notes
                  </Typography>
                  <TextField
                    className="w-full"
                    id="outlined-multiline-static"
                    multiline
                    rows={isMdUp ? 1 : 8}
                    onChange={(e) =>
                      setNotes({ ...notes, customerNotes: e.target.value })
                    }
                    value={notes.customerNotes}
                    placeholder="Add notes here"
                  />
                </Box>
                <Box className="flex flex-col gap-1 md:w-1/2">
                  <Typography style={theme.typography.body}>
                    Internal Notes
                  </Typography>
                  <TextField
                    className="w-full"
                    id="outlined-multiline-static"
                    multiline
                    rows={isMdUp ? 1 : 8}
                    value={notes.internalNotes}
                    onChange={(e) =>
                      setNotes({ ...notes, internalNotes: e.target.value })
                    }
                    placeholder="Add notes here"
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box className="flex flex-grow min-w-full self-stretch justify-end">
        <Button
          className="md:flex-grow-0 flex-grow"
          variant="contained"
          color="success"
          disabled={isButtonDisabled()}
          onClick={updateNotes}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default OrderDetailsFooter;
