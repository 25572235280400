import React from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

const SetupShipStation = ({
  organizationData,
  setOrganizationData,
  handleBack,
  handleNext,
  activeStep,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSkip = () => {
    setOrganizationData((prevData) => ({
      ...prevData,
      shipstation_key: "",
      shipstation_secret: "",
    }));
    handleNext();
  };

  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <p className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 5
        </p>
      )}
      <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Shipstation account setup
      </p>
      <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug">
        Now you can connect your Coblr account with Shipstation to have a
        seamless shipping experience.
      </p>

      <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug">
        If you don’t have a Shipstation account already, click here to sign up
        one. If you already have an account, enter the API key below, so we can
        do the rest for you!
      </p>

      <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug">
        Shipstation API key
      </p>

      <TextField
        name="shipstation_key"
        placeholder="Please enter"
        variant="outlined"
        value={organizationData?.shipstation_key}
        onChange={handleChange}
      />

      <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug">
        Shipstation API Secret
      </p>

      <TextField
        name="shipstation_secret"
        placeholder="Please enter"
        variant="outlined"
        value={organizationData?.shipstation_secret}
        onChange={handleChange}
      />

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mt-4 ml-auto w-[90%]">
        <div className="flex items-center justify-end">
          <div className="underline cursor-pointer" onClick={handleSkip}>
            Skip for now
          </div>
        </div>
        <Button
          variant="outlinedSecondary"
          className="w-full"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          disabled={
            !organizationData?.shipstation_key.trim() ||
            !organizationData?.shipstation_secret.trim()
          }
          onClick={handleNext}
        >
          Connect
        </Button>
      </div>
    </div>
  );
};

export default SetupShipStation;
