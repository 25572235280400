import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { ArrowDownIcon, ArrowUpIcon } from "../../common/icons";
import theme from "../../theme";

function DropdownButton({ button, menuItems, onClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Box className="flex flex-row" gap="1px">
        <Button
          variant="contained"
          sx={{
            borderRadius: "8px 0px 0px 8px",
          }}
          onClick={() => onClick(button.returnedValue)}
        >
          {button.text}
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: "0px 8px 8px 0px",
            p: 1,
            minWidth: 0,
          }}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {open ? (
            <ArrowUpIcon fill={theme.palette.text.white} />
          ) : (
            <ArrowDownIcon fill={theme.palette.text.white} />
          )}
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onClick(item.returnedValue);
              handleClose();
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
export default DropdownButton;
