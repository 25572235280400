import { getRequest, postRequest } from "./requests";
import { calculateTotal, transformCreateOrderPayload } from "../utils";
import { POS_NEW_ORDER_STEPS, USER_ROLES_MAPPER } from "../constants";
import { addToast } from "../redux/toastSlice";
import { setLoading } from "../redux/loadingSlice";

export const getPOS = async (setProducts, setSubCategories, stores, dispatch) => {
  try {
    dispatch(setLoading(true));
    const { products, sub_categories: subCategories } = await getRequest(
      `stores/${stores?.[0].id}`,
      {},
      "products,sub_categories.options,sub_categories.services"
    );

    setProducts(products);
    setSubCategories(subCategories);
  } catch (error) {
    dispatch(addToast(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStateTaxes = async (state, dispatch) => {
  try {
    const stateTaxes = await getRequest("taxes", {
      filters: { state_abbreviation: state },
    });

    return stateTaxes.find((item) => item.state_abbreviation === state);
  } catch (error) {
    dispatch(addToast(error.message));
  }
};

export const createPayment = async (cart, setCart, userData, dispatch) => {
  try {
    const paymentResponse = await postRequest(
      `stores/${userData.stores[0].id}/payments`,
      {
        payment: {
          amount: calculateTotal(cart),
        },
      }
    );

    if (paymentResponse && paymentResponse.status == 201)
      setCart((prev) => ({
        ...prev,
        payment_id: paymentResponse?.data?.id,
      }));
  } catch (error) {
    dispatch(addToast(error));
  }
};

export const posCreateOrder = async (orderType, cart, setCart, setCurrentStep, userData, dispatch) => {
  const orderPayload = transformCreateOrderPayload(orderType, cart);

  try {
    const orderResponse = await postRequest(
      `stores/${userData.stores[0].id}/orders`,
      {
        order: orderPayload,
        payment_id: cart?.payment_id,
        customer: {
          filters: {
            "id": cart?.attached_customer?.id
          },
        },
      }
    );

    if (orderResponse && orderResponse.status == 201) {
      setCart((prev) => ({ ...prev, orderId: orderResponse?.data?.id }));
      setCurrentStep(POS_NEW_ORDER_STEPS.ORDER_SUCCESS);
    }
  } catch (error) {
    dispatch(addToast(error || "Error."));
  }
};
