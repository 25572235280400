import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Divider,
  InputAdornment,
} from "@mui/material";
import { calculateChangeDue, calculateTotal } from "../../../utils";
import { BackspaceOutlined } from "@mui/icons-material";
import { ORDER_TYPE_ENUM, PAYMENT_METHODS, PAYMENT_STATUS_ENUM, POS_NEW_ORDER_STEPS } from "../../../constants";
import { postRequest, updateRequest } from "../../../api";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/authSlice";
import { useDispatch } from "react-redux";
import { addToast } from "../../../redux/toastSlice";
import { posCreateOrder } from "../../../api/pos";
import CartBadge from "./CartBadge";

const CalculatePayment = ({ cart, setCart, currentStep, setCurrentStep }) => {
  const [selectedButton, setSelectedButton] = useState("");
  const [changeDue, setChangeDue] = useState(-1);
  const [amountTendered, setAmountTendered] = useState("");

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);
  const buttonRefs = useRef({});

  useEffect(() => {
    setChangeDue(calculateChangeDue(cart, amountTendered));
  }, [amountTendered, cart]);

  const handleConcat = (value) => {
    setAmountTendered(
      (
        parseFloat("00".concat(amountTendered.replace(".", ""), value)) / 100
      ).toFixed(2)
    );
  };

  const handleSum = (value) => {
    setAmountTendered((parseFloat(amountTendered || 0) + value).toFixed(2));
  };

  const handleKeyboardInput = (event) => {
    if (event.key >= "0" && event.key <= "9") {
      handleConcat(event.key);
      setSelectedButton(event.key);
    } else if (event.key === "Backspace") {
      setAmountTendered(
        (
          parseFloat(
            "000".concat(amountTendered.replace(".", "").slice(0, -1))
          ) / 100
        ).toFixed(2)
      );
      setSelectedButton("backspace");
    } else if (event.key === "Escape") {
      setAmountTendered("");
      setSelectedButton("C");
    } else if (event.key === "Enter") {
      buttonRefs.current["done"].click();
    } else {
      const buttonMap = {
        $: "$",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        0: "0",
        C: "C",
        $10: "$10",
        $20: "$20",
        $50: "$50",
        $100: "$100",
        backspace: "backspace",
      };

      const buttonKey = buttonMap[event.key] || event.key;
      if (buttonRefs.current[buttonKey]) {
        setSelectedButton(buttonKey);
        buttonRefs.current[buttonKey].click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardInput);
    return () => {
      window.removeEventListener("keydown", handleKeyboardInput);
    };
  }, [amountTendered]);

  const renderButton = (label, onClick) => (
    <Button
      ref={(el) => (buttonRefs.current[label] = el)}
      variant="selectableSecondary"
      className="flex-1"
      isSelected={selectedButton === label}
      onClick={() => {
        setSelectedButton(label);
        onClick();
      }}
    >
      {label === "backspace" ? <BackspaceOutlined /> : label}
    </Button>
  );

  const handleCashPaymentCapture = async () => {
    try {
      await updateRequest(
        `stores/${userData.stores[0].id}/payments/${cart.payment_id}`, {
          payment: { status: PAYMENT_STATUS_ENUM.succeeded }
        }
      );

      posCreateOrder(
        ORDER_TYPE_ENUM.in_store,
        cart,
        setCart,
        setCurrentStep,
        userData,
        dispatch
      );
    } catch (error) {
      dispatch(addToast(error))
    }
  }

  return (
    <Box className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[55%] sm:m-auto">
        <Box>
          <CartBadge
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <div className="flex justify-between items-center my-2 md:my-4">
            <span className="text-lg md:text-xl font-bold font-['Questrial'] leading-snug">
              Total amount due
            </span>
            <span className="text-lg font-semibold md:text-xl lg:font-bold font-['Montserrat'] leading-[25.20px]">
              ${calculateTotal(cart).toFixed(2)}
            </span>
          </div>
          <Divider sx={{ my: 2 }} />
          <div className="flex flex-col md:flex-row justify-between md:items-center gap-2 my-2 md:my-4">
            <span className="text-lg md:text-xl font-bold font-['Questrial'] leading-snug">
              Amount tendered
            </span>
            <TextField
              disabled
              placeholder="Please enter"
              type="number"
              value={amountTendered}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </div>
          <Box
            className="flex flex-col justify-between w-full gap-2 lg:gap-4"
            sx={{ my: 4 }}
          >
            <Box className="flex flex-row justify-between w-full gap-3 md:gap-6">
              {renderButton("1", () => handleConcat("1"))}
              {renderButton("2", () => handleConcat("2"))}
              {renderButton("3", () => handleConcat("3"))}
              {renderButton("$10", () => handleSum(10))}
            </Box>
            <Box className="flex flex-row justify-between w-full gap-3 md:gap-6">
              {renderButton("4", () => handleConcat("4"))}
              {renderButton("5", () => handleConcat("5"))}
              {renderButton("6", () => handleConcat("6"))}
              {renderButton("$20", () => handleSum(20))}
            </Box>
            <Box className="flex flex-row justify-between w-full gap-3 md:gap-6">
              {renderButton("7", () => handleConcat("7"))}
              {renderButton("8", () => handleConcat("8"))}
              {renderButton("9", () => handleConcat("9"))}
              {renderButton("$50", () => handleSum(50))}
            </Box>
            <Box className="flex flex-row justify-between w-full gap-3 md:gap-6">
              {renderButton("C", () => setAmountTendered(""))}
              {renderButton("0", () => handleConcat("0"))}
              {renderButton("backspace", () => {
                setAmountTendered(
                  (
                    parseFloat(
                      "000".concat(amountTendered.replace(".", "").slice(0, -1))
                    ) / 100
                  ).toFixed(2)
                );
              })}
              {renderButton("$100", () => handleSum(100))}
            </Box>
          </Box>
          {cart.paymentMethod == PAYMENT_METHODS.CASH && (
            <div className="flex justify-between items-center my-2 md:my-4">
              <span className="text-lg md:text-xl font-bold font-['Questrial'] leading-snug">
                Change due
              </span>
              <span className="text-lg font-semibold md:text-xl lg:font-bold font-['Montserrat'] leading-[25.20px]">
                {changeDue >= 0 ? `$${changeDue.toFixed(2)}` : "-"}
              </span>
            </div>
          )}
        </Box>

        <Box className="flex flex-col-reverse justify-between w-full gap-2 mt-8">
          <Button
            variant="outlinedSecondary"
            className="flex-1"
            onClick={() => {
              setCart((prev) => ({ ...prev, paymentMethod: null }));
              setCurrentStep(POS_NEW_ORDER_STEPS.PAYMENT_METHOD);
            }}
          >
            Back
          </Button>
          <Button
            ref={(el) => (buttonRefs.current["done"] = el)}
            variant="containedPrimary"
            className="flex-1"
            disabled={changeDue < 0}
            onClick={handleCashPaymentCapture}
          >
            Done
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CalculatePayment;
