import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

export default function CollapsibleRow({ row, columns }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const ActionComponent = columns.find(
    (col) => col.field === "actions"
  )?.component;

  return (
    <React.Fragment>
      <TableRow
        className={`${row.link && "cursor-pointer"} ${
          open ? "bg-neutral-100" : ""
        }`}
        onClick={row.link && (() => navigate(row.link))}
      >
        {columns.map((column, index) => (
          <TableCell
            key={index}
            style={{
              width: column.width,
              border: 0,
              display: column.collapsible ? "flex" : "table-cell",
              alignItems: column.collapsible ? "center" : "baseline",
              justifyContent: column.collapsible ? "space-between" : "start",
              gap: column.collapsible ? "16px" : "0",
            }}
            align={column.align}
          >
            {column.collapsible ? (
              <>
                {row[column.field]}
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </>
            ) : column.field === "actions" ? (
              row.actionsProps && ActionComponent ? (
                <ActionComponent {...row.actionsProps} id={row.id} />
              ) : (
                <div className="w-[2.5rem]"></div>
              )
            ) : (
              row[column.field]
            )}
          </TableCell>
        ))}
      </TableRow>
      {row.collapse && (
        <TableRow>
          <TableCell style={{ padding: 0, border: 0 }} colSpan={columns.length}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table aria-label="purchases">
                <TableBody>
                  {row.collapse.map((subRow, subIndex) => (
                    <TableRow key={subIndex} className="bg-neutral-100">
                      {columns.map((subColumn, colIndex) => (
                        <TableCell
                          key={colIndex}
                          style={{ width: subColumn.width, border: 0 }}
                          align={subColumn.align}
                        >
                          {subColumn.field === "actions"
                            ? (subRow.actionsProps && ActionComponent) && (
                                <ActionComponent
                                  {...subRow.actionsProps}
                                  id={subRow.id}
                                />
                              )
                            : subRow[subColumn.field] || ""}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
