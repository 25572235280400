import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { COBLRSHOP_PRODUCT_FIELDS } from "../../constants";

export default function FieldDataDropdown({ value, handleDropdown }) {
  const handleChange = (event) => {
    handleDropdown(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <Select value={value} onChange={handleChange}>
        {COBLRSHOP_PRODUCT_FIELDS.map((item) => (
          <MenuItem value={item}>{item}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
