import React from "react";
import { Box, Typography } from "@mui/material";

const Capsule = ({
  text = "",
  textColor = "",
  capsuleColor = "",
  LeftIcon = <></>,
  RightIcon = <></>,
}) => {
  return (
    <Box className={`${capsuleColor} rounded-full flex items-center px-3 py-1`}>
      <Typography
        className={`${textColor} flex flex-row justify-start items-center gap-2`}
      >
        {LeftIcon}
        <p className="text-sm">{text}</p>
        {RightIcon}
      </Typography>
    </Box>
  );
};

export default Capsule;
