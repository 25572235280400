import React from "react";
import { InputAdornment, TextField } from "@mui/material";

const TextFieldWithAdortment = ({
  startAdornment = null,
  endAdornment = null,
  value = null,
  handleChangeValue = () => {},
  keyValue,
  isNumber,
}) => {
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      handleChangeValue(keyValue, newValue);
    }
  };
  return (
    <TextField
      value={value[keyValue]}
      onChange={handleInputChange}
      type={isNumber ? "number" : "text"}
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : null,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : null,
      }}
      size="small"
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#D0D5DD",
            borderRadius: "8px",
          },
        },
        width: "320px",
        height: "42px",
      }}
    />
  );
};

export default TextFieldWithAdortment;
