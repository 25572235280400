import React from "react";
import Button from "@mui/material/Button";

import { IncludedIcon } from "../common/icons";
import Chip from "./Chip";
import { postRequest } from "../api";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/authSlice";
import { setLoading } from "../redux/loadingSlice";

const PricingPlan = ({ organization, setOpen }) => {

  const userData = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  const handleSubscription = async () => {
    try {
      dispatch(setLoading(true))
      const response = await postRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stripe/subscriptions`,
        {
          organization_id: organization.id,
          plan_name: "Starter Plan",
        }
      );

      if (response && response.status == 201) {
        setOpen(false);
        dispatch(addToast("Refresh your page!"));
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2 mx-8 mt-8">
        <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          Pricing plan option
        </p>
        <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          We now offer a starter plan, more plans options coming up!
        </p>
      </div>

      <div className="mx-8 my-5 bg-[linear-gradient(0deg,_rgba(76,_140,_74,_0.05)_0%,_rgba(76,_140,_74,_0.05)_100%)] rounded">
        <div className="flex flex-col gap-2 p-6">
          <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
            Starter
          </p>
          <div className="justify-start items-baseline gap-2 flex">
            <div className="justify-start items-baseline gap-1 flex">
              <p className="text-stone-800 text-xl font-semibold font-['SF Pro Text'] leading-7">
                $75
              </p>
              <p className="text-stone-400 text-xs font-normal font-['Questrial'] leading-none">
                /Month
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-2 gap-2 px-6 pb-6">
          <div className="px-6 pt-6 border-stone-300 flex-col gap-5 flex">
            <p className="text-stone-400 text-xs font-normal font-['Questrial'] leading-none">
              Items included:
            </p>
            <div className="flex-col justify-center gap-3 flex">
              <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                1 Location
              </p>
              <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                2 Employees
              </p>
              <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                1 Integration
              </p>
              <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                Email Support Only
              </p>
            </div>
          </div>

          <div className="px-6 pt-6 border-l border-neutral-200 flex-col flex gap-5">
            <p className="text-stone-400 text-xs font-normal font-['Questrial'] leading-none">
              Features included:
            </p>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2 items-center">
                <IncludedIcon />
                <p className="grow shrink basis-0 text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                  Order Management
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <IncludedIcon />
                <p className="grow shrink basis-0 text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                  Full POS
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <IncludedIcon />
                <p className="grow shrink basis-0 text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                  Customer Management
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <IncludedIcon />
                <p className="grow shrink basis-0 text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                  Messages
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-5 mx-8 mb-8">
        <Button variant="outlinedSecondary" disabled className="w-full">
          Contact Us
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          onClick={handleSubscription}
        >
          Subscribe now
        </Button>
      </div>
    </>
  );
};

export default PricingPlan;
