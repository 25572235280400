import React from "react";
import FieldDataDropdown from "./FieldDataDropdown";
import { Checkbox, FormControlLabel } from "@mui/material";
import GreenButton from "../Button/GreenButton";

const TableRow = ({ item, index, handleCheckedBox, handleDropdownChange }) => {
  const handleChange = (event) => {
    handleCheckedBox(event.target.checked, parseInt(event.target.name));
  };

  const handleDropdown = (value) => {
    handleDropdownChange(value, index);
  };

  return (
    <div className="flex flex-row justify-between p-4 items-center">
      <p className="min-w-32">{item.csvField}</p>
      <div className="flex flex-row gap-3 items-center">
        <p>→</p>
        <FieldDataDropdown
          value={item.coblrshopField}
          handleDropdown={handleDropdown}
        />
      </div>
      <FormControlLabel
        control={
          <Checkbox
            name={index}
            onChange={handleChange}
            checked={item.isMapField}
            color="success"
          />
        }
      />
    </div>
  );
};

export default function MappingField({
  csvFields,
  handleCheckedBox,
  handleDropdownChange,
  handleBack,
}) {
  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex flex-row px-4 gap-5">
          <p className="text-lg hover:cursor-pointer" onClick={handleBack}>
            {"<"}
          </p>
          <p className="font-bold text-2xl">Fields Mapping</p>
        </div>

        <div className="border rounded-t-lg">
          <div className="flex flex-row justify-between p-4">
            <p>CSV Field</p>
            <p>Coblrshop Field</p>
            <p>Map Field</p>
          </div>

          {csvFields.map((item, index) => (
            <TableRow
              item={item}
              index={index}
              handleCheckedBox={handleCheckedBox}
              handleDropdownChange={handleDropdownChange}
            />
          ))}
        </div>
        <div className="my-2 w-full">
          <div className="flex flex-row justify-end gap-2 items-center p-3">
            <GreenButton text="Cancel" />

            <GreenButton text="Start mapping" onClick={handleBack} />
          </div>
        </div>
      </div>
    </>
  );
}
