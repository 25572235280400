import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import InputMask from "react-input-mask";
import MediaQuery from "react-responsive";
import {
  CART_TYPES,
  ORDER_STATUS_ENUM,
  ORDER_TYPE_ENUM,
  PAYMENT_METHODS,
  PAYMENT_STATUS_ENUM,
  POS_NEW_ORDER_STEPS,
} from "../../../constants";
import {
  deleteRequest,
  getRequest,
  postRequest,
  updateRequest,
} from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/authSlice";
import { transformCreateOrderPayload, validateEmail } from "../../../utils";
import { addToast } from "../../../redux/toastSlice";
import { LeftArrowIcon } from "../../../common/icons";
import CartBadge from "./CartBadge";

const SelectCustomer = ({ cart, setCart, currentStep, setCurrentStep, isDesktopScreen }) => {
  const [inputValue, setInputValue] = useState("");
  const [inputType, setInputType] = useState("phone");
  const [isFormValid, setIsFormValid] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCustomerTypeSwitch = (type) => {
    setInputValue("");
    setInputType(type);
  };

  const handleBack = async () => {
    if (cart?.payment_id) {
      const res = await deleteRequest(
        `stores/${userData.stores[0].id}/payments/${cart?.payment_id}`
      );
      if (res) {
        setCart((prev) => ({
          ...prev,
          payment_id: null,
        }));
      }
    }

    isDesktopScreen
      ? setCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM)
      : setCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART);

    setCart((prev) => ({
      ...prev,
      type: null,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await getRequest(
        `stores/${userData.stores[0].id}/users/filter_user`,
        {
          users: {
            filters: {
              [inputType == "phone" ? "phone_number" : inputType]: inputValue,
            },
          },
        }
      );

      if (response) {
        setCart((prev) => ({
          ...prev,
          new_customer: false,
          customer: response,
        }));
        setCurrentStep(POS_NEW_ORDER_STEPS.ATTACH_CUSTOMER);
      } else {
        setCart((prev) => ({
          ...prev,
          lookupPhone: inputType === "phone" ? inputValue : prev.lookupPhone,
          lookupEmail: inputType === "email" ? inputValue : prev.lookupEmail,
        }));
        dispatch(addToast("Couldn't find User"));
        setCurrentStep(POS_NEW_ORDER_STEPS.CREATE_CUSTOMER);
      }
    } catch (error) {
      dispatch(addToast(error || "Error."));
    }
  };

  useEffect(() => {
    if (inputType === "phone") {
      setIsFormValid(inputValue.replace(/[_()-\s]/g, "").length === 10);
    } else if (inputType === "email") {
      setIsFormValid(validateEmail(inputValue));
    }
  }, [inputValue, inputType]);

  return (
    <Box className="relative flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <MediaQuery minWidth={1024}>
        <Box
          className="absolute flex items-center gap-1 top-[2.5%] left-[2.5%] cursor-pointer"
          onClick={handleBack}
        >
          <LeftArrowIcon width={18} height={19} />
          <span>Back</span>
        </Box>
      </MediaQuery>
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[50%] sm:m-auto">
        <div className="flex flex-col">
          <CartBadge
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <p className="text-lg sm:text-3xl font-semibold sm:font-bold font-['Montserrat'] leading-[25.20px]">
            Customer Lookup{" "}
          </p>
          <p className="mt-4 text-base sm:lg font-normal md:font-semibold font-['Questrial'] leading-snug">
            Enter phone number or email address to attach a customer to an
            order:
          </p>
          <Box className="flex flex-row w-full items-center gap-3 my-4">
            <Button
              variant="selectablePrimary"
              className="flex-1"
              isSelected={inputType === "phone"}
              onClick={() => handleCustomerTypeSwitch("phone")}
            >
              Phone
            </Button>
            <Button
              variant="selectablePrimary"
              className="flex-1"
              isSelected={inputType === "email"}
              onClick={() => handleCustomerTypeSwitch("email")}
            >
              Email
            </Button>
          </Box>
          {inputType === "phone" ? (
            <>
              <Typography variant="body">Phone Number</Typography>
              <InputMask
                mask="(999) 999-9999"
                value={inputValue}
                onChange={handleInputChange}
              >
                {() => (
                  <TextField
                    type="tel"
                    placeholder="(123) 456-7890"
                    className="flex-1"
                    sx={{ mt: 0.5, mb: 4 }}
                  />
                )}
              </InputMask>
            </>
          ) : (
            <>
              <Typography variant="body">Email</Typography>
              <TextField
                type="email"
                placeholder="Example@gmail.com"
                className="flex-1"
                sx={{ mt: 0.5, mb: 4 }}
                value={inputValue}
                onChange={handleInputChange}
              />
            </>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <Button
            variant="containedPrimary"
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
          <MediaQuery maxWidth={1023}>
            <Button variant="outlinedSecondary" onClick={handleBack}>
              Back
            </Button>
          </MediaQuery>
        </div>
      </Box>
    </Box>
  );
};

export default SelectCustomer;
