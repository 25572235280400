import React, { useEffect, useState } from "react";
import Chip from "../Chip";
import { IconRight } from "./MessageHistory";
import { useNavigate } from "react-router-dom";
import { ORDERS_DETAILS } from "../../constants/FrontendRoutes";
import { RAILS_APP_API_URL } from "../../constants/BackendRoutes";
import { ArrowDownIcon } from "../../common/icons";
import { convertDateFormat } from "../../utils";

const OrderAccordion = ({ order }) => {
  const [expand, setExpand] = useState(false);
  const [serviceLineItems, setServiceLineItems] = useState([]);
  const [productLineItems, setProductLineItems] = useState([]);
  const [beforeImages, setBeforeImages] = useState([]);
  const [afterImages, setAfterImages] = useState([]);

  const navigate = useNavigate();

  const ExpandedServiceItem = ({ serviceItem, topDivider, bottomDivider }) => {
    const { categoryType, services, options, description } =
      serviceItem?.details;

    const uniqueServiceTypes = services
      .map((service) => service.type)
      .filter((type, index, self) => self.indexOf(type) === index);

    return (
      <React.Fragment>
        {topDivider && <hr className="my-4" />}
        <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
          {repairItemTitle(serviceItem)}
        </p>
        <div className="ml-2 sm:ml-9 mr-7 my-4 flex flex-col gap-2">
          <div className="flex flex-col sm:flex-row gap-2 justify-between">
            <div className="flex flex-col gap-1 w-[130px]">
              <span className="text-[#939291]">Category type</span>
              <span className="text-[#272523]">{categoryType}</span>
            </div>
            {services.length > 0 && (
              <div className="flex flex-col gap-1 w-[130px]">
                <span className="text-[#939291]">Service type</span>
                <span className="text-[#272523]">
                  <ul>
                    {uniqueServiceTypes.map((type) => (
                      <li className="custom-list-style">{type}</li>
                    ))}
                  </ul>
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-col sm:flex-row gap-2 justify-between">
            {services.length > 0 && (
              <div className="flex flex-col gap-1 w-[130px]">
                <span className="text-[#939291]">Service name</span>
                <span className="text-[#272523]">
                  <ul>
                    {services &&
                      services.map((service) => (
                        <li className="custom-list-style">{service?.name}</li>
                      ))}
                  </ul>
                </span>
              </div>
            )}

            {options.length > 0 && (
              <div className="flex flex-col gap-1 w-[130px]">
                <span className="text-[#939291]">Options</span>
                <span className="text-[#272523]">
                  <ul>
                    {options &&
                      options.map((option) => (
                        <li className="custom-list-style">{option?.name}</li>
                      ))}
                  </ul>
                </span>
              </div>
            )}
          </div>
          {description && (
            <div className="flex flex-col w-full">
              <span className="text-[#939291]">Description</span>
              <span className="text-[#272523]">{description}</span>
            </div>
          )}
        </div>
        {bottomDivider && <hr className="my-4" />}
      </React.Fragment>
    );
  };

  const ExpandedProductItem = ({ productItem, divider }) => {
    // const { category, brand, color } = productItem?.details;
    return (
      <React.Fragment>
        <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
          {productItem.details?.name}
        </p>
        {/* <div className="ml-9 mr-7 my-4 flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="flex flex-col gap-1 w-[130px]">
              <span className="text-[#939291]">Category</span>
              <span className="text-[#272523]">Zimbabway</span>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col gap-1 w-[130px]">
              <span className="text-[#939291]">Brand</span>
              <span className="text-[#272523]">
                Sagapaga
              </span>
            </div>

            <div className="flex flex-col gap-1 w-[130px]">
              <span className="text-[#939291]">Color</span>
              <span className="text-[#272523]">
                yellow
              </span>
            </div>
          </div>
        </div> */}
      </React.Fragment>
    );
  }

  const repairItemTitle = (repairItem) => {
    const categoryType = repairItem?.details?.categoryType;
    const services = repairItem?.details?.services;
    const options = repairItem?.details?.options;

    if (!services || services.length === 0) return categoryType;

    const firstService = services[0];
    const remainingServicesCount = services.length - 1;

    let title = `${categoryType} - ${firstService.name}`;
    if (remainingServicesCount > 0) title += ` and ${remainingServicesCount} more`;
    if (options && options.length > 0) title += " with options";

    return title;
  };

  useEffect(() => {
    if (order) {
      const separatedItems = order.order_line_items.reduce(
        (acc, lineItem) => {
          const { line_item_type, before_images_data, after_images_data } = lineItem;
          const beforeImageUrls = before_images_data.map((image) => image.url);
          const afterImageUrls = after_images_data.map((image) => image.url);

          return {
            serviceLineItems:
              line_item_type === "service"
                ? [...acc.serviceLineItems, lineItem]
                : acc.serviceLineItems,
            productLineItems:
              line_item_type === "product"
                ? [...acc.productLineItems, lineItem]
                : acc.productLineItems,
            beforeImages: [...acc.beforeImages, ...beforeImageUrls],
            afterImages: [...acc.afterImages, ...afterImageUrls],
          };
        },
        {
          serviceLineItems: [],
          productLineItems: [],
          beforeImages: [],
          afterImages: [],
        }
      );

      console.log(separatedItems);

      setServiceLineItems(separatedItems.serviceLineItems);
      setProductLineItems(separatedItems.productLineItems);
      setBeforeImages(separatedItems.beforeImages);
      setAfterImages(separatedItems.afterImages);
    }
  }, [order]);

  return (
    <div className="flex flex-col md:flex-row border border-[#C9C8C8] rounded-[8px]">
      <div
        onClick={() => setExpand(!expand)}
        className="h-full self-center mx-[8px] cursor-pointer my-2"
      >
        <ArrowDownIcon transform={expand ? "rotate(180)" : ""} />
      </div>
      <div className="flex flex-col grow gap-4 p-5 font-['Questrial'] border-t-[0.5px] border-t-[#c9c8c8] md:border-l-[0.5px] md:border-l-[#c9c8c8] md:border-r-[0.5px] md:border-r-[#c9c8c8]">
        <div className="font-normal text-[16px] text-[#939291]">
          Created on {convertDateFormat(order?.created_at)}
        </div>

        <div className="flex items-center w-full">
          <div className="font-normal text-[16px] text-[#939291] w-[50%] sm:w-[25%]">
            {order?.status == "quote" ? "Quote" : "Order"} ID:
          </div>
          <div className="flex gap-1 font-['Montserrat'] font-[600] text-[18px] text-[#272523] w-[50%] sm:w-[75%]">
            <div>#{order?.id}</div>
            <Chip status={order?.status} />
          </div>
        </div>

        {serviceLineItems.length > 0 && (
          <div className="flex w-full">
            <div className="font-normal text-[16px] text-[#939291] w-[50%] sm:w-[25%]">
              Repair Type:
            </div>

            <div className="w-[50%] sm:w-[75%]">
              {!expand ? (
                <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
                  {repairItemTitle(serviceLineItems[0])}
                </p>
              ) : (
                serviceLineItems.map((serviceItem, index) => (
                  <ExpandedServiceItem
                    serviceItem={serviceItem}
                    key={serviceItem.id || serviceItem._id}
                    topDivider={index >= 1}
                    bottomDivider={
                      serviceLineItems.length - 1 == index &&
                      productLineItems.length > 0
                    }
                  />
                ))
              )}
            </div>
          </div>
        )}

        {productLineItems.length > 0 && (
          <div className="flex w-full">
            <div className="font-normal text-[16px] text-[#939291] w-[50%] sm:w-[25%]">
              Product(s):
            </div>

            <div className="w-[50%] sm:w-[75%]">
              {!expand ? (
                <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
                  {productLineItems[0]?.details?.name}
                </p>
              ) : (
                productLineItems.map((productItem) => (
                  <ExpandedProductItem
                    productItem={productItem}
                    key={productItem.id || productItem._id}
                  />
                ))
              )}
            </div>
          </div>
        )}

        <div className="flex items-center w-full">
          <div className="font-normal text-[16px] text-[#939291] w-[50%] sm:w-[25%]">
            {order?.status == "quote" ? "Est." : "Order"} total:
          </div>
          <div className="text-[16px] text-[#272523] font-normal w-[50%] sm:w-[75%]">
            ${order?.net_total?.toFixed(2)}
          </div>
        </div>

        {expand && beforeImages.length > 0 && (
          <div className="flex flex-col md:flex-row">
            <div className="text-gray-500 sm:w-[25%]">Before Photos:</div>
            <div className="flex md:flex-wrap gap-2 w-full md:w-[75%] overflow-x-auto">
              {beforeImages.map((image, index) => (
                <img
                  key={index}
                  src={`${RAILS_APP_API_URL}${image}`}
                  alt="Before Image"
                  className="min-w-[131px] h-[68px] object-cover rounded-[8px]"
                />
              ))}
            </div>
          </div>
        )}

        {expand && afterImages.length > 0 && (
          <div className="flex flex-col md:flex-row">
            <div className="text-gray-500 sm:w-[25%]">After Photos:</div>
            <div className="flex md:flex-wrap gap-2 w-full md:w-[75%] overflow-x-auto">
              {afterImages.map((image, index) => (
                <img
                  key={index}
                  src={`${RAILS_APP_API_URL}${image}`}
                  alt="Before Image"
                  className="min-w-[131px] h-[68px] object-cover rounded-[8px]"
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="md:self-center mx-3 lg:mx-5">
        <div
          onClick={() => navigate(ORDERS_DETAILS.replace(":id", order?.id))}
          className="cursor-pointer flex py-[16px] px-[10px] items-center gap-[8px] font-['Questrial'] text-[16px] font-normal underline text-[#272523]"
        >
          <div className="text-nowrap">View Details</div>
          <IconRight />
        </div>
      </div>
    </div>
  );
};

export default OrderAccordion;
