import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import toastReducer from "./toastSlice";
import loadingReducer from "./loadingSlice";
import cartReducer from "./cartSlice";
import storeReducer from "./storeSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastReducer,
    loading: loadingReducer,
    cart: cartReducer,
    store: storeReducer,
  },
});
