import React, { useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";

const FeeManagement = ({ id, userData, setBusinessDetailChanges }) => {
  const [rushOrderFee, setRushOrderFee] = useState("");

  useEffect(() => {
    if (userData) {
      const { stores } = userData;
      if (stores && stores.length > 0) {
        setRushOrderFee(stores[0].rush_fee);
      }
    }
  }, [userData]);

  const handleFeeChange = (event) => {
    const value = event.target.value;
    setRushOrderFee(value);
    setBusinessDetailChanges((prevChanges) => ({
      ...prevChanges,
      rush_fee: value,
    }));
  };

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Fee Management
      </div>
      <div className="text-stone-800 text-base font-normal font-['Questrial']">
        You can now customize the price of the following services:
      </div>
      <div className="flex flex-col gap-4 mt-4">
        <div className="flex flex-col gap-2">
          <label className="text-stone-800 text-base font-normal font-['Questrial']">
            Rush order fees
          </label>
          <div className="relative">
            <TextField
              type="number"
              className="pl-8 pr-3 py-2 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-500"
              value={rushOrderFee}
              onChange={handleFeeChange}
              placeholder="Enter fee amount"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeManagement;
