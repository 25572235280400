import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TableRowHeader from "./TableHeader";
import OrderDetailsCard from "./OrderDetailsCard";
import { OrderStatus } from "../../constants/OrderStatus";
import { sumBy } from "../../utils";
import { Typography } from "@mui/material";
import theme from "../../theme";

export const OrderOverviewTable = ({
  onDragEnd,
  selectedStatus,
  quoteOrders,
  newOrders,
  inProgress,
  shipping,
  delayed,
  repaired,
  delivered,
}) => {
  const { NEW_ORDER, SHIPPING, IN_PROGRESS, DELAYED, REPAIRED, DELIVERED } =
    OrderStatus;
  const scrollRef = useRef(null);
  const [leftScroll, setLeftScroll] = useState(0);
  const [maxScrollLeft, setMaxScrollLeft] = useState(0);

  const getScrollPosition = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      setLeftScroll(Math.min(scrollLeft, maxScrollLeft));
    }
  };

  const updateMaxScrollLeft = () => {
    if (scrollRef.current) {
      const newMaxScrollLeft =
        scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
      setMaxScrollLeft(newMaxScrollLeft);
    }
  };

  useEffect(() => {
    updateMaxScrollLeft();

    window.addEventListener("resize", updateMaxScrollLeft);

    return () => {
      window.removeEventListener("resize", updateMaxScrollLeft);
    };
  }, []);

  useEffect(() => {
    getScrollPosition();
  }, [leftScroll]);

  return (
    <div className="bg-white px-2">
      <div className="max-h-screen overflow-auto">
        <DragDropContext onDragEnd={onDragEnd}>
          <div
            ref={scrollRef}
            onScroll={getScrollPosition}
            className="w-full overflow-x-auto"
          >
            <div className="flex flex-row gap-5 p-4">
              {/* Quote Orders Column */}
              {selectedStatus === "" && (
                <div className="max-w-[224px] flex flex-col flex-1 min-h-full gap-3">
                  <TableRowHeader
                    name="Quote"
                    count={quoteOrders.length}
                    bgColor="bg-[#E8E8E8]"
                  />
                  {quoteOrders.map((order, index) => (
                    <OrderDetailsCard key={index} order={order} />
                  ))}
                </div>
              )}

              {/* New Orders Column */}
              {selectedStatus === "" && <p className="border-e" />}

              {(selectedStatus === "" || selectedStatus === "newOrders") && (
                <div className="max-w-[224px] flex flex-col flex-1 min-h-full">
                  <Droppable droppableId={NEW_ORDER}>
                    {(provided) => (
                      <div
                        className="flex flex-col gap-3 min-h-full"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <TableRowHeader
                          name="New orders"
                          count={newOrders.length}
                          bgColor="bg-[#f3f0e8]"
                        />
                        {newOrders.map((order, index) => (
                          <Draggable
                            key={order.id}
                            draggableId={order.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex flex-col gap-3"
                                style={{
                                  ...provided.draggableProps.style,
                                  transform: snapshot.isDragging
                                    ? `${provided.draggableProps.style.transform} rotate(-5deg)`
                                    : provided.draggableProps.style.transform,
                                  transition: snapshot.isDragging
                                    ? "transform 0.2s ease"
                                    : "none",
                                  background: snapshot.isDragging
                                    ? "linear-gradient(0deg, rgba(76, 140, 74, 0.10) 0%, rgba(76, 140, 74, 0.10) 100%), #FFF"
                                    : "",
                                  border: snapshot.isDragging
                                    ? "1px solid var(--Success, #4C8C4A)"
                                    : "",
                                }}
                              >
                                <OrderDetailsCard order={order} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
              {selectedStatus === "" && <p className="border-e" />}

              {/* Shipping Column */}
              {selectedStatus === "" && (
                <div className="max-w-[224px] flex flex-col flex-1 min-h-full">
                  <Droppable droppableId={SHIPPING}>
                    {(provided) => (
                      <div
                        className="flex flex-col gap-3 min-h-full"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <TableRowHeader
                          name="Shipping"
                          count={shipping.length}
                          bgColor="bg-[#e8daf8]"
                        />
                        {shipping.map((order, index) => (
                          <Draggable
                            key={order.id}
                            draggableId={order.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex flex-col gap-3"
                                style={{
                                  ...provided.draggableProps.style,
                                  transform: snapshot.isDragging
                                    ? `${provided.draggableProps.style.transform} rotate(-5deg)`
                                    : provided.draggableProps.style.transform,
                                  transition: snapshot.isDragging
                                    ? "transform 0.2s ease"
                                    : "none",
                                  background: snapshot.isDragging
                                    ? "linear-gradient(0deg, rgba(76, 140, 74, 0.10) 0%, rgba(76, 140, 74, 0.10) 100%), #FFF"
                                    : "",
                                  border: snapshot.isDragging
                                    ? "1px solid var(--Success, #4C8C4A)"
                                    : "",
                                }}
                              >
                                <OrderDetailsCard
                                  order={order}
                                  isShipping={true}
                                  shippingLabels={order.shipping_labels}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
              {selectedStatus === "" && <p className="border-e" />}

              {/* In Progress Column */}
              {(selectedStatus === "" || selectedStatus === "inProgress") && (
                <div className="max-w-[224px] flex flex-col flex-1 min-h-full">
                  <Droppable droppableId={IN_PROGRESS}>
                    {(provided) => (
                      <div
                        className="flex flex-col gap-3 min-h-full"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <TableRowHeader
                          name="In progress"
                          count={inProgress.length}
                          bgColor="bg-[#d8ede0]"
                        />
                        {inProgress.map((order, index) => (
                          <Draggable
                            key={order.id}
                            draggableId={order.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex flex-col gap-3"
                                style={{
                                  ...provided.draggableProps.style,
                                  transform: snapshot.isDragging
                                    ? `${provided.draggableProps.style.transform} rotate(-5deg)`
                                    : provided.draggableProps.style.transform,
                                  transition: snapshot.isDragging
                                    ? "transform 0.2s ease"
                                    : "none",
                                  background: snapshot.isDragging
                                    ? "linear-gradient(0deg, rgba(76, 140, 74, 0.10) 0%, rgba(76, 140, 74, 0.10) 100%), #FFF"
                                    : "",
                                  border: snapshot.isDragging
                                    ? "1px solid var(--Success, #4C8C4A)"
                                    : "",
                                }}
                              >
                                <OrderDetailsCard order={order} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
              {selectedStatus === "" && <p className="border-e" />}

              {/* Delayed Column */}
              {(selectedStatus === "" || selectedStatus === "delayed") && (
                <div className="max-w-[224px] flex flex-col flex-1 min-h-full">
                  <Droppable droppableId={DELAYED}>
                    {(provided) => (
                      <div
                        className="flex flex-col gap-3 min-h-full"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <TableRowHeader
                          name="Delayed"
                          count={delayed.length}
                          bgColor="bg-[#fadfbf]"
                        />
                        {delayed.map((order, index) => (
                          <Draggable
                            key={order.id}
                            draggableId={order.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex flex-col gap-3"
                                style={{
                                  ...provided.draggableProps.style,
                                  transform: snapshot.isDragging
                                    ? `${provided.draggableProps.style.transform} rotate(-5deg)`
                                    : provided.draggableProps.style.transform,
                                  transition: snapshot.isDragging
                                    ? "transform 0.2s ease"
                                    : "none",
                                  background: snapshot.isDragging
                                    ? "linear-gradient(0deg, rgba(76, 140, 74, 0.10) 0%, rgba(76, 140, 74, 0.10) 100%), #FFF"
                                    : "",
                                  border: snapshot.isDragging
                                    ? "1px solid var(--Success, #4C8C4A)"
                                    : "",
                                }}
                              >
                                <OrderDetailsCard order={order} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
              {selectedStatus === "" && <p className="border-e" />}

              {/* Repaired Column */}
              {selectedStatus === "" && (
                <div className="max-w-[224px] flex flex-col flex-1 min-h-full">
                  <Droppable droppableId={REPAIRED}>
                    {(provided) => (
                      <div
                        className="flex flex-col gap-3 min-h-full"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <TableRowHeader
                          name="Repaired"
                          count={repaired.length}
                          bgColor="bg-[#e8e8e8]"
                        />
                        {repaired.map((order, index) => (
                          <Draggable
                            key={order.id}
                            draggableId={order.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex flex-col gap-3"
                                style={{
                                  ...provided.draggableProps.style,
                                  transform: snapshot.isDragging
                                    ? `${provided.draggableProps.style.transform} rotate(-5deg)`
                                    : provided.draggableProps.style.transform,
                                  transition: snapshot.isDragging
                                    ? "transform 0.2s ease"
                                    : "none",
                                  background: snapshot.isDragging
                                    ? "linear-gradient(0deg, rgba(76, 140, 74, 0.10) 0%, rgba(76, 140, 74, 0.10) 100%), #FFF"
                                    : "",
                                  border: snapshot.isDragging
                                    ? "1px solid var(--Success, #4C8C4A)"
                                    : "",
                                }}
                              >
                                <OrderDetailsCard order={order} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
              {selectedStatus === "" && <p className="border-e" />}

              {/* Delivered Column */}
              {(selectedStatus === "" || selectedStatus === "delivered") && (
                <div className="max-w-[224px] flex flex-col flex-1 min-h-full">
                  <Droppable droppableId={DELIVERED}>
                    {(provided) => (
                      <div
                        className="flex flex-col gap-3 min-h-full"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <TableRowHeader
                          name="Delivered"
                          count={delivered.length}
                          bgColor="bg-[#e1e1e1]"
                        />
                        {delivered.map((order, index) => (
                          <Draggable
                            key={order.id}
                            draggableId={order.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex flex-col gap-3"
                                style={{
                                  ...provided.draggableProps.style,
                                  transform: snapshot.isDragging
                                    ? `${provided.draggableProps.style.transform} rotate(-5deg)`
                                    : provided.draggableProps.style.transform,
                                  transition: snapshot.isDragging
                                    ? "transform 0.2s ease"
                                    : "none",
                                  background: snapshot.isDragging
                                    ? "linear-gradient(0deg, rgba(76, 140, 74, 0.10) 0%, rgba(76, 140, 74, 0.10) 100%), #FFF"
                                    : "",
                                  border: snapshot.isDragging
                                    ? "1px solid var(--Success, #4C8C4A)"
                                    : "",
                                }}
                              >
                                <OrderDetailsCard order={order} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-3 bg-[#fcfbf9] min-w-max">
              <div className="relative">
                <div className="h-[50px]" />
                <div
                  style={{
                    marginLeft: leftScroll,
                  }}
                  className={`flex flex-row absolute bottom-[13px] w-screen justify-center bg-[#f7f5f0] py-2`}
                >
                  <Typography style={theme.typography.body}>
                    Orders total
                  </Typography>
                </div>
              </div>
              <div className="overflow-x-auto">
                <div className="flex flex-row gap-1 justify-between w-full min-w-max">
                  <div className="w-[22%] min-w-[255px] flex justify-center py-4">
                    <p>${sumBy(quoteOrders, "net_total")}</p>
                  </div>
                  <p className="border-e" />
                  <div className="w-[22%] min-w-[255px] flex justify-center py-4">
                    <p>${sumBy(newOrders, "net_total")}</p>
                  </div>
                  <p className="border-e" />
                  <div className="w-[22%] min-w-[255px] flex justify-center py-4">
                    <p>${sumBy(shipping, "net_total")}</p>
                  </div>
                  <p className="border-e" />
                  <div className="w-[22%] min-w-[255px] flex justify-center py-4">
                    <p>${sumBy(inProgress, "net_total")}</p>
                  </div>
                  <p className="border-e" />
                  <div className="w-[22%] min-w-[255px] flex justify-center py-4">
                    <p>${sumBy(delayed, "net_total")}</p>
                  </div>
                  <p className="border-e" />
                  <div className="w-[22%] min-w-[255px] flex justify-center py-4">
                    <p>${sumBy(repaired, "net_total")}</p>
                  </div>
                  <p className="border-e" />
                  <div className="w-[22%] min-w-[255px] flex justify-center py-4">
                    <p>${sumBy(delivered, "net_total")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};
