import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, Divider, IconButton } from '@mui/material';
import { PAYMENT_METHODS, POS_NEW_ORDER_STEPS } from '../../../constants';
import { calculateTotal } from '../../../utils';
import CustomDatePicker from '../../Form/Field/DatePicker';
import { Add } from "@mui/icons-material";
import { LeftArrowIcon } from '../../../common/icons';
import { deleteRequest } from '../../../api';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../redux/toastSlice';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../redux/authSlice';
import CartBadge from "./CartBadge";
import { useMediaQuery } from "react-responsive";

const PaymentMethod = ({
  cart,
  setCart,
  currentStep,
  setCurrentStep,
  isDesktopScreen,
}) => {
  const [enableStripeCharge, setEnableStripeCharge] = useState(false);
  const [publishableKey, setPublishableKey] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);
  const isTabletScreen = useMediaQuery({ query: "(min-width: 768px)" });

  const handleBack = async () => {
    try {
      const response = await deleteRequest(
        `stores/${userData.stores[0].id}/payments/${cart?.payment_id}`
      );

      if (response) {
        setCart((prev) => ({
          ...prev,
          type: null,
          paymentMethod: null,
          payment_id: null,
        }));
        isDesktopScreen
          ? setCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM)
          : setCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART);
      }
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const handleEstimatedDateChange = (e) => {
    setCart({ ...cart, estimateCompleteDate: e });
  };

  useEffect(() => {
    if (userData) {
      setEnableStripeCharge(
        userData?.organization?.connect_acc_charges_enabled
      );
      setPublishableKey(userData?.organization?.connect_acc_publishable_key);
    }
  }, [userData?.organization]);

  return (
    <Box className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[55%] sm:m-auto">
        <div>
          <CartBadge
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />

          <div className="flex flex-col gap-5">
            <p className="text-xl font-semibold font-['Montserrat'] leading-7 lg:hidden">
              Checkout
            </p>
            <Box className="flex flex-row justify-between w-full items-center gap-6">
              <span className="text-base xl:text-2xl xl:font-bold font-normal font-['Questrial'] leading-snug">
                Total amount
              </span>
              <span className="text-lg xl:text-2xl xl:font-bold font-['Montserrat'] leading-[25.20px]">
                ${calculateTotal(cart).toFixed(2)}
              </span>
            </Box>
            <Box className="flex flex-col md:flex-row md:justify-between md:items-center w-full gap-2 xl:mt-6">
              <p className="text-base xl:text-2xl lg:font-bold font-normal font-['Questrial'] leading-snug">
                Est. Completion date
              </p>
              <CustomDatePicker
                value={cart.estimateCompleteDate}
                textFieldStyles={{ width: isTabletScreen ? "50%" : "100%" }}
                onChange={handleEstimatedDateChange}
              />
            </Box>
          </div>

          <hr className="my-3 lg:my-5" />

          <div className="flex flex-col gap-5">
            <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Choose a payment method below
            </p>
            <Box className="flex justify-between w-full gap-3">
              <Button
                variant="selectablePrimary"
                className="w-1/2"
                isSelected={cart.paymentMethod == PAYMENT_METHODS.CASH}
                onClick={() =>
                  setCart((prev) => ({
                    ...prev,
                    paymentMethod: PAYMENT_METHODS.CASH,
                  }))
                }
              >
                Cash
              </Button>
              <Button
                variant="selectablePrimary"
                className="w-1/2"
                disabled={!enableStripeCharge}
                isSelected={cart.paymentMethod == "card_present" ? true : false}
                onClick={() =>
                  setCart((prev) => ({
                    ...prev,
                    paymentMethod: "card_present",
                  }))
                }
              >
                Debit/Credit
              </Button>
            </Box>
            <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              *To continue processing the payment, please click [Confirm] below
              to swipe the card. You can also manually add card info.
            </p>
            <div className="flex flex-row justify-end items-center">
              <Button
                variant="textSecondary"
                disabled={
                  !enableStripeCharge ||
                  !publishableKey ||
                  !cart.estimateCompleteDate
                }
                onClick={() => {
                  setCart((prev) => ({ ...prev, paymentMethod: "card" }));
                  setCurrentStep(POS_NEW_ORDER_STEPS.CARD_PAYMENT);
                }}
                startIcon={<Add />}
              >
                Manually add card info
              </Button>
            </div>
          </div>
        </div>

        <Box className="flex flex-col justify-between w-full gap-2 mt-8">
          <Button
            variant="containedPrimary"
            className="flex-1"
            disabled={!cart.paymentMethod || !cart.estimateCompleteDate}
            onClick={() => {
              setCurrentStep(
                cart.paymentMethod === PAYMENT_METHODS.CASH
                  ? POS_NEW_ORDER_STEPS.CALCULATE_PAYMENT
                  : POS_NEW_ORDER_STEPS.SCAN_CARD
              );
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlinedSecondary"
            className="flex-1"
            onClick={handleBack}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentMethod;
