import React from "react";

const FieldGroup = ({ label, children }) => {
  return (
    <div className="flex flex-col gap-8 my-5">
      {label && (
        <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          {label}
        </p>
      )}
      <div className="flex flex-col gap-5">{children}</div>
    </div>
  );
};

export default FieldGroup;
