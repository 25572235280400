import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";

import { getRoutes } from "./config";
import { Drawer, DrawerHeader } from "./muiTheme";

import Logo from "../../images/logo.png";
import CameraSVG from "../../images/camera.svg";

import { logout } from "../../redux/authActions";
import { Avatar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/authSlice";
import { diffImageSrc } from "../../utils";

export default function MiniDrawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const userData = useSelector(selectCurrentUser);
  const routes = getRoutes(location);

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const handleDrawerClose = () => {
    setOpenDropdown(null);
    setIsNavbarOpen(false);
  };

  const handleDrawerToggle = () => {
    setIsNavbarOpen((prev) => !prev);
  };

  const handleClick = (route) => {
    if (route.name === "logout") return handleLogout();

    if (route.href) {
      window.open(route.href, "_blank");
    } else if (route?.childs?.length > 0 && isNavbarOpen) {
      setOpenDropdown((prevOpenDropdown) =>
        prevOpenDropdown === route.name ? null : route.name
      );
    } else {
      navigate(route.path);
      if (isMobileView) handleDrawerClose();
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      {isMobileView && (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mx: "auto", margin: 1 }}
          >
            <MenuIcon sx={{ width: "35px", height: "35px" }} />
          </IconButton>

          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              position: "fixed",
              top: "25px",
              left: "212px",
              zIndex: 1300,
              display: isNavbarOpen ? "block" : "none",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: 0,
              border: "0.5px solid #939291",
            }}
          >
            <ChevronLeftIcon
              sx={{ display: "flex", width: "28px", height: "28px" }}
            />
          </IconButton>
        </>
      )}

      <Drawer
        variant="permanent"
        open={isNavbarOpen}
        sx={{
          display: isMobileView && !isNavbarOpen ? "none" : "block",
          "& .MuiPaper-root": {
            borderRadius: "0px 20px 20px 0px",
            boxShadow: "2px 0px 5px rgba(136, 136, 136, 0.2)",
          },
        }}
        onMouseEnter={() => setIsNavbarOpen(true)}
        onMouseLeave={() => !isMobileView && handleDrawerClose()}
      >
        <DrawerHeader>
          <ListItemButton
            sx={{
              minHeight: 40,
              justifyContent: isNavbarOpen ? "initial" : "center",
              px: 1,
              py: 0,
              ml: 4,
              mr: isNavbarOpen ? 2 : 4,
              my: 1,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isNavbarOpen ? 1 : "auto",
                justifyContent: "center",
              }}
            >
              <Avatar
                alt="Profile Image"
                src={
                  userData?.avatar_url
                    ? diffImageSrc(userData?.avatar_url)
                    : CameraSVG
                }
              />
            </ListItemIcon>
            <Typography
              className={`text-stone-800 text-base font-normal font-['Questrial'] leading-snug transition-opacity duration-300 ${
                isNavbarOpen ? "opacity-100" : "hidden"
              }`}
            >
              {userData?.organization?.name || "[Organization] name"}
            </Typography>
          </ListItemButton>
        </DrawerHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <List>
            {routes.map((route, index) => {
              const showRoute = route?.roles?.includes(userData?.stores_users?.[0]?.role);
              return route.display && (showRoute || route.isDivider) ? (route.isDivider ?
              (
              <Divider sx={{ my: 5, mx: "auto", width: "75%" }} key={index} />
              ) : (
              <ListItem disablePadding sx={{ display: "block" }} key={index}>
                <ListItemButton
                  sx={{
                    minHeight: 40,
                    justifyContent: isNavbarOpen ? "initial" : "center",
                    bgcolor: route.isActive ? "#F3F0E880" : "initial",
                    borderRadius: "12px",
                    px: 1,
                    py: 0,
                    ml: 4,
                    mr: isNavbarOpen ? 2 : 4,
                    my: 1,
                  }}
                  onClick={() => handleClick(route)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isNavbarOpen ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <route.icon
                      className="w-6 h-6"
                      isActive={route.isActive}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={route.title}
                    className={`transition-opacity duration-300 ${
                      isNavbarOpen ? "opacity-100" : "opacity-0"
                    }`}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        color: route.isActive ? "#272523" : "#939291",
                      },
                    }}
                  />
                  {isNavbarOpen &&
                    route?.childs?.length > 0 &&
                    (openDropdown === route.name ? (
                      <ExpandLess
                        sx={{ color: route.isActive ? "#272523" : "#939291" }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{ color: route.isActive ? "#272523" : "#939291" }}
                      />
                    ))}
                </ListItemButton>
                {route?.childs?.length > 0 && (
                  <Collapse
                    in={openDropdown === route.name}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {route.childs.map((childRoute, childIndex) => (
                        <ListItemButton
                          key={childIndex}
                          sx={{
                            borderRadius: "12px",
                            px: 1,
                            py: "3px",
                            ml: 4,
                            mr: isNavbarOpen ? 2 : 4,
                          }}
                          onClick={() => handleClick(childRoute)}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: isNavbarOpen ? 4 : "auto",
                              justifyContent: "center",
                            }}
                          ></ListItemIcon>
                          <ListItemText
                            primary={childRoute.title}
                            primaryTypographyProps={{
                              sx: {
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                color: childRoute.isActive
                                  ? "#272523"
                                  : "#939291",
                              },
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </ListItem>
              )) : (<></>)
            }
            )
            }
          </List>
          <Box sx={{ mb: 6 }}>
            <img
              src={Logo}
              alt="logo"
              className={`min-w-0 m-auto justify-center h-8 w-auto`}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
