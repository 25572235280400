import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { getRequest, updateRequest } from "../api";
import {
  ORDER_LINE_ITEMS_DETAILS,
  UPDATE_ORDER_SRATUS_AND_SHIPPING_INFO,
} from "../constants/BackendRoutes";
import theme from "../theme";
import Capsule from "../components/Capsule";
import { destroyDataFromLocalStorage } from "../utils/localStorage";
import { caseConversion } from "../utils";
import { ORDER_STATUS } from "../constants";
import { OrderStatus } from "../constants/OrderStatus";
import TextInput from "../components/Form/Field/TextInput";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";

const LineItemsDetails = () => {
  const dispatch = useDispatch();

  const [lineItem, setLineItem] = useState({});
  const [currentStep, setCurrentStep] = useState("details");
  const [updateStatus, setUpdateStatus] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const { token } = useParams();

  const getLineItem = async () => {
    try {
      const response = await getRequest(
        `${ORDER_LINE_ITEMS_DETAILS}/${token}`,
        {},
        "order.user"
      );
      setLineItem(response);
      setUpdateStatus(response.order.status);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setUpdateStatus(event.target.value);
  };

  const handleSubmit = async () => {
    let reqBody = {
      id: token,
      status: updateStatus,
    };
    if (
      [OrderStatus.REPAIRED, OrderStatus.SHIPPING].includes(updateStatus) &&
      trackingNumber !== ""
    ) {
      reqBody = {
        ...reqBody,
        shipping_info: {
          shipping_leg:
            updateStatus === ORDER_STATUS.SHIPPING ? "leg_2" : "leg_3",
          tracking_number: trackingNumber,
        },
      };
    }
    try {
      const res = await updateRequest(
        UPDATE_ORDER_SRATUS_AND_SHIPPING_INFO,
        reqBody
      );
      if (res.status === 200) {
        setCurrentStep("details");
        getLineItem();
        dispatch(addToast("Order updated"));
      }
    } catch (error) {
      dispatch(addToast(error.message));
    }
  };

  const orderStatusArray = Object.keys(ORDER_STATUS)
    .filter((item) => item !== "quote")
    .map((key) => ({
      key,
      value: ORDER_STATUS[key],
    }));

  useEffect(() => {
    if (token) {
      getLineItem();
    }
  }, [token]);

  return (
    <div className="max-w-screen-md mx-auto px-2 py-8 h-screen">
      <div className="flex flex-col gap-3 w-96">
        <Typography style={theme.typography.subTitle}>
          Order # {lineItem?.order?.id}
        </Typography>
        <Divider />
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-2">
            <Typography
              style={{
                ...theme.typography.info,
                color: theme.palette.text.secondary,
              }}
            >
              Current status
            </Typography>
            <Capsule
              text={caseConversion(lineItem?.order?.status)}
              textColor="text-[#BF975F]"
              capsuleColor="bg-[#F3F0E8]"
            />
          </div>
          {currentStep === "details" && (
            <Button
              variant="containedPrimary"
              onClick={() => setCurrentStep("")}
            >
              Update status
            </Button>
          )}
        </div>
        {currentStep === "details" ? (
          <div>
            <Typography
              style={{
                ...theme.typography.info,
              }}
            >
              Here are basic info for this item:
            </Typography>

            <div className="border rounded-lg p-2">
              <div className="flex flex-col gap-3 p-2">
                <Typography
                  style={{
                    ...theme.typography.info,
                    color: theme.palette.text.secondary,
                  }}
                >
                  Due Date
                </Typography>
                <Typography style={theme.typography.body}>
                  {lineItem?.order?.estimated_completion || "---"}
                </Typography>
                <Divider />
                <Typography
                  style={{
                    ...theme.typography.info,
                    color: theme.palette.text.secondary,
                  }}
                >
                  Item Name
                </Typography>
                <Typography style={theme.typography.body}>
                  {lineItem?.details?.category}
                </Typography>
                {lineItem?.details?.services
                  ?.map((item) => item.name)
                  .filter(
                    (item) =>
                      !item.includes("- No ") &&
                      !item.includes("any additional comments")
                  )
                  .map((item) => (
                    <Typography style={theme.typography.body}>
                      {item}
                    </Typography>
                  ))}
                {lineItem?.details?.options?.length !== 0 && (
                  <>
                    <Typography
                      style={{
                        ...theme.typography.info,
                        color: theme.palette.text.secondary,
                      }}
                    >
                      Options(s)
                    </Typography>
                    {lineItem?.details?.options?.map((item) => (
                      <Typography style={theme.typography.body}>
                        {item.name}
                      </Typography>
                    ))}
                  </>
                )}
                <Divider />
                <Typography
                  style={{
                    ...theme.typography.info,
                    color: theme.palette.text.secondary,
                  }}
                >
                  Item Category
                </Typography>
                <Typography style={theme.typography.body}>
                  {lineItem?.details?.categoryType}
                </Typography>
                <Typography
                  style={{
                    ...theme.typography.info,
                    color: theme.palette.text.secondary,
                  }}
                >
                  Description
                </Typography>
                <Typography style={theme.typography.body}>
                  {lineItem?.details?.description || "---"}
                </Typography>
                <Divider />
                <Typography
                  style={{
                    ...theme.typography.info,
                    color: theme.palette.text.secondary,
                  }}
                >
                  Customer Name
                </Typography>
                <Typography style={theme.typography.body}>
                  {lineItem?.order?.user?.name}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Typography style={theme.typography.info}>
              Please select a status:
            </Typography>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <Select value={updateStatus} onChange={handleChange}>
                  {orderStatusArray.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {[OrderStatus.REPAIRED, OrderStatus.SHIPPING].includes(
              updateStatus
            ) && (
              <>
                <div className="flex flex-col gap-5">
                  <Divider />
                  <Typography style={theme.typography.info}>
                    There’s no shipping info attached to this order yet, please
                    enter the tracking number below:
                  </Typography>
                  <div>
                    <Typography style={theme.typography.info}>
                      Tracking number:
                    </Typography>
                    <TextInput
                      name="trackingNumber"
                      value={trackingNumber}
                      onChange={(e) => setTrackingNumber(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="fixed bottom-20 left-0 w-full flex justify-center px-4 box-border">
              <Button
                variant="containedPrimary"
                onClick={handleSubmit}
                className="w-full max-w-xs"
              >
                Apply
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LineItemsDetails;
