import { getRequest, postRequest, deleteRequest } from "../api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  USERS_SIGN_IN,
  USERS_BASE,
  USERS_SIGN_OUT,
} from "../constants/BackendRoutes";
import { destroyDataFromLocalStorage } from "../utils/localStorage";
import { BASIC_AUTH_ROLES, All_AUTH_ROLES, USER_STATUS_MAPPER } from "../constants";

export const login = createAsyncThunk(
  "auth/login",
  async (creds, { rejectWithValue }) => {
    try {
      let res = await postRequest(USERS_SIGN_IN, creds);
      const firstStoreUser = res?.data?.stores_users.find(user => BASIC_AUTH_ROLES.includes(user.role)) || res?.data?.stores_users.find(user => All_AUTH_ROLES.includes(user.role));
      res.data.stores_users = firstStoreUser ? [firstStoreUser] : [];
      const foundStore = res.data.stores.find(store => store.id === res?.data?.stores_users?.[0]?.store_id);

      res.data.stores = foundStore ? [foundStore] : [];

      if (res.data.stores_users.length === 0) {
        res.data.status = USER_STATUS_MAPPER.ONBOARDING;
      }
      return {
        ...res.data,
        token: res.headers.authorization,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      await deleteRequest(USERS_SIGN_OUT);
      destroyDataFromLocalStorage();
      return true;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async (id, { rejectWithValue }) => {
    try {
      let userData = await getRequest(
        `${USERS_BASE}/${id}`,
        {},
        "organization.subscription.plan,organization.addresses,stores.addresses,stores_users,stores.service_types,stores.categories,organization.subscription.add_on"
      );
      const foundStoreUser = userData.stores_users.find(user => BASIC_AUTH_ROLES.includes(user.role)) || userData.stores_users.find(user => All_AUTH_ROLES.includes(user.role));
      userData.stores_users = foundStoreUser ? [foundStoreUser] : [];
      const foundStore = userData?.stores?.find(store => store.id === userData?.stores_users?.[0]?.store_id);
      userData.stores = userData.stores_users[0] ? [foundStore] : [];
      if (userData.stores_users.length === 0) {
        userData.status = USER_STATUS_MAPPER.ONBOARDING;
      }
      return userData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
