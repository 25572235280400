import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import shipstation from "../../../images/shipstation.svg";
import { addToast } from "../../../redux/toastSlice";
import { postRequest, updateRequest } from "../../../api";
import { STRIPE_CONNECT_ACC_LINK_TYPES } from "../../../constants";
import { setLoading } from "../../../redux/loadingSlice";
import { fetchUserData } from "../../../redux/authActions";

const ShipStation = ({ userData }) => {
  const [formData, setFormData] = useState({
    shipstationKey: "",
    shipstationSecret: "",
  });
  const [subscription, setSubscription] = useState();
  const [isDetailsSubmitted, setIsDetailSubmitted] = useState(false);
  const [isChargesEnabled, setIsChargesEnabled] = useState(false);
  const [connectPublishableKey, setConnectPublishableKey] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const handleOnboardingLink = async () => {
    try {
      dispatch(setLoading(true));
      const response = await postRequest(
        `stripe/connect_accounts/create_link`,
        {
          organization_id: userData?.organization?.id,
          link_type: STRIPE_CONNECT_ACC_LINK_TYPES.ONBOARDING,
        }
      );

      if (response && response.status === 200)
        window.open(response.data?.link, "_blank");
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleKeySubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await updateRequest(
        `organizations/${userData?.organization?.id}`,
        {
          organization: {
            shipstation_token: btoa(
              `${formData?.shipstationKey}:${formData?.shipstationSecret}`
            ),
          },
        }
      );

      if (response.status === 200) {
        setIsDialogOpen(false);
        dispatch(fetchUserData(userData?.id));
        dispatch(addToast("ShipStation token saved."));
      }
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  useEffect(() => {
    if (userData?.organization) {
      setSubscription(userData?.organization?.subscription);
      setIsDetailSubmitted(
        userData?.organization?.connect_acc_details_submitted
      );
      setIsChargesEnabled(userData?.organization?.connect_acc_charges_enabled);
      setConnectPublishableKey(
        userData?.organization?.connect_acc_publishable_key
      );
    }
  }, [userData?.organization]);

  return (
    <div className="flex flex-col xl:flex-row gap-6 w-full justify-between rounded-lg xl:items-center">
      <div className="flex flex-col lg:flex-row gap-7 items-start lg:items-center xl:w-[70%]">
        <img
          src={shipstation}
          alt="Shipstation Logo"
          className="w-16 h-auto "
        />

        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px] flex gap-2 items-center">
            Shipstation
          </div>
          <div className="self-stretch text-[#5d5c5a] text-base font-normal font-['Questrial'] leading-snug">
            Easily create shipping labels for your mail-in business with
            ShipStation.
          </div>
        </div>
      </div>

      {
        <Button
          variant="containedPrimary"
          className={`${
            !subscription ? "cursor-not-allowed" : ""
          } rounded-lg h-fit`}
          onClick={handleDialogOpen}
        >
          Configure
        </Button>
      }

      {subscription && isDetailsSubmitted && isChargesEnabled && (
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-green-500 rounded-full flex items-center justify-center"></div>
          <p className="text-base font-normal font-['Questrial'] leading-snug">
            Live
          </p>
        </div>
      )}

      {subscription && isDetailsSubmitted && !isChargesEnabled && (
        <div className="flex items-center gap-2 cursor-not-allowed">
          <p className="text-base font-normal text-stone-300 font-['Questrial'] leading-snug">
            Charge Disabled
          </p>
        </div>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Add ShipStation Token</DialogTitle>
        <form onSubmit={handleKeySubmit}>
          <DialogContent className="self-stretch text-[#5d5c5a] text-base font-normal font-['Questrial'] leading-snug">
            Easily create shipping labels for your mail-in business with
            ShipStation.
          </DialogContent>
          <DialogContent className="flex flex-col gap-5">
            <TextField
              label="ShipStation API Key"
              variant="outlined"
              name="shipstationKey"
              onChange={handleChange}
              type="text"
              fullWidth
            />
            <TextField
              label="ShipStation API Secret"
              variant="outlined"
              name="shipstationSecret"
              onChange={handleChange}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ShipStation;
