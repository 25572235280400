import React from 'react';
import { DraftIcon, FlagIcon, InboxIcon, SentIcon, TrashIcon } from '../../common/icons';

function Type({ selectedListItem, setSelectedListItem }) {
  return (
    <div className="flex flex-col px-6 sm:px-0 sm:items-center gap-3 text-[#939291] my-3 sm:mt-5">
      <div className="flex flex-row sm:flex-col gap-5 sm:gap-6 justify-between">
        <div
          className={`flex flex-row items-center gap-7`}
          onClick={() => setSelectedListItem('Inbox')}
        >
          <InboxIcon className="w-7 h-7 hidden sm:block" />
          <span
            className={`${
              selectedListItem === 'Inbox' &&
              ' text-black underline decoration-4 underline-offset-8 decoration-green-600 sm:no-underline'
            }`}
          >
            Inbox
          </span>
        </div>

        <div
          className="flex flex-row items-center gap-7"
          onClick={() => setSelectedListItem('Flagged')}
        >
          <FlagIcon className="w-7 h-7 hidden sm:block" />
          <span
            className={`${
              selectedListItem === 'Flagged' &&
              ' text-black underline decoration-4 underline-offset-8 decoration-green-600 sm:no-underline'
            }`}
          >
            Flagged
          </span>
        </div>

        <div
          className="flex flex-row items-center gap-7"
          onClick={() => setSelectedListItem('Sent')}
        >
          <SentIcon className="w-7 h-7 hidden sm:block" />
          <span
            className={`${
              selectedListItem === 'Sent' &&
              ' text-black underline decoration-4 underline-offset-8 decoration-green-600 sm:no-underline'
            }`}
          >
            Sent
          </span>
        </div>

        <div
          className="flex flex-row items-center gap-7"
          onClick={() => setSelectedListItem('Drafts')}
        >
          <DraftIcon className="w-7 h-7 hidden sm:block" />
          <span
            className={`${
              selectedListItem === 'Drafts' &&
              ' text-black underline decoration-4 underline-offset-8 decoration-green-600 sm:no-underline'
            }`}
          >
            Drafts
          </span>
        </div>

        <div
          className="flex flex-row items-center gap-7"
          onClick={() => setSelectedListItem('Trash')}
        >
          <TrashIcon className="w-7 h-7 hidden sm:block" />
          <span
            className={`${
              selectedListItem === 'Trash' &&
              ' text-black underline decoration-4 underline-offset-8 decoration-green-600 sm:no-underline'
            }`}
          >
            Trash
          </span>
        </div>
      </div>
    </div>
  );
}

export default Type;
