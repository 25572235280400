import React, { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../Button/Menu";
import NumberInput from "../../Form/NumberInput"
import ServiceItem from "./ServiceItem";
import ProductItem from "./ProductItem";
import CartBadge from "./CartBadge";

const ITEM_OPTIONS = {
  service_list: "Service List",
  product_list: "Product List",
};

const AddItem = ({ cart, setCart, currentStep, setCurrentStep, products, subCategories, addServiceLineItem, addProductLineItem }) => {
  const [itemType, setItemType] = useState(ITEM_OPTIONS.service_list);

  const [quantity, setQuantity] = useState(1);
  const [currentServices, setCurrentServices] = useState([]);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const [currentCategoryType, setCurrentCategoryType] = useState();

  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  const resetSelections = () => {
    setCurrentServices([]);
    setCurrentOptions([]);
    setCurrentProduct();
    setCurrentCategoryType();
    setDescription("");
    setShowDescription(false);
  };

  const handleCurrentCategoryType = (cT) => {
    resetSelections();
    setCurrentCategoryType(cT);
  };

  const isButtonDisabled = () => {
    if (itemType === ITEM_OPTIONS.service_list) {
      return currentOptions.length < 1 && currentServices.length < 1;
    } else {
      return !currentProduct;
    }
  };

  const handleLineItems = () => {
    if (isButtonDisabled()) return;

    if (itemType === ITEM_OPTIONS.service_list && currentCategoryType) {
      addServiceLineItem({
        category: currentCategoryType.category,
        categoryType: currentCategoryType.name,
        services: currentServices,
        options: currentOptions,
        quantity,
        description,
      });
    } else if (itemType === ITEM_OPTIONS.product_list) {
      addProductLineItem({
        ...currentProduct,
        quantity,
        description,
      });
    }
    setQuantity(1);
    resetSelections();
  };

  return (
    <div className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <div className="overflow-y-auto p-0 lg:px-5 lg:pt-5">
        <CartBadge
          cart={cart}
          setCart={setCart}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
        <div className="flex flex-col gap-2 lg:gap-4 items-start">
          <MenuButton
            options={Object.values(ITEM_OPTIONS)}
            variant="outlinedTertiary"
            onChange={(e) => {
              setItemType(e);
              resetSelections();
            }}
          />

          {itemType === ITEM_OPTIONS.service_list ? (
            <ServiceItem
              subCategories={subCategories}
              currentServices={currentServices}
              setCurrentServices={setCurrentServices}
              currentOptions={currentOptions}
              setCurrentOptions={setCurrentOptions}
              currentCategoryType={currentCategoryType}
              setCurrentCategoryType={handleCurrentCategoryType}
              resetSelections={resetSelections}
            />
          ) : (
            <ProductItem
              products={products}
              currentProduct={currentProduct}
              setCurrentProduct={setCurrentProduct}
            />
          )}
        </div>

        {showDescription && (
          <div className="flex flex-col mb-4">
            <p className="text-lg font-bold my-4">Description</p>
            <TextField
              id="outlined-multiline"
              placeholder="Description"
              multiline
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
            />
          </div>
        )}
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: showDescription ? "flex-end" : "space-between",
          alignItems: "center",
          gap: 2,
          borderBottomLeftRadius: "lg",
          borderBottomRightRadius: "lg",
        }}
        className="pt-4 lg:px-5 lg:pb-2"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 4,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          {!showDescription && (
            <Button
              variant="textSecondary"
              startIcon={<Add />}
              sx={{
                margin: { xs: "0px", sm: "16px" },
              }}
              disabled={isButtonDisabled()}
              onClick={() => setShowDescription(true)}
            >
              Additional order details
            </Button>
          )}

          <Box
            sx={{
              display: { xs: "inline-flex", sm: "none" },
            }}
          >
            {(currentServices.length > 0 ||
              currentOptions.length > 0 ||
              currentProduct) && (
              <NumberInput quantity={quantity} setQuantity={setQuantity} />
            )}
          </Box>
        </Box>

        <Box className="flex gap-2 w-full justify-end lg:items-center sm:w-auto">
          <Box
            sx={{
              display: { xs: "none", sm: "inline-flex" },
            }}
          >
            {(currentServices.length > 0 ||
              currentOptions.length > 0 ||
              currentProduct) && (
              <NumberInput quantity={quantity} setQuantity={setQuantity} />
            )}
          </Box>
          <Button
            variant="outlinedSecondary"
            onClick={() => navigate(-1)}
            sx={{
              display: { xs: "none", sm: "inline-flex" },
              width: { xs: "100%", sm: "fit-content" },
            }}
          >
            Cancel
          </Button>

          <Button
            variant="containedPrimary"
            disabled={isButtonDisabled()}
            onClick={handleLineItems}
            sx={{
              width: { xs: "100%", sm: "fit-content" },
            }}
          >
            Add item
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default AddItem;
