import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import SuccessFrame from "../../images/success-frame.png"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentUser, updateUser } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../constants/FrontendRoutes";
import { getRequest } from "../../api";
import { USERS_BASE } from "../../constants/BackendRoutes";

const Success = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const handleDashboardMove = async() => {
    const response = await getRequest(`${USERS_BASE}/${userData?.id}`, {}, "stores.addresses,stores_users");

    if (response && response.id && response.status == 'active') {
      dispatch(updateUser(response));
      navigate(DASHBOARD)
    }
  }

  return (
    <div className="flex flex-col gap-3 m-8">
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px] flex flex-col">
        <span>🎉 Congratulations!</span>
        <span>Your account has been created</span>
      </div>

      <img src={SuccessFrame} />

      <div className="flex flex-col sm:flex-row gap-5 mt-5">
        <Button
          variant="containedPrimary"
          className="w-full"
          onClick={handleDashboardMove}
        >
          Continue to dashboard
        </Button>
      </div>
    </div>
  );
};

export default Success;
