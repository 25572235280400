import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useState } from 'react';

const MenuOptionButton = ({ data, id }) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        {data.map((obj, index) => (
          <MenuItem
            key={index}
            onClick={() => (id ? obj.function(id) : obj.function())}
            disabled={obj.disabled}
          >
            {obj.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default MenuOptionButton;
