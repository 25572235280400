import React, { useRef, useState, useEffect } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import { useParams } from "react-router-dom";
import PlaceHolderImage from "../images/placeholder.png";
import {
  BarcodeScannedIcon,
  BarcodeScannedIconBackground,
  ScanIcon,
  ScannerPageBackIcon,
} from "../common/icons";
import { getRequest } from "../api";
import { SCAN_QR_AND_GET_JWT } from "../constants/BackendRoutes";

const ScannerPage = () => {
  const [isScannerOn, setIsScannerOn] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const videoRef = useRef(null);

  const [success, setSuccess] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      getJWTToken();
    }
  }, [token]);

  const getJWTToken = async () => {
    const response = await getRequest(`${SCAN_QR_AND_GET_JWT}`, { token });
    if (response.status === "success") {
      setSuccess(true);
    }
  };

  const onClickScanner = () => {
    const codeReader = new BrowserMultiFormatReader();
    codeReader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        if (videoInputDevices.length > 0) {
          const backCamera = videoInputDevices.find((device) =>
            device?.label?.toLowerCase()?.includes("back")
          );

          const selectedDeviceId = backCamera
            ? backCamera.deviceId
            : videoInputDevices[0].deviceId;

          codeReader.decodeFromVideoDevice(
            selectedDeviceId,
            videoRef.current,
            (result, err) => {
              if (result) {
                setIsScannerOn(false);
                setIsScanned(true);
                // getRequest(NEXT_STEP_BROADCASTING);
                codeReader.reset();
              }
              if (err) {
                console.error(err);
              }
            }
          );
        } else {
          console.error("No video input devices found");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setIsScannerOn(true);
  };

  return (
    <>
      {success && (
        <div className="max-w-screen-xl mx-auto p-2 h-screen">
          {isScanned ? (
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-24">
                <ScannerPageBackIcon />
                <div>
                  <div className="relative flex items-center justify-center w-full h-full mt-28">
                    <div className="absolute inset-0 flex items-center justify-center z-0">
                      <BarcodeScannedIconBackground />
                    </div>
                    <div className="absolute inset-0 flex items-center justify-center z-10">
                      <BarcodeScannedIcon />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col text-center mt-5 gap-5">
                  <p className="font-bold text-lg">Barcode Scanned, Yayy!</p>
                  <div className="flex flex-col gap-10">
                    <p>The task will be continued on your desktop.</p>
                    <p>
                      Please sit tight while we reassign your order’s status,
                      this might take a few seconds...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : isScannerOn ? (
            <video
              ref={videoRef}
              style={{ width: "100%", maxWidth: "400px" }}
            ></video>
          ) : (
            <div className="flex flex-col gap-8 w-full">
              <div className="flex flex-row gap-5 items-center">
                <img
                  src={PlaceHolderImage}
                  className="rounded-full w-16 h-auto"
                />
                <div className="flex flex-col gap-2">
                  <p className="font-semibold">👋🏻 Hello,</p>
                  <p>Ed Doe</p>
                </div>
              </div>
              <p>Your device is now linked to your Coblrshop.com account!</p>
              <p>
                Please tap the button below to start scanning the barcode and
                continue the task on your desktop.
              </p>
              <div
                onClick={onClickScanner}
                className="flex w-[342px] h-[177px] bg-[#E6ECE6] justify-center items-center rounded-xl"
              >
                <div className="flex flex-col items-center">
                  <ScanIcon />
                  <p className="text-lg">Start Scanning</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ScannerPage;
