import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Selector from "../Form/Field/AutoComplete";
import { EMPLOYEE_ROLES_OPTIONS } from "../../constants";
import { TextField } from "@mui/material";
import { PlusCircle } from "../../common/icons";
import { validateEmail } from "../../utils";

const SetupTeam = ({ teamMembers, setTeamMembers, defaultFields, handleBack, handleNext, activeStep }) => {
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const handleInputChange = (index, name, value) => {
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[index][name] = value;
    setTeamMembers(updatedTeamMembers);
  };

  const handleMore = () => {
    if (teamMembers.length < 4) {
      setTeamMembers([...teamMembers, { name: "", email: "", role: "" }]);
    }
  };

  const handleSkip = () => {
    setTeamMembers([]);
    handleNext();
  }

  const isAllFieldsFilled = teamMembers.every(
    (member) =>
      member.name.trim() !== "" &&
      member.email.trim() !== "" &&
      member.role &&
      validateEmail(member.email)
  );

  useEffect(() => {
    setIsNextDisabled(!isAllFieldsFilled);
  }, [teamMembers]);

  useEffect(() => {
    if (teamMembers.length == 0) setTeamMembers([{ email: "", name: "", phone_number: "" }]);
  }, [])

  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <div className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 5
        </div>
      )}
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Build your team
      </div>

      <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
        We will send your team members invites through email.
      </p>

      {teamMembers.map((member, index) => (
        <div key={index} className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 w-full">
            <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Employee’s Name
            </p>
            <TextField
              placeholder="Jane Doe"
              variant="outlined"
              value={member.name}
              onChange={(e) => handleInputChange(index, "name", e.target.value)}
            />
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full">
              <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Employee’s Email
              </p>
              <TextField
                placeholder="example@gmail.com"
                variant="outlined"
                value={member.email}
                onChange={(e) =>
                  handleInputChange(index, "email", e.target.value)
                }
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Employee’s role
              </p>
              <Selector
                name="state"
                label="Please select"
                size="large"
                options={EMPLOYEE_ROLES_OPTIONS}
                value={EMPLOYEE_ROLES_OPTIONS.find(
                  (role) => role?.value === member?.role
                )}
                onChange={(_, value) =>
                  handleInputChange(index, "role", value?.value)
                }
              />
            </div>
          </div>
        </div>
      ))}

      <Button
        variant="textPrimary"
        startIcon={
          <PlusCircle
            fill={
              !isAllFieldsFilled || teamMembers.length == 4 ? "gray" : "green"
            }
          />
        }
        onClick={handleMore}
        disabled={!isAllFieldsFilled || teamMembers.length == 4}
        sx={{ width: "fit-content", marginLeft: "auto" }}
      >
        Add More
      </Button>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 ml-auto w-[90%]">
        <div className="flex items-center justify-end">
          <div className="underline cursor-pointer" onClick={handleSkip}>
            Skip for now
          </div>
        </div>
        <Button
          variant="outlinedSecondary"
          className="w-full"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          disabled={isNextDisabled}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SetupTeam;
