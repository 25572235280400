import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import { TextField, Checkbox } from "@mui/material";
import InputMask from "react-input-mask";
import Selector from "../Form/Field/AutoComplete";
import { COUNTRIES, US_STATES } from "../../constants";
import { validateEmail, validatePhoneNumber } from "../../utils";
import { selectCurrentUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";

const SetupStore = ({ isSameInfo, setIsSameInfo, organizationData, storeData, setStoreData, handleBack, handleNext, activeStep }) => {
  const [submitEnable, setSubmitEnable] = useState(false);
  const [storeLocalData, setStoreLocalData] = useState(storeData);

  const hasMounted = useRef(false);
  const userData = useSelector(selectCurrentUser);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setStoreLocalData({...storeData, [name]: value});
  };

  const handleSelectorChange = (field) => (_, value) => {
    handleChange({ target: { name: field, value: value?.value } });
  };

  useEffect(() => {
    const isPhoneNumberValid = validatePhoneNumber(storeData.phone_number);
    const isEmailValid = validateEmail(storeData.email);

    const addressFields = ["line", "city", "zipcode", "state", "country"];
    const areAllAddressFieldsFilled = addressFields.every(
      (field) => storeData[field] !== ""
    );

    const isFormComplete =
      isEmailValid &&
      isPhoneNumberValid &&
      storeData.name !== "" &&
      areAllAddressFieldsFilled;

    setSubmitEnable(isFormComplete);
  }, [storeData]);

  useEffect(() => {
    if (userData?.organization) {
      const organization = userData?.organization
      setStoreData((prevData) => ({
        ...prevData,
        email: organization.email,
        name: organization.name,
        phone_number: organization.phone_number,
      }));
    }
  }, [userData?.organization]);

  useEffect(() => {
    if (hasMounted.current) {
      if (isSameInfo) {
        setStoreData(organizationData);
        setStoreLocalData(organizationData);
      } else {
        setStoreData(storeLocalData);
      }
    } else {
      hasMounted.current = true;
    }
  }, [isSameInfo]);

  const handleChangeBICheckbox = () => {
    setIsSameInfo(!isSameInfo);
  };

  const moveNextStep = () => {
    setStoreData(storeLocalData);
    handleNext();
  }

  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <p className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 5
        </p>
      )}
      <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Now, tell us more about your store
      </p>
      <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug">
        Please select the checkbox below If your store is not sharing same information as your business.
      </p>
      <div className="flex">
        <Checkbox
          checked={!isSameInfo}
          onChange={handleChangeBICheckbox}
          style={{ marginLeft: -10 }}
        />
        <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug mt-3">
          My store is not sharing same information as my business
        </p>
      </div>

      <hr />

      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Store name *
          </p>
          <TextField
            id="companyName"
            placeholder="Please enter"
            variant="outlined"
            name="name"
            value={storeLocalData.name}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Store address *
          </p>
          <TextField
            id="line"
            placeholder="Please enter"
            variant="outlined"
            name="line"
            value={storeLocalData.line}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Zipcode *
          </p>
          <InputMask
            mask="99999"
            value={storeLocalData.zipcode}
            onChange={handleChange}
          >
            {() => (
              <TextField
                type="tel"
                name="zipcode"
                placeholder="12345"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            City *
          </p>
          <TextField
            id="city"
            placeholder="Please enter"
            variant="outlined"
            name="city"
            value={storeLocalData.city}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            State *
          </p>
          <Selector
            name="state"
            label="Please select"
            size="large"
            options={US_STATES}
            onChange={handleSelectorChange("state")}
            value={US_STATES.find(
              (state) => state.value === storeLocalData.state
            )}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Country *
          </p>
          <Selector
            name="country"
            label="Please select"
            size="large"
            options={COUNTRIES}
            onChange={handleSelectorChange("country")}
            value={COUNTRIES.find(
              (country) => country.value === storeLocalData.country
            )}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          Store email *
        </p>
        <TextField
          id="contactEmail"
          placeholder="Please enter"
          variant="outlined"
          name="email"
          value={storeLocalData.email}
          onChange={handleChange}
        />
      </div>

      <div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Store number *
          </p>
          <InputMask
            mask="(999) 999-9999"
            value={storeLocalData.phone_number}
            onChange={handleChange}
          >
            {() => (
              <TextField
                id="phone_number"
                name="phone_number"
                type="tel"
                placeholder="(123) 456-7890"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mt-4 ml-auto w-[90%]">
        <div></div>
        <Button
          variant="outlinedSecondary"
          className="w-full"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          disabled={!submitEnable}
          onClick={moveNextStep}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SetupStore;