import React from "react";
import { Box, Typography, Divider, Grid, Paper } from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import { REACT_APP_URL } from "../../../constants/BackendRoutes";
import { DETAILS } from "../../../constants/FrontendRoutes";
import {
  caseConversion,
  convertDateFormat,
  groupServicesByType,
} from "../../../utils";

const LetterSize = ({ currentItem, data }) => {
  return (
    <Box
      sx={{
        width: 612,
        backgroundColor: "white",
      }}
    >
      <Box sx={{ width: 564 }}>
        <Box sx={{ mb: 2 }}>
          <Grid container justifyContent="space-between" alignItems="baseline">
            <Grid item>
              <Box display="flex" alignItems="end" gap={6}>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  sx={{ fontWeight: 600 }}
                >
                  Order #{data?.id}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {data?.user?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textPrimary">
                {currentItem} / {data?.order_line_items?.length} items
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
        </Box>
        <Grid container spacing={4}>
          <Grid item xs>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <div className="grid grid-cols-2">
                <div className="row-span-2">
                  <QRCode
                    value={`${REACT_APP_URL}/${DETAILS}/${
                      data?.order_line_items[currentItem - 1]?.unique_identifier
                    }`}
                    size={200}
                  />
                </div>
                <Box mb={2}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                        Due Date
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" color="textPrimary">
                        {convertDateFormat(data?.estimated_completion)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Box>
                <Box mb={2}>
                  <Typography variant="body2" color="textSecondary">
                    {data?.order_line_items[currentItem - 1]?.line_item_type ===
                    "product"
                      ? data?.order_line_items[currentItem - 1]?.details?.type
                      : data?.order_line_items[currentItem - 1]?.details
                          ?.category}
                  </Typography>
                  {data?.order_line_items[currentItem - 1]?.line_item_type ===
                  "product" ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      {data?.order_line_items[currentItem - 1]?.details?.name}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      sx={{ fontWeight: 600 }}
                    >
                      {
                        data?.order_line_items[currentItem - 1]?.details
                          ?.categoryType
                      }{" "}
                      -{" "}
                      {Object.entries(
                        groupServicesByType(
                          data?.order_line_items[currentItem - 1]?.details
                            ?.services &&
                            data?.order_line_items[currentItem - 1]?.details
                              ?.services
                        )
                      )
                        .map(([type]) => caseConversion(type))
                        .join(", ")}
                    </Typography>
                  )}
                  <Divider sx={{ my: 1 }} />
                </Box>
                {Object.entries(
                  groupServicesByType(
                    data?.order_line_items[currentItem - 1]?.details?.services
                  )
                ).map(([type, names]) => (
                  <Box className="col-span-2" mb={2}>
                    <Typography variant="body2" color="textSecondary">
                      {caseConversion(type)}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      sx={{ fontWeight: 600 }}
                    >
                      {names.length > 0 ? (
                        names.map((name, index) => name).join(", ")
                      ) : (
                        <p className="font-questrial text-base">----</p>
                      )}
                    </Typography>

                    <Divider sx={{ my: 1 }} />
                  </Box>
                ))}
                {![0, null, undefined].includes(
                  data?.order_line_items[currentItem - 1]?.details?.options
                    ?.length
                ) && (
                  <Box className="col-span-2" mb={2}>
                    <Typography variant="body2" color="textSecondary">
                      Option(s)
                    </Typography>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      sx={{ fontWeight: 600 }}
                    >
                      {data?.order_line_items[currentItem - 1]?.details?.options
                        ?.map((item, index) => item.name)
                        .join(", ")}
                    </Typography>
                    {data?.order_line_items[currentItem - 1]?.details
                      ?.description !== "" && <Divider sx={{ my: 1 }} />}
                  </Box>
                )}
                {data?.order_line_items[currentItem - 1]?.details
                  ?.description !== "" && (
                  <Box className="col-span-2">
                    <Typography variant="body2" color="textSecondary">
                      Description
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {
                        data?.order_line_items[currentItem - 1]?.details
                          ?.description
                      }
                    </Typography>
                  </Box>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LetterSize;
