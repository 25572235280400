export const COBLRSHOP_PRODUCT_FIELDS = [
  "Status",
  "Date of laste order",
  "Item name",
  "Brand",
  "Color",
  "Description",
  "Cost per item",
  "Sales price",
  "Stock",
  "Inventory",
  "Reorder level",
];

export const CUSTOMER_FIELDS = {
  NAME: "Full name",
  EMAIL: "Email",
  PHONE_NUMBER: "Phone number",
  SIGNUP_DATE: "Last signup",
  SHIPPING_ADDRESS: "Shipping address",
  BILLING_ADDRESS: "Billing address",
};

export const MONTHS_IN_YEAR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SERVICE_TYPE = Object.freeze({
  upper_repair: "Upper Repair",
  sole_repair: "Sole Repair",
  other_shoe_repairs: "Other Shoe Repairs",
  more_leather_repairs: "More Leather Repairs",
});

export const CATEGORY = Object.freeze({
  women_shoes: "Women's Shoes",
  mens_shoes: "Men's Shoes",
  handbags: "Handbags",
  leather_care: "Leather Care",
  accessories: "Accessories",
  other: "Other",
});

export const COMMON_STATUS = Object.freeze({
  active: "Active",
  in_active: "Inactive",
});

export const USER_ROLES = Object.freeze({
  CUSTOMER: "customer",
  ADMIN: "admin",
  MANAGER: "manager",
  REPAIR_TEAM: "repair_team",
});

export const USER_ROLES_MAPPER = Object.freeze({
  [USER_ROLES.CUSTOMER]: "Customer",
  [USER_ROLES.ADMIN]: "Admin",
  [USER_ROLES.MANAGER]: "Manager",
  [USER_ROLES.REPAIR_TEAM]: "Repair Team",
});

export const USER_ROLES_ENUM = Object.freeze({
  [USER_ROLES.CUSTOMER]: 0,
  [USER_ROLES.ADMIN]: 1,
  [USER_ROLES.MANAGER]: 2,
  [USER_ROLES.REPAIR_TEAM]: 3,
});

export const ADMIN_ROLE = [USER_ROLES.ADMIN];
export const BASIC_AUTH_ROLES = [...ADMIN_ROLE, USER_ROLES.MANAGER];
export const All_AUTH_ROLES = [...BASIC_AUTH_ROLES, USER_ROLES.REPAIR_TEAM];

export const ADDRESS_TYPE_ENUM = Object.freeze({
  shop: 0,
  shipping: 1,
  customer: 2,
  billing: 3,
});

export const USER_STATUS_MAPPER = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ONBOARDING :'onboarding'
});

export const USER_STATUS_ENUM = Object.freeze({
  [USER_STATUS_MAPPER.ACTIVE]: 0,
  [USER_STATUS_MAPPER.INACTIVE]: 1,
  [USER_STATUS_MAPPER.ONBOARDING]: 2,
});

export const ORDER_TYPE_ENUM = Object.freeze({
  in_store: 0,
  online: 1,
});

export const ORDER_STATUS_ENUM = Object.freeze({
  new_order: 0,
  shipping: 1,
  in_progress: 2,
  delayed: 3,
  repaired: 4,
  delivered: 5,
  quote: 6,
});

export const SUBSCRIPTION_STATUS = Object.freeze({
  TRIAL: "trialing",
});

export const ORDER_STATUS = Object.freeze({
  new_order: "New",
  shipping: "Shipping",
  in_progress: "In progress",
  delayed: "Delayed",
  repaired: "Repaired",
  delivered: "Delivered",
  quote: "Quote",
});

export const PAYMENT_STATUS_ENUM = Object.freeze({
  in_complete: 0,
  captured: 1,
  succeeded: 2,
  failed: 3,
  canceled: 4,
  pending_refund: 5,
  refunded: 6,
  pending: 7,
});

export const PRODUCT_STATUS = Object.freeze({
  in_active: "Inactive",
  active: "Active",
  low_inventory: "Low inventory",
  in_stock: "In Stock",
  out_of_stock: "Out of stock",
});

export const DISCOUNT_TYPES = {
  percentage: "percentage",
  amount: "amount",
};

export const CART_TYPES = {
  checkout: "checkout",
  quote: "quote",
};

export const PAYMENT_METHODS = {
  CASH: "cash",
  DEBIT_OR_CREDIT: "debitOrCredit",
  CARD_SCAN: "card_present",
};

export const POS_NEW_ORDER_STEPS = {
  ADD_ITEM: 0,
  ADD_DISCOUNT: 1,
  CREATE_NEW_DISCOUNT: 2,
  PAYMENT_METHOD: 3,
  SELECT_CUSTOMER: 4,
  CREATE_CUSTOMER: 5,
  CALCULATE_PAYMENT: 6,
  ORDER_SUCCESS: 7,
  CARD_PAYMENT: 8,
  SCAN_CARD: 9,
  ATTACH_CUSTOMER: 10,
  ORDER_CART: 11,
};

export const EMPLOYEE_ROLES_OPTIONS = [
  { title: "Manager", value: "manager" },
  { title: "Repair Team", value: "repair_team" },
];

export const COUNTRIES = [{ title: "United States", value: "US" }];

export const COMPANY_SIZE = [
  { title: "1-5 employees", value: "1-5" },
  { title: "10-50 employees", value: "10-50" },
  { title: "50-100 employees", value: "50-100" },
  { title: "100-200 employees", value: "100-200" },
 ];

export const US_STATES = [
  { title: "Alabama", value: "AL" },
  { title: "Alaska", value: "AK" },
  { title: "Arizona", value: "AZ" },
  { title: "Arkansas", value: "AR" },
  { title: "California", value: "CA" },
  { title: "Colorado", value: "CO" },
  { title: "Connecticut", value: "CT" },
  { title: "Delaware", value: "DE" },
  { title: "District of Columbia", value: "DC" },
  { title: "Florida", value: "FL" },
  { title: "Georgia", value: "GA" },
  { title: "Hawaii", value: "HI" },
  { title: "Idaho", value: "ID" },
  { title: "Illinois", value: "IL" },
  { title: "Indiana", value: "IN" },
  { title: "Iowa", value: "IA" },
  { title: "Kansas", value: "KS" },
  { title: "Kentucky", value: "KY" },
  { title: "Louisiana", value: "LA" },
  { title: "Maine", value: "ME" },
  { title: "Maryland", value: "MD" },
  { title: "Massachusetts", value: "MA" },
  { title: "Michigan", value: "MI" },
  { title: "Minnesota", value: "MN" },
  { title: "Mississippi", value: "MS" },
  { title: "Missouri", value: "MO" },
  { title: "Montana", value: "MT" },
  { title: "Nebraska", value: "NE" },
  { title: "Nevada", value: "NV" },
  { title: "New Hampshire", value: "NH" },
  { title: "New Jersey", value: "NJ" },
  { title: "New Mexico", value: "NM" },
  { title: "New York", value: "NY" },
  { title: "North Carolina", value: "NC" },
  { title: "North Dakota", value: "ND" },
  { title: "Ohio", value: "OH" },
  { title: "Oklahoma", value: "OK" },
  { title: "Oregon", value: "OR" },
  { title: "Pennsylvania", value: "PA" },
  { title: "Rhode Island", value: "RI" },
  { title: "South Carolina", value: "SC" },
  { title: "South Dakota", value: "SD" },
  { title: "Tennessee", value: "TN" },
  { title: "Texas", value: "TX" },
  { title: "Utah", value: "UT" },
  { title: "Vermont", value: "VT" },
  { title: "Virginia", value: "VA" },
  { title: "Washington", value: "WA" },
  { title: "West Virginia", value: "WV" },
  { title: "Wisconsin", value: "WI" },
  { title: "Wyoming", value: "WY" },
];

export const POS_TAB_LIST = [
  { label: "Services", value: "services" },
  { label: "Products", value: "products" },
  { label: "Options", value: "options" },
  { label: "Category Types", value: "category-types" },
];

export const ORDER_HISTORY_TAB_LIST = [
  { label: "All orders", value: "all-orders" },
  { label: "Quotes", value: "quotes" },
  { label: "Active orders", value: "active-orders" },
  { label: "Past orders", value: "past-orders" },
];

export const STRIPE_CONNECT_ACC_LINK_TYPES = {
  ONBOARDING: "account_onboarding",
};

export const TEAM_MEMBER_ROLES = [
  { title: "Admin", value: "admin" },
  { title: "Manager", value: "manager" },
  { title: "Repair Team", value: "repair_team" },
]
