import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginPNG from "../images/login.png";
import Logo from "../images/Logo.svg";
import { useDispatch } from "react-redux";
import { updatePassword } from "../redux/authActions";
import { addToast } from "../redux/toastSlice";
import { ROOT, SIGNUP } from "../constants/FrontendRoutes";
import { Button } from "@mui/material";
import { EyeIcon } from "../common/icons";
import { updateRequest } from "../api";
import { USERS_PASSWORD } from "../constants/BackendRoutes";

const FORM_FIELDS = {
  newPassword: "",
  confirmPassword: "",
};

const NewPasswordPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("reset_password_token");

  const [formData, setFormData] = useState(FORM_FIELDS);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = formData;

    if (newPassword !== confirmPassword) {
      dispatch(addToast("Passwords do not match."));
      return;
    }

    try {
      const response = await updateRequest(USERS_PASSWORD, {
        user: {
          reset_password_token: token,
          password: newPassword,
          password_confirmation: confirmPassword,
        },
      });
      if (response && response.status === 200) {
        dispatch(addToast(response?.data?.message || 'Password updated'))
        navigate(ROOT);
      }
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  useEffect(() => {
    const isFormComplete = Object.values(formData).every(
      (field) => field.trim() !== ""
    );
    setIsFormValid(isFormComplete);
  }, [formData]);

  return (
    <div className="bg-gray-100 flex h-screen">
      <div className="w-full lg:w-2/5 my-auto">
        <div className="flex flex-col gap-7 w-[80%] md:w-[45%] lg:w-[70%] mx-auto">
          <img
            alt="logo"
            src={Logo}
            className="w-11 h-auto absolute top-[10%]"
          />
          <div>
            <h2 className="text-[#272523] mb-[8px] text-[24px] font-bold font-['Montserrat'] leading-[33.60px]">
              Reset your password
            </h2>
            <span className="text-[#272523] text-[16px] font-normal font-['Questrial'] leading-snug flex items-center justify-between">
              Please enter your new password:
            </span>
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <label className="text-[#272523] text-[16px] font-normal font-['Questrial'] leading-snug">
                  New Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                    placeholder="Enter password here"
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    <EyeIcon />
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-[#272523] text-[16px] font-normal font-['Questrial'] leading-snug">
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    placeholder="Enter password here"
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    <EyeIcon />
                  </button>
                </div>
              </div>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isFormValid}
            >
              Reset Password
            </Button>
          </form>
          <div className="border-[0.5px] border-b-neutral-200"></div>
        </div>
        <span className="flex justify-center my-8 items-center gap-2">
          <div className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
            New to Coblr?
          </div>
          <Link
            to={SIGNUP}
            className="text-right text-green-700 text-xs font-normal font-['SF Pro Display'] leading-tight tracking-tight"
          >
            Sign up now
          </Link>
        </span>
      </div>
      <div className="hidden lg:block w-3/5">
        <img
          src={LoginPNG}
          alt="Coblar"
          className="w-full h-screen object-cover"
        />
      </div>
    </div>
  );
};

export default NewPasswordPage;
