import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import MessageType from '../components/Message/Type';
import MessageList from '../components/Message/List';
import MessageDetail from '../components/Message/Detail';
import { Search, FilterList } from '@mui/icons-material';
import { EditIcon, FlagIcon, MoreIcon } from '../common/icons';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import EntityPage from '../components/EntityPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MESSAGES } from '../constants/FrontendRoutes';

const Messages = () => {
  const { id } = useParams();
  const [customer, setCustomer] = useState({});
  const [selectedListItem, setSelectedListItem] = useState('Inbox');

  return (
    <div className="bg-[#F8F8F8] overflow-hidden">
      <EntityPage title="Messages" classNameLayout="hidden md:flex p-0 md:p-5" childrenLayout="p-0">
        <div className={`grid sm:border-t border-[#F3F0E8] ${id && 'grid-cols-5'} grid-rows-auto`}>
          <div
            className={`items-center flex flex-row justify-between px-6 p-2 py-4 sm:p-4 col-span-5 sm:col-span-2 ${
              id && 'hidden sm:flex'
            }`}
          >
            <div className=" text-[#272523] text-lg font-semibold font-['Montserrat']">
              All Messages
            </div>
            <div className="flex gap-2 items-center" style={{ color: "#939291" }}>
            <Search/>
            <EditIcon />
            <FilterList />
          </div>
          </div>

          {id && (
            <div className="sm:flex hidden rounded-tl-lg rounded-tr-lg border-[#F3F0E8] p-4 col-span-3 bg-white">
              <div className="flex flex-col w-full">
                <div className="flex justify-between">
                  <span className="text-[#272523] text-lg font-semibold font-['Montserrat']">
                    {customer?.name}
                  </span>
                  {/* <div className="flex gap-2 items-center">
                <FlagIcon className="w-7 h-7" />
                <MoreIcon className="w-7 h-7" />
              </div> */}
                </div>
              </div>
            </div>
          )}

          <Box
            className={`flex flex-col sm:flex-row justify-between md:border-t border-[#F3F0E8] sm:ps-6 col-span-5 sm:col-span-2 bg-white md:bg-transparent ${
              id && 'hidden sm:flex'
            }`}
          >
            <MessageType selectedListItem={selectedListItem} setSelectedListItem={setSelectedListItem} />
            <Box
              sx={{
                height: `calc(100vh - 170px)`,
              }}
              className="border-l border-r border-[#F3F0E8] overflow-auto flex flex-col my-2 sm:my-0 gap-2 sm:w-3/5 "
            >
              <MessageList />
            </Box>
          </Box>

          {id && (
            <React.Fragment>
              <Box
                sx={{
                  height: `calc(100vh - 170px)`,
                }}
                className="hidden sm:block overflow-auto border-t border-[#F3F0E8] p-4 col-span-5 sm:col-span-3 bg-white"
              >
                <MessageDetail customer={customer} setCustomer={setCustomer} />
              </Box>
              <div className="flex flex-col sm:hidden col-span-5">
                <div className="bg-transparent flex flex-row py-4">
                  <div className="basis-[10%] flex justify-center items-center">
                    <Link to={MESSAGES}>
                      <ChevronLeftIcon className="text-gray-600" />
                    </Link>
                  </div>
                  <div className="basis-[70%] flex flex-col">
                    <div className="text-gray-500 text-xs">Order# {id}</div>
                    <div className="text-base">{customer?.name}</div>
                  </div>
                  <div className="basis-[20%] flex justify-center gap-2 items-center">
                    <FlagOutlinedIcon className="text-gray-500" />
                    <MoreVertIcon className="text-gray-500" />
                  </div>
                </div>

                <Box
                  sx={{
                    height: `calc(100vh - 145px)`,
                  }}
                  className="border-t border-[#F3F0E8] col-span-5 bg-white"
                >
                  <MessageDetail customer={customer} setCustomer={setCustomer} />
                </Box>
              </div>
            </React.Fragment>
          )}
        </div>
      </EntityPage>
    </div>
  );
};

export default Messages;
