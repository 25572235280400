import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import MediaQuery from "react-responsive";
import { validateEmail } from "../../../utils";
import { postRequest } from "../../../api";
import {
  POS_NEW_ORDER_STEPS,
} from "../../../constants";
import { selectCurrentUser } from "../../../redux/authSlice";
import { addToast } from "../../../redux/toastSlice";
import { LeftArrowIcon } from "../../../common/icons";
import CartBadge from "./CartBadge";

const CreateCustomer = ({ cart, setCart, currentStep, setCurrentStep }) => {
  const [customerDetail, setCustomerDetail] = useState({
    name: "",
    email: cart.lookupEmail,
    phone: cart.lookupPhone,
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
  };

  useEffect(() => {
    const isFormValid =
      customerDetail.name &&
      customerDetail.email &&
      validateEmail(customerDetail.email);

    setIsFormValid(isFormValid);
  }, [customerDetail]);

  const handleSubmit = async () => {
    try {
      const response = await postRequest(`stores/${userData.stores[0].id}/users`, {
        users: [
          {
            name: customerDetail.name,
            email: customerDetail.email,
            phone_number: customerDetail.phone,
          },
        ],
      });

      if (response?.status === 201) {
        setCart((prev) => ({
          ...prev,
          new_customer: true,
          customer: response.data?.users?.[0],
          lookupPhone: "",
          lookupEmail: "",
        }));
        dispatch(addToast(response.data?.message));
        setCurrentStep(POS_NEW_ORDER_STEPS.ATTACH_CUSTOMER);
      }
    } catch (error) {
      dispatch(addToast(error.message || "Error."));
    }
  };

  return (
    <Box className="relative flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <MediaQuery minWidth={1024}>
        <Box
          className="absolute flex items-center gap-1 top-[2.5%] left-[2.5%] cursor-pointer"
          onClick={() => {
            setCart((prev) => ({ ...prev, lookupPhone: "", lookupEmail: "" }));
            setCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER);
          }}
        >
          <LeftArrowIcon width={18} height={19} />
          <span>Back</span>
        </Box>
      </MediaQuery>
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[50%] sm:m-auto gap-4">
        <div className="flex flex-col gap-3">
          <CartBadge
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <div className="flex flex-col gap-2">
            <p className="text-lg md:text-2xl font-semibold md:font-bold font-['Montserrat'] leading-[25.20px]">
              Oops, we couldn’t find this customer
            </p>
            <p className="text-base md:text-lg font-normal md:font-semibold font-['Questrial'] leading-snug">
              There’s no existing customer with this number, enter the info to
              sign-up:
            </p>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <Typography variant="body" sx={{ mt: 2 }}>
                Name
              </Typography>
              <TextField
                type="text"
                name="name"
                placeholder="Jane Doe"
                value={customerDetail.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex flex-col gap-1">
              <Typography variant="body">Phone Number</Typography>
              <InputMask
                mask="(999) 999-9999"
                value={customerDetail.phone}
                onChange={handleInputChange}
              >
                {() => (
                  <TextField
                    type="tel"
                    name="phone"
                    placeholder="(123) 456-7890"
                  />
                )}
              </InputMask>
            </div>

            <div className="flex flex-col gap-1">
              <Typography variant="body">Email address</Typography>
              <TextField
                type="email"
                name="email"
                placeholder="Example@gmail.com"
                value={customerDetail.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <Box className="flex flex-col justify-between w-full gap-2 mt-4">
          <Button
            variant="containedPrimary"
            className="flex-1 mt-8"
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            Sign up
          </Button>
          <Button
            variant="outlinedSecondary"
            className="flex-1"
            onClick={() => {
              setCart((prev) => ({ ...prev, lookupPhone: "", lookupEmail: "" }));
              setCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateCustomer;
