import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { retrieveTokenFromLocalStorage, retrieveUserIdFromLocalStorage, destroyDataFromLocalStorage } from "../utils/localStorage";
import { fetchUserData } from '../redux/authActions';
import { selectCurrentUser } from '../redux/authSlice';
import { DASHBOARD, LOGIN, ONBOARDING, SETTINGS } from '../constants/FrontendRoutes';
import { USER_VALIDATE_TOKEN } from "../constants/BackendRoutes";
import { getRequest } from "../api";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isTokenAvailable = retrieveTokenFromLocalStorage();
  const userId = retrieveUserIdFromLocalStorage();

  const isLocalStorageAvailable = isTokenAvailable && userId;

  const userData = useSelector(selectCurrentUser);
  const location = useLocation();
  const dispatch = useDispatch();

  const [isAuthorized, setIsAuthorized] = useState(true);

  const validateUser = async () => {
    const response = await getRequest(USER_VALIDATE_TOKEN);
    if (!response?.valid) {
      destroyDataFromLocalStorage();
      window.location.href = LOGIN;
    }
  }

  useEffect(() => {
    validateUser();
    if (userData) {
      setIsAuthorized(rest?.roles?.includes(userData?.stores_users?.[0]?.role));
    }
  }, [userData, location]);

  useEffect(() => {
    if (isLocalStorageAvailable && !userData) {
      dispatch(fetchUserData(userId));
    }

    if (!isLocalStorageAvailable) {
      setIsAuthorized(false);
    }
  }, [dispatch, isTokenAvailable]);

  if (!isLocalStorageAvailable) {
    destroyDataFromLocalStorage();
    return <Navigate to={LOGIN} />;
  }

  // if (!isAuthorized) {
  //   return <Navigate to={SETTINGS} />;
  // }

  if (
    userData && userData?.status === "onboarding" &&
    location.pathname !== ONBOARDING
  ) {
    return <Navigate to={ONBOARDING} />;
  } else if (
    userData &&
    userData?.status !== "onboarding" &&
    location.pathname === ONBOARDING
  ) {
    return <Navigate to={DASHBOARD} />;
  } else if (userData) {
    return <Component {...rest} />;
  }
};

export default ProtectedRoute;
