import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Introduction from "../components/Onboarding/Introduction";
import SetupCompany from "../components/Onboarding/SetupCompany";
import SetupStore from "../components/Onboarding/SetupStore";
import SetupTeam from "../components/Onboarding/SetupTeam";
import SetupCustomer from "../components/Onboarding/SetupCustomer";
import Success from "../components/Onboarding/Success";
import { processCSVData } from "../utils/customerList";
import { useDispatch } from "react-redux";
import { postRequest } from "../api";
import { setLoading } from "../redux/loadingSlice";
import { ADDRESS_TYPE_ENUM } from "../constants";
import { addToast } from "../redux/toastSlice";
import { fetchUserData } from "../redux/authActions";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/authSlice";
import SetupShipStation from "../components/Onboarding/SetupShipStation";

export const TEAM_MEMBER_FIELDS = {
  name: "",
  email: "",
  role: "",
};

export const STORE_FIELDS = {
  email: "",
  name: "",
  phone_number: "",
  line: "",
  zipcode: "",
  state: "",
  city: "",
  country: "",
};

const ORGANIZATION_FIELDS = {
  email: "",
  name: "",
  phone_number: "",
  line: "",
  zipcode: "",
  state: "",
  city: "",
  country: "",
  employee_count: "",
  shipstation_key: "",
  shipstation_secret: "",
};

const Onboarding = () => {
  const [maxWidth, setMaxWidth] = useState("sm");
  const [activeStep, setActiveStep] = useState(0);

  const userData = useSelector(selectCurrentUser);

  const [organizationData, setOrganizationData] = useState(() => {
    const { organization } = userData || {};

    return organization
      ? {
          ...ORGANIZATION_FIELDS,
          ...organization,
          ...organization.addresses?.[0],
        }
      : ORGANIZATION_FIELDS;
  });
  const [storeData, setStoreData] = useState(STORE_FIELDS);

  const [teamMembers, setTeamMembers] = useState([TEAM_MEMBER_FIELDS]);
  const [file, setFile] = useState(null);

  const [isSameInfo, setIsSameInfo] = useState(false);

  const dispatch = useDispatch();

  const handleNext = () => {
    setMaxWidth("sm");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setMaxWidth("sm");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const readCustomerCSV = (customerCSV) => {
    return new Promise((resolve, reject) => {
      if (customerCSV) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const csvData = e.target.result;
          resolve(processCSVData(csvData));
        };

        reader.onerror = () => {
          reject(reader.error);
        };

        reader.readAsText(customerCSV);
      } else {
        resolve([]);
      }
    });
  };

  const uploadDetails = async () => {
    dispatch(setLoading(true));
    try {
      const customers = await readCustomerCSV(file);
      const organization = {
        name: organizationData.name,
        email: organizationData.email,
        phone_number: organizationData.phone_number,
        employee_count: organizationData.employee_count,
        cust_id: userData?.organization?.cust_id,
        shipstation_token: btoa(
          `${organizationData?.shipstation_key}:${organizationData?.shipstation_secret}`
        ),
        ...(organizationData.line &&
          organizationData.zipcode &&
          organizationData.city &&
          organizationData.state &&
          organizationData.country && {
            addresses_attributes: [
              {
                id: userData?.organization?.addresses?.[0]?.id,
                line: organizationData.line,
                city: organizationData.city,
                zipcode: organizationData.zipcode,
                state: organizationData.state,
                country: organizationData.country,
                address_type: ADDRESS_TYPE_ENUM.billing,
              },
            ],
          }),
      };
      const store = {
        name: storeData.name,
        email: storeData.email,
        phone_number: storeData.phone_number,
        ...(storeData.line &&
          storeData.zipcode &&
          storeData.city &&
          storeData.state &&
          storeData.country && {
            addresses_attributes: [
              {
                line: storeData.line,
                city: storeData.city,
                zipcode: storeData.zipcode,
                state: storeData.state,
                country: storeData.country,
                address_type: ADDRESS_TYPE_ENUM.shop,
              },
              {
                line: storeData.line,
                city: storeData.city,
                zipcode: storeData.zipcode,
                state: storeData.state,
                country: storeData.country,
                address_type: ADDRESS_TYPE_ENUM.shipping,
              },
            ],
          }),
      };

      const response = await postRequest(
        `stores`,
        {
          organization,
          store,
          team_members: teamMembers,
          customers,
        },
        { "Content-Type": "multipart/form-data" }
      );

      if (response && response.status == 201) {
        handleNext();
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (userData) {
      dispatch(fetchUserData(userData?.id));
    }
  }, []);

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <Introduction handleNext={handleNext} activeStep={activeStep} />;
      case 1:
        return (
          <SetupCompany
            organizationData={organizationData}
            setOrganizationData={setOrganizationData}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <SetupStore
            organizationData={organizationData}
            storeData={storeData}
            setStoreData={setStoreData}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            isSameInfo={isSameInfo}
            setIsSameInfo={setIsSameInfo}
          />
        );
      case 3:
        return (
          <SetupTeam
            teamMembers={teamMembers}
            setTeamMembers={setTeamMembers}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
        );
      case 4:
        return (
          <SetupCustomer
            file={file}
            setFile={setFile}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            setMaxWidth={setMaxWidth}
          />
        );
      case 5:
        return (
          <SetupShipStation
            organizationData={organizationData}
            setOrganizationData={setOrganizationData}
            handleBack={handleBack}
            handleNext={uploadDetails}
            activeStep={activeStep}
            setMaxWidth={setMaxWidth}
          />
        );
      case 6:
        return (
          <Success
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#272523 ",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={true}
        sx={{ borderRadius: "50px" }}
      >
        {renderStepContent()}
      </Dialog>
    </Box>
  );
};

export default Onboarding;
