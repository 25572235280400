import React from "react";
import {
  Button,
  Box,
} from "@mui/material";
import MediaQuery from "react-responsive";
import {
  POS_NEW_ORDER_STEPS,
} from "../../../constants";
import { LeftArrowIcon } from "../../../common/icons";
import CartBadge from "./CartBadge";

const AttachCustomer = ({ cart, setCart, currentStep, setCurrentStep, isDesktopScreen }) => {
  const handleBack = async () => {
    setCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER);
    setCart((prev) => ({
      ...prev,
      type: null,
    }));
  };

  const handleAttachCustomer = () => {
    setCart((prev) => ({
      ...prev,
      attached_customer: prev.customer,
      customer: null
    }))

    isDesktopScreen ? setCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM) : setCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART);
  }

  return (
    <Box className="relative flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <MediaQuery minWidth={1024}>
        <Box
          className="absolute flex items-center gap-1 top-[2.5%] left-[2.5%] cursor-pointer"
          onClick={handleBack}
        >
          <LeftArrowIcon width={18} height={19} />
          <span>Back</span>
        </Box>
      </MediaQuery>
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[50%] sm:m-auto">
        <div>
          <CartBadge
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <p className="text-lg sm:text-3xl font-semibold sm:font-bold font-['Montserrat'] leading-[25.20px]">
            {cart.new_customer
              ? "Attaching new customer to the order"
              : "We found you!"}
          </p>
          <div className="flex flex-col gap-1 my-6">
            <span className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
              Customer #{cart?.customer?.id}
            </span>
            <span className="text-lg sm:text-3xl font-semibold sm:font-bold font-['Montserrat'] leading-[25.20px]">
              {cart?.customer?.name}
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-between my-8">
            <div className="flex flex-col gap-1">
              <span className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
                Phone number
              </span>
              <span>{cart?.customer?.phone_number || "-"}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
                Email address:
              </span>
              <span>{cart?.customer?.email || "-"}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Button variant="containedPrimary" onClick={handleAttachCustomer}>
            Attach to order
          </Button>
          {/* <MediaQuery maxWidth={1023}> */}
            <Button variant="outlinedSecondary" onClick={handleBack}>
              Try another method
            </Button>
          {/* </MediaQuery> */}
        </div>
      </Box>
    </Box>
  );
};

export default AttachCustomer;
