import React from "react";
import { Card, CardContent, Typography, Box, Checkbox } from "@mui/material";
import { styled } from "@mui/system";
import theme from "../../../theme/theme";

const StyledCard = styled(Card)(({ theme, isSelected }) => ({
  borderRadius: "8px",
  border: `1px solid ${isSelected ? theme.palette.border.primary : theme.palette.border.secondary}`,
  backgroundColor: isSelected ? theme.palette.background.primary : "transparent",
  padding: theme.spacing(2),
  "&:hover": {
    cursor: "pointer",
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  boxShadow: "none",
  height: "85px",
}));

const PriceCard = ({ data, isAdditional, isSelected, onClick, ...rest }) => {
  return (
    <StyledCard isSelected={isSelected} onClick={onClick} {...rest}>
      <Box className="flex items-center gap-4 w-full">
        <Checkbox
          checked={isSelected}
          sx={{
            p: "0px !important",
            color: isSelected
              ? theme.palette.primary.main
              : theme.palette.grey[300],
            "&.Mui-checked": {
              color: theme.palette.primary.main,
            },
          }}
        />

        <Box className="flex flex-col sm:flex-row sm:justify-between sm:items-center w-full">
          <Typography
            component="p"
            variant="body"
            sx={{
              fontSize: { xs: "16px", sm: "18px" },
              textAlign: { xs: "left" },
            }}
          >
            {data.name}{" "}
          </Typography>
          <div className="flex gap-2 justify-between sm:flex-col border-t border-[#DFDFDF] sm:border-t-0 pt-1">
            <Typography
              component="p"
              variant="body"
              color="textSecondary"
              sx={{
                fontSize: { xs: "12px", sm: "1rem" },
                textAlign: { xs: "center", sm: "left" },
              }}
              textAlign={isAdditional ? "right" : "left"}
            >
              Price
            </Typography>
            <Typography
              component="p"
              variant="subTitle"
              width="max-content"
              sx={{
                fontSize: { xs: "12px", sm: "1rem" },
                textAlign: { xs: "center", sm: "left" },
              }}
              textAlign="right"
            >
              ${parseFloat(data?.price).toFixed(2)}
            </Typography>
          </div>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default PriceCard;