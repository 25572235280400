import React, { useRef, useState, useEffect } from "react";
import { QRCode } from "react-qrcode-logo";
import GreenButton from "../Button/GreenButton";
import { useActionCable } from "../../customHooks/useActionCable";
import { CrossIcon, GreenTick } from "../../common/icons";
import { REACT_APP_URL } from "../../constants/BackendRoutes";

const QRCodeModal = ({ token, handleClose }) => {
  const [currentStep, setCurrrentStep] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const rootRef = useRef(null);

  const [code] = useActionCable("CodesChannel");

  useEffect(() => {
    if (code?.step) {
      setCurrrentStep(code?.step);
      setTimeout(() => {
        setShowNotification(true);
      }, 500);
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [code]);

  return (
    <div
      ref={rootRef}
      className={`absolute top-1/2 left-1/2 w-[530px] ${
        currentStep === 2 ? "h-36" : "h-60"
      } transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-8`}
    >
      <div className="flex flex-col relative">
        {showNotification && (
          <div className="absolute left-[40%] transition-opacity duration-1000">
            <div className="p-2 rounded-2xl shadow-md">
              <div className="flex flex-row gap-3 items-center">
                <GreenTick />
                <p className="text-sm text-[#4c8c4a]">
                  Your devices is now connected!
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-row justify-between w-full items-center">
          <p className="text-gray">Step {currentStep} of 3</p>
          <div className=" cursor-pointer">
            <CrossIcon onClick={handleClose} />
          </div>
        </div>
        <p className="text-2xl font-bold">Scan QR Code</p>
        {currentStep === 1 ? (
          <div className="flex flex-row gap-10">
            <div className="w-1/5">
              <QRCode value={`${REACT_APP_URL}/scanner/${token}`} size={100} />
            </div>
            <div className="w-full">
              <p>
                Please use your mobile device to scan the QR code, so we can
                setup the link between your devices.
              </p>
              <p>
                This might take some time depending on your internet status.
              </p>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div>
            <p>
              Please follow the instruction on your phone to scan the barcode.
            </p>
          </div>
        ) : (
          <div>
            <p>
              Barcode scanned! Please sit tight while we reassign your order’s
              status, this might take a few seconds...
            </p>
            <div className="my-2 w-full">
              <div className="flex flex-row justify-end" onClick={handleClose}>
                <GreenButton text="Got it" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QRCodeModal;
