import React, { useEffect, useState, useRef } from "react";
import EntityPage from "../components/EntityPage";
import MyAccount from "../components/Setting/MyAccount";
import BusinessDetail from "../components/Setting/BusinessDetail";
import Billing from "../components/Setting/Billing";
import InfoIcon from "@mui/icons-material/Info";
import { Alert, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";
import { updateRequest } from "../api";
import { addDaysToSpecificDate, daysDifferenceFromToday, hasNonEmptyValues } from "../utils";
import FeeManagement from "../components/Setting/FeeManagement";
import { setLoading } from "../redux/loadingSlice";
import { fetchUserData } from "../redux/authActions";
import SubscriptionFlow from "../components/Modal/SubscriptionFlow";
import SettingIntegrations from "../components/Setting/Integration";
import StoreDetail from "../components/Setting/StoreDetail";
import TeamMember from "../components/Setting/TeamMember";
import { USER_ROLES } from "../constants";
import AlertTile from "../components/Alert/AlertTile";

const default_team_member_limit = parseInt(process.env.REACT_APP_STRIPE_USERS_DEFAULT_LIMIT, 10);

const Setting = () => {
  const [activeLink, setActiveLink] = useState("my_account");
  const [openPlanModal, setOpenPlanModal] = useState(false);

  const [accountDetailChanges, setAccountDetailChanges] = useState({});
  const [businessDetailChanges, setBusinessDetailChanges] = useState({});
  const [storeDetailChanges, setStoreDetailChanges] = useState({});
  const [addOnAlert, setAddOnAlert] = useState(false);

  const accountRef = useRef(null);
  const businessDetailRef = useRef(null);
  const storeDetailRef = useRef(null);
  const billingRef = useRef(null);
  const integrationRef = useRef(null);
  const feeManagementRef = useRef(null);
  const teamMemberRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const isAdmin = userData?.stores_users?.[0]?.role === USER_ROLES.ADMIN;
  const isManager = userData?.stores_users?.[0]?.role === USER_ROLES.MANAGER;
  const isRepairTeamMember = userData?.stores_users?.[0]?.role === USER_ROLES.REPAIR_TEAM;

  const isAdminOrManager = isAdmin || isManager;
  const hasAnyRole = isAdminOrManager || isRepairTeamMember;

  const linksConfig = [
    ...(hasAnyRole ? [{
      id: "my_account",
      label: "My account",
      component: MyAccount,
      props: { userData, setAccountDetailChanges },
      ref: accountRef,
    }] : []),
    ...(isAdminOrManager ? [{
      id: "business_details",
      label: "Business details",
      component: BusinessDetail,
      props: { userData, setBusinessDetailChanges },
      ref: businessDetailRef,
    }] : []),
    ...(isAdminOrManager ? [{
      id: "store_details",
      label: "Store details",
      component: StoreDetail,
      props: { userData, setStoreDetailChanges },
      ref: storeDetailRef,
    }] : []),
    ...(isAdmin ? [{
      id: "team_members",
      label: "Team Members",
      component: TeamMember,
      props: { userData, setOpenPlanModal },
      ref: teamMemberRef,
    }] : []),
    ...(isAdminOrManager ? [{
      id: "billing",
      label: "Billing",
      component: Billing,
      props: { userData, setOpenPlanModal },
      ref: billingRef,
    }] : []),
    ...(isAdminOrManager ? [{
      id: "integrations",
      label: "Integrations",
      component: SettingIntegrations,
      props: { userData },
      ref: integrationRef,
    }] : []),
    ...(isAdminOrManager ? [{
      id: "fee_management",
      label: "Fee Management",
      component: FeeManagement,
      props: { userData, setBusinessDetailChanges },
      ref: feeManagementRef,
    }] : []),
  ];

  const isSaveDisabled = () => {
    const accountChangesExist = hasNonEmptyValues(accountDetailChanges);
    const businessChangesExist = hasNonEmptyValues(businessDetailChanges);
    const storeChangesExist = hasNonEmptyValues(storeDetailChanges);

    const passwordsMatch = accountDetailChanges.new_password === accountDetailChanges.confirm_new_password;

    if (!accountChangesExist && !businessChangesExist && !storeChangesExist) return true;

    if (
      accountDetailChanges.new_password ||
      accountDetailChanges.confirm_new_password
    ) {
      return !(passwordsMatch);
    }

    return false;
  };

  const updateSettings = async () => {
    try {
      dispatch(setLoading(true));
      const response = await updateRequest(
        `stores/${userData.stores[0].id}/settings`,
        {
          user: accountDetailChanges,
          organization: businessDetailChanges,
          store: storeDetailChanges,
        },
        { "Content-Type": "multipart/form-data" }
      );

      if (response.status == 200) {
        dispatch(addToast("Updated successfully."));
        dispatch(fetchUserData(userData?.id));
        setAccountDetailChanges({});
        setBusinessDetailChanges({});
        setStoreDetailChanges({});
      }
    } catch (error) {
      dispatch(addToast(error));
      setAccountDetailChanges({});
      setBusinessDetailChanges({});
      setStoreDetailChanges({});
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleScrollToSection = (sectionRef, linkId) => {
    setActiveLink(linkId);
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (userData) {
      dispatch(fetchUserData(userData?.id));
    }
    if (
      userData?.organization?.subscription?.add_on &&
      userData?.organization?.users_count <
        userData?.organization?.subscription?.add_on?.current_quantity +
          default_team_member_limit &&
      daysDifferenceFromToday(userData?.organization?.subscription?.current_period_end) < 7
    ) {
      setAddOnAlert(true);
    } else {
      setAddOnAlert(false);
    }

  }, []);


  return (
    <EntityPage title="Settings" className="bg-[#F8F8F8]" stickyBottomBar>
      {!userData?.organization?.subscription &&
        <AlertTile
        text="Your free-trial will end in"
        date={daysDifferenceFromToday(
          addDaysToSpecificDate(
            userData?.organization ? userData?.organization?.created_at : userData?.created_at,
            14,
          ),
        )}
        textAfterDate="days, go ahead and upgrade today!"
        buttonText="Upgrade"
        OnSubmit={setOpenPlanModal}
        isButtonVisibile={true}
        />}

      {addOnAlert &&
        <AlertTile
        text="Your Add-on subscription ends in"
        date={daysDifferenceFromToday(userData?.organization?.subscription?.current_period_end)}
        textAfterDate="days, You will be charged for extra users."
        isButtonVisibile={false}
        />
      }

      <div className="flex gap-6">
        <div className="w-[240px] h-fit p-8 bg-white rounded-lg hidden md:block">
          <div className="flex-col justify-start items-start gap-2 flex">
            {linksConfig.map((link) => (
              <a
                key={link.id}
                className={`text-base font-normal font-['Questrial'] leading-snug hover:bg-stone-100 w-full px-2 ${
                  activeLink === link.id ? "text-stone-800" : "text-stone-400"
                }`}
                href={`#${link.id}`}
                onClick={() => handleScrollToSection(link.ref, link.id)}
              >
                {link.label}
              </a>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-6 w-full h-[calc(100vh-82px-40px-80px)] overflow-scroll">
          {linksConfig.map((link) => {
            const Component = link.component;
            return (
              <div key={link.id} ref={link.ref}>
                <Component
                  {...link.props}
                />
              </div>
            );
          })}
        </div>
      </div>

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          padding: "16px 0",
          display: "flex",
          flexDirection: "row-reverse",
          gap: "28px",
        }}
        elevation={8}
      >
        <div className="flex flex-row gap-3 mx-5">
          <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            variant="containedPrimary"
            disabled={isSaveDisabled()}
            onClick={updateSettings}
          >
            Save changes
          </Button>
        </div>
      </Paper>
      <SubscriptionFlow open={openPlanModal} setOpen={setOpenPlanModal} />
    </EntityPage>
  );
};

export default Setting;
