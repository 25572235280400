import React, { useState, useEffect } from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { CartIcon, LeftArrowIcon } from "../../../common/icons";
import { Box } from "@mui/material";
import MediaQuery from "react-responsive";
import { POS_NEW_ORDER_STEPS } from "../../../constants";
import { deleteRequest } from "../../../api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentCart } from "../../../redux/cartSlice";
import { addToast } from "../../../redux/toastSlice";
import { selectCurrentUser } from "../../../redux/authSlice";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#4C8C4A",
    color: "white",
    fontSize: "8px",
  },
}));

export default function CartBadge({
  cart,
  setCart,
  currentStep = POS_NEW_ORDER_STEPS.ADD_ITEM,
  setCurrentStep,
}) {
  const [title, setTitle] = useState("New Item")
  const [cartItems, setCartItems] = useState(null)

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const resetCartAndDeletePayment = async () => {
    try {
      if (cart?.payment_id) {
        const response = await deleteRequest(
          `stores/${userData.stores[0].id}/payments/${cart?.payment_id}`
        );
        if (response) {
          setCart((prev) => ({ ...prev, payment_id: null}));
        }
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      setCart((prev) => ({ ...prev, type: null, paymentMethod: null }));
    }
  };

  const handleCartClick = async () => {
    switch (currentStep) {
      case POS_NEW_ORDER_STEPS.ORDER_CART:
        return;

      case POS_NEW_ORDER_STEPS.ADD_ITEM:
        setTitle("New Item");
        setCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART);
        return;

      default:
        setTitle("Cart");
        setCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART);
        await resetCartAndDeletePayment();
        return;
    }
  };

  const handleBack = async () => {
    switch (currentStep) {
      case POS_NEW_ORDER_STEPS.ADD_ITEM:
        return;

      case POS_NEW_ORDER_STEPS.ORDER_CART:
        // setTitle("New Item");
        setCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM);
        return;

      default:
        // setTitle("Cart");
        setCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART);
        await resetCartAndDeletePayment();
        return;
    }
  };

  useEffect(() => {
    if (cart) {
      setCartItems((cart?.serviceLineItems?.reduce(
          (acc, item) => acc + item.quantity,
          0
        ) || 0) +
        (cart?.productLineItems?.reduce(
          (acc, item) => acc + item.quantity,
          0
        ) || 0))
    }
  }, [cart])

  useEffect(() => {
    switch (currentStep) {
      case POS_NEW_ORDER_STEPS.ADD_ITEM:
      case POS_NEW_ORDER_STEPS.ORDER_CART:
        setTitle("New Item");
        break;
      default:
        setTitle("Cart");
        return;
    }
  }, [currentStep])

  return (
    <MediaQuery maxWidth={1023}>
      <div className="flex justify-between items-center mr-2">
        <Box
          className="flex items-center gap-1 cursor-pointer"
          onClick={handleBack}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          { currentStep !== POS_NEW_ORDER_STEPS.ADD_ITEM && <LeftArrowIcon width={18} height={19} />}
          <span className="text-lg font-['Montserrat'] leading-[25.20px] font-semibold">
            {title}
          </span>
        </Box>
        <IconButton
          aria-label="cart"
          onClick={handleCartClick}
        >
          <StyledBadge
            badgeContent={cartItems}
            color="secondary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CartIcon />
          </StyledBadge>
        </IconButton>
      </div>
    </MediaQuery>
  );
}
