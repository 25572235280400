import React, { useState, useEffect } from "react";
import { Button, Divider, Typography, Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/authSlice";
import { getRequest } from "../../../api";
import { addToast } from "../../../redux/toastSlice";
import { POS_NEW_ORDER_STEPS } from "../../../constants";
import CartBadge from "./CartBadge";

const SelectDiscount = ({ cart, setCart, currentStep, setCurrentStep, isDesktopScreen }) => {
  const [selectedDiscountIndex, setSelectedDiscountIndex] = useState(-1);
  const [discountList, setDiscountList] = useState([]);

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const getStoreDiscounts = async () => {
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/discounts`
      );

      setDiscountList(response);
    } catch (error) {
      dispatch(addToast(error.message));
    }
  };

  const onDiscountApply = (discount) => {
    if (discount) {
      setCart({...cart, discount});
      isDesktopScreen
        ? setCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM)
        : setCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART);
    }
  };

  useEffect(() => {
    if (userData) {
      getStoreDiscounts();
    }
  }, [userData]);

  return (
    <div className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <div className="flex sm:h-auto flex-col justify-between lg:p-5 gap-0">
        <div className="flex flex-col justify-between">
          <CartBadge
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <div className="flex justify-between items-center">
            <p className="text-lg md:text-2xl font-semibold md:font-bold font-['Montserrat'] leading-[25.20px]">
              Add discount
            </p>
            <Button
              variant="textSecondary"
              startIcon={<Add />}
              onClick={() =>
                setCurrentStep(POS_NEW_ORDER_STEPS.CREATE_NEW_DISCOUNT)
              }
            >
              New discount
            </Button>
          </div>
        </div>
        <Typography variant="body">
          Selected discount will be applied to the entire order
        </Typography>
        <Divider sx={{ my: 3 }} />
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-3">
          {discountList.map((item, index) => (
            <Button
              key={index}
              variant="selectableSecondary"
              isSelected={selectedDiscountIndex === index}
              onClick={() =>
                setSelectedDiscountIndex(
                  selectedDiscountIndex === index ? -1 : index
                )
              }
            >
              {item.name}
            </Button>
          ))}
        </div>
      </div>
      <div className="flex flex-col md:flex-row-reverse w-full lg:w-1/2 lg:ml-auto items-center gap-2 lg:p-5">
        <Button
          variant="containedPrimary"
          disabled={selectedDiscountIndex === -1}
          onClick={() => onDiscountApply(discountList[selectedDiscountIndex])}
          fullWidth
        >
          Apply
        </Button>
        <Button
          variant="outlinedSecondary"
          onClick={() => isDesktopScreen ? setCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM) : setCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART)}
          fullWidth
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default SelectDiscount;
