import React from "react";

const FieldItem = ({ children }) => {
  return (
    <div className="min-w-[320px] flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-5">
      {children}
    </div>
  );
};

export default FieldItem;
