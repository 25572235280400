import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadTemplate, extractOriginalCSVData } from "../../utils/customerList";
import CustomTable from "../Table";

const FileUploadModal = ({ open, onClose, onFileUpload }) => {
  const [file, setFile] = useState(null);
  const [originalHeaders, setOriginalHeaders] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const resetState = () => {
    setFile(null);
    setOriginalHeaders([]);
    setOriginalRows([]);
    setError(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const { headers, data, error } = extractOriginalCSVData(csvData);
        if (error) {
          setError(error);
          setFile(null);
        } else {
          setFile(acceptedFiles[0]);
          setOriginalHeaders(headers);
          setOriginalRows(data);
          setError(null);
        }
      };
      reader.readAsText(acceptedFiles[0]);
    },
  });

  const handleFileUpload = () => {
    if (file) {
      onFileUpload(file);
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="file-upload-dialog-title"
      aria-describedby="file-upload-dialog-description"
    >
      <Box
        className="bg-white rounded-lg shadow-md p-10 mx-auto"
        style={{
          width: "90%",
          maxWidth: "900px",
          outline: "none",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          maxHeight: "90vh",
          overflow: "hidden",
        }}
      >
        <Box className="flex justify-between items-start pb-2">
          <Typography
            id="file-upload-dialog-title"
            variant="h5"
            component="span"
            fontWeight="bold"
            style={{ maxWidth: "85%", width: "100%" }}
          >
            Upload CSV File
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ marginTop: "-8px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {!file && (
          <Box
            {...getRootProps({ className: "dropzone" })}
            style={{
              border: "2px dashed #ccc",
              borderRadius: "10px",
              padding: "40px",
              textAlign: "center",
              cursor: "pointer",
              minHeight: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px"
            }}
          >
            <input {...getInputProps()} />
            <Typography variant="body1">
              Drag and drop a CSV file here, or click to select a file
            </Typography>
          </Box>
        )}
        {error && (
          <Box mt={2}>
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          </Box>
        )}
        {file && originalRows?.length > 0 && (
          <React.Fragment>
            <Typography variant="h6">CSV preview</Typography>
            <Typography variant="body">{file.name}</Typography>
            <Box
              mt={2}
              style={{
                overflowY: "auto",
                maxHeight: "50vh",
                overflowX: "auto",
              }}
            >
              <Box style={{ overflowX: "auto" }}>
                <CustomTable
                  columns={originalHeaders.map((header) => ({
                    field: header,
                    label: header,
                    width: "150px",
                  }))}
                  rows={originalRows.map((row) =>
                    row.reduce((acc, cell, index) => {
                      acc[originalHeaders[index]] = cell;
                      return acc;
                    }, {})
                  )}
                  sortable={false}
                  searchable={false}
                  csvPreview={true}
                />
              </Box>
            </Box>
          </React.Fragment>
        )}
        <Box className="flex justify-between mt-10 space-x-2">
          <Typography
            variant="body2"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              display: "flex",
              alignItems: "center",
            }}
            onClick={downloadTemplate}
          >
            <DownloadIcon style={{ marginRight: "4px" }} />
            Download CSV Template
          </Typography>
          <Button
            onClick={handleFileUpload}
            variant="contained"
            color="primary"
            style={{ minWidth: "120px" }}
            disabled={!!error}
          >
            Create now
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FileUploadModal;
