import React, { useEffect } from "react";
import EntityPage from "../../EntityPage";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import {
  ORDERS_OVERVIEW,
  POS_CREATE_ORDER,
} from "../../../constants/FrontendRoutes";
import {
  CART_TYPES,
  ORDER_TYPE_ENUM,
  POS_NEW_ORDER_STEPS,
} from "../../../constants";
import { initialCartState } from "../../../redux/cartSlice";

const OrderSuccess = ({
  cart,
  setCart,
  setCurrentStep,
  emptyCart,
  currentOrder,
}) => {
  const navigate = useNavigate();

  const handleCreateNew = () => {
    setCart((prev) => ({
      ...initialCartState,
      productSalesTaxRate: prev.productSalesTaxRate,
      serviceSalesTaxRate: prev.serviceSalesTaxRate,
      rushOrder: { ...prev.rushOrder, isRush: false },
    }));
    setCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM);
  };

  return (
    <Box className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[60%] lg:max-w-[70%] xl:max-w-[50%] sm:m-auto sm:gap-8">
        <div className="flex flex-col gap-3">
          <p className="mt-4 text-2xl font-bold font-['Questrial'] leading-snug">
            {`${cart.type == CART_TYPES.checkout ? "Order" : "Quote"} #${
              cart.orderId
            } has been created! 🎉`}
          </p>
          <p className="mt-4 text-lg font-semibold font-['Questrial'] leading-snug">
            {`We will be sending ${
              cart.type == CART_TYPES.checkout
                ? "emails to the customer for their record!"
                : "the quote to the customer to complete the order!"
            }`}
          </p>
        </div>
        <Box className="flex flex-col-reverse sm:flex-row items-center gap-2">
          <Button
            variant="outlinedSecondary"
            fullWidth
            onClick={() => navigate(ORDERS_OVERVIEW)}
          >
            Back to order list
          </Button>
          <Button
            fullWidth
            variant="containedPrimary"
            startIcon={<Add />}
            onClick={handleCreateNew}
          >
            Create new order
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSuccess;
