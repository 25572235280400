import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledCard = styled(Card)(({ theme, isSelected }) => ({
  borderRadius: "8px",
  border: `1px solid ${isSelected ? theme.palette.border.primary : theme.palette.border.secondary}`,
  backgroundColor: isSelected ? theme.palette.background.primary : "transparent",
  padding: theme.spacing(2),
  "&:hover": {
    cursor: "pointer",
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  boxShadow: "none",
  height: "80px",
}));

const ItemCard = ({data, isSelected, onClick, ...rest}) => {

  return (
    <StyledCard isSelected={isSelected} onClick={onClick} {...rest}>
      <CardContent sx={{ p: "0px !important", width: "100%" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap={0.5}
          >
            <Typography
              component="p"
              variant="body"
              color="textSecondary"
              sx={{
                fontSize: { xs: "16px", sm: "18px" },
              }}
            >
              Item
            </Typography>
            <Typography
              component="p"
              variant="body"
              width="max-content"
              sx={{
                fontSize: { xs: "16px", sm: "18px" },
              }}
            >
              {data.name}
            </Typography>
          </Box>
          {data?.price && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={0.5}
              ml={0.5}
            >
              <Typography component="p" variant="body" color="textSecondary">
                Price
              </Typography>
              <Typography
                component="p"
                variant="subTitle"
                width="max-content"
                textAlign="right"
              >
                {`$${parseFloat(data?.price).toFixed(2)}`}
              </Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default ItemCard;
