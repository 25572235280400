export const OrderStatus = {
  QUOTE: "quote",
  NEW_ORDER: "new_order",
  SHIPPING: "shipping",
  IN_PROGRESS: "in_progress",
  DELAYED: "delayed",
  REPAIRED: "repaired",
  DELIVERED: "delivered",
};

export const OrderStatusMap = {
  quote: "Quote",
  new_order: "New Order",
  shipping: "Shipping",
  in_progress: "In Progress",
  delayed: "Delayed",
  repaired: "Repaired",
  delivered: "Delivered",
};
