import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import InputFileUpload from "../../Button/FileUpload";
import CustomTable from "../../Table";
import { getRequest, deleteRequest } from "../../../api";
import { Plus } from "../../../common/icons";
import { POS, POS_CREATE_OPTION } from "../../../constants/FrontendRoutes";
import { PRODUCT_STATUS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../../redux/toastSlice";
import { maskUSD } from "../../../utils";
import { selectCurrentUser } from "../../../redux/authSlice";
import ActionMenu from "../../Button/ActionMenu";

const columns = [
  {
    field: "id",
    label: "ID",
    sortable: true,
    width: "100px",
  },
  {
    field: "name",
    label: "Options",
    sortable: true,
    searchable: true,
    width: "200px",
  },
  {
    field: "category",
    label: "Categories",
    sortable: true,
    width: "200px",
  },
  {
    field: "brand",
    label: "Brand",
    sortable: true,
    searchable: true,
    width: "100px",
  },
  {
    field: "price",
    label: "Price",
    sortable: true,
    width: "100px",
  },
  {
    field: "actions",
    label: "",
    width: "10px",
    component: ActionMenu,
  },
];

const transformData = (data, handleEdit, handleRemove) => {
  return data.map((item) => {
    const uniqueCategories = [
      ...new Set(item?.sub_categories?.map((sub) => sub?.category)),
    ];

    return {
      id: item?.id,
      name: item?.name,
      brand: item?.brand || "",
      status: PRODUCT_STATUS[item?.status],
      category: uniqueCategories.join(", "),
      price: maskUSD(item?.price),
      actionsProps: {
        listOfActions: [
          { label: "Edit", handler: handleEdit },
          { label: "Remove", handler: handleRemove },
        ],
      },
    };
  });
};

function ActionItems({ onCSV }) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {/* <InputFileUpload onChange={onCSV} /> */}
      <Button
        variant="containedPrimary"
        onClick={() => navigate(POS_CREATE_OPTION)}
        startIcon={<Plus />}
      >
        Add Option
      </Button>
    </React.Fragment>
  );
}

const Option = ({ onCSVUpload }) => {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectCurrentUser);

  useEffect(() => {
    if (userData) {
      handleGetOptions()
        .then((options) => {
          setRows(transformData(options, handleEdit, handleRemove));
        })
        .catch((error) => {
          dispatch(addToast(error.message));
        });
    }
  }, [userData]);

  const handleGetOptions = async () => {
    return await getRequest(
      `stores/${userData.stores[0].id}/options`,
      {},
      "sub_categories"
    );
  };

  const handleEdit = (id) => {
    navigate(`${POS}/edit/option/${id}`);
  };

  const handleRemove = async (id) => {
    try {
      await deleteRequest(
        `stores/${userData.stores[0].id}/options/${id}`
      );
      setRows((prevRows) =>
        prevRows.filter((row) => row.id !== id)
      );
      dispatch(addToast("Option removed successfully."));
    } catch (error) {
      dispatch(addToast(error.message));
    }
  };

  return (
    <div className="flex flex-col justify-between">
      <CustomTable
        columns={columns}
        rows={rows}
        sortable
        searchable
        actions={<ActionItems onCSV={onCSVUpload} />}
      />
    </div>
  );
};

export default Option;
