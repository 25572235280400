import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import SwitchIosStyle from "../Switch/SwitchIosStyle";
import {
  ButtonIcon,
  DeviderButtonIcon,
  ImagePlaceholderIcon,
  TextIcon,
} from "../../common/icons";

const EditCampaign = ({ setCurrentStep }) => {
  const [selectedItem, setSelecteditem] = useState("");
  const [showInputBar, setShowInputBar] = useState(false);
  const [campaignData, setCampaignData] = useState({
    firstHeading: "",
    firstParagraph: "",
    secondParagraph: "",
  });

  const toggleShowInputBar = (event) => {
    setShowInputBar(event.target.checked);
  };

  const handleFirstHeading = (e) => {
    setCampaignData({ ...campaignData, firstHeading: e.target.value });
  };

  const handleFirstParagraph = (e) => {
    setCampaignData({ ...campaignData, firstParagraph: e.target.value });
  };

  const handleSecondParagraph = (e) => {
    setCampaignData({ ...campaignData, secondParagraph: e.target.value });
  };

  useEffect(() => {
    setCampaignData({
      firstHeading: "Don't Miss Out!",
      firstParagraph: "Limited Time Only!",
      secondParagraph: "Perfect timing to rejuvenate your cherished items!",
    });
  }, []);

  return (
    <div className="flex flex-row gap-10 justify-between">
      <div className="p-8 bg-white">
        {selectedItem === "" && (
          <div className="flex flex-col w-[380px] gap-3 h-full">
            <p className="text-lg font-semibold">Add new element</p>
            <p>
              Select the element from the list below to start building your
              email
            </p>
            <div className="flex flex-col h-full gap-5">
              <div className="grid grid-cols-2 gap-5">
                <div className="flex flex-col p-3 border items-center gap-3 rounded-lg">
                  <TextIcon />
                  <p className="text-center">Text</p>
                </div>
                <div className="flex flex-col p-3 border items-center gap-3 rounded-lg">
                  <ImagePlaceholderIcon className="w-[90px] h-auto" />
                  <p className="text-center">Images</p>
                </div>
                <div className="flex flex-col p-3 border items-center gap-3 rounded-lg">
                  <ButtonIcon />
                  <p className="text-center">Button</p>
                </div>
                <div className="flex flex-col p-3 border items-center gap-3 rounded-lg">
                  <DeviderButtonIcon />
                  <p className="text-center">Text</p>
                </div>
              </div>
              <div className="flex justify-end items-end">
                <button
                  type="button"
                  className="text-white bg-[#4c8c4a] border border-[#50c04c] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                  onClick={() => setCurrentStep(3)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
        {selectedItem === "image" && (
          <div className="flex flex-col w-[380px] gap-3 h-full">
            <p className="text-lg font-semibold">Customize Image</p>
            <ImagePlaceholderIcon className="w-[400px] h-[300px]" />
            <div className="flex flex-row justify-between items-center">
              <p>Add a link</p>
              <SwitchIosStyle
                checked={showInputBar}
                onChange={toggleShowInputBar}
              />
            </div>
            {showInputBar && <TextField label="Link" type="text" />}
            <div className="flex justify-end items-end">
              <button
                type="button"
                className="text-white bg-[#4c8c4a] border border-[#50c04c] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                onClick={() => setSelecteditem("")}
              >
                Apply
              </button>
            </div>
          </div>
        )}
        {selectedItem !== "image" && selectedItem !== "" && (
          <div className="flex flex-col w-[380px] gap-3 h-full">
            <p className="text-lg font-semibold">Customize text</p>
            {selectedItem === "firstHeading" && (
              <TextField
                value={campaignData.firstHeading}
                onChange={handleFirstHeading}
                label="Text"
                type="text"
              />
            )}
            {selectedItem === "firstParagraph" && (
              <TextField
                value={campaignData.firstParagraph}
                onChange={handleFirstParagraph}
                label="Text"
                type="text"
              />
            )}
            {selectedItem === "secondParagraph" && (
              <TextField
                value={campaignData.secondParagraph}
                onChange={handleSecondParagraph}
                label="Text"
                type="text"
              />
            )}
            <div className="flex justify-end items-end">
              <button
                type="button"
                className="text-white bg-[#4c8c4a] border border-[#50c04c] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                onClick={() => setSelecteditem("")}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="w-full p-5">
        <div className="bg-white m-8 rounded-lg">
          <div className="flex flex-col text-center gap-5 px-16 py-8">
            <p
              className={`text-3xl font-bold p-2 rounded-xl ${
                selectedItem === "firstHeading"
                  ? "text-[#4c8c4a] border-[#4c8c4a] bg-[#d2e2d2]"
                  : ""
              }`}
              onClick={() => setSelecteditem("firstHeading")}
            >
              {campaignData.firstHeading}
            </p>
            <p
              className={`text-2xl font-light p-2 rounded-xl ${
                selectedItem === "firstParagraph"
                  ? "text-[#4c8c4a] border-[#4c8c4a] bg-[#d2e2d2]"
                  : ""
              }`}
              onClick={() => setSelecteditem("firstParagraph")}
            >
              {campaignData.firstParagraph}
            </p>
            <ImagePlaceholderIcon
              className="h-[386px] w-auto"
              isGreen={selectedItem === "image" ? true : false}
              onClick={() => setSelecteditem("image")}
            />
            <p
              className={`${
                selectedItem === "secondParagraph"
                  ? "text-[#4c8c4a] p-2 border-[#4c8c4a] bg-[#d2e2d2]"
                  : ""
              }`}
              onClick={() => setSelecteditem("secondParagraph")}
            >
              {campaignData.secondParagraph}
            </p>
            <button
              type="button"
              className="text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 items-center text-center"
            >
              Learn more
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCampaign;
