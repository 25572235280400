import React from "react";

const TableRowHeader = ({ name, count, bgColor, className }) => {
  return (
    <div className="flex flex-row justify-between items-center w-[222.5px]">
      <h2 className="font-bold text-lg">{name}</h2>
      <p className={`px-4 py-2 ${bgColor} rounded-xl`}>{count}</p>
    </div>
  );
};
export default TableRowHeader;
