import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CrossCircle, PlusCircle } from "../common/icons";
import EntityPage from "../components/EntityPage";
import Selector from "../components/Form/Field/AutoComplete";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";
import { COMMON_STATUS, SERVICE_TYPE } from "../constants";
import { postRequest, getRequest } from "../api";
import { selectCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";
import { fetchServiceTypes } from "../redux/storeAction";
import { selectAllCategories, selectAllServiceType } from "../redux/storeSlice";

const INITIAL_SERVICE = {
  categoryType: "",
  serviceType: "",
  serviceName: "",
  costPerItem: "",
  price: "",
};

const CreateService = () => {
  const [mainCategory, setMainCategory] = useState();
  const [status, setStatus] = useState();
  const [newCategoryType, setNewCategoryType] = useState();
  const [subCategoryOptions, setSubCategoryOptions] = useState();
  const [services, setServices] = useState([{ ...INITIAL_SERVICE }]);
  const [hoverIndex, setHoverIndex] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector(selectCurrentUser);
  const allServiceTypes = useSelector(selectAllServiceType);
  const allCategories = useSelector(selectAllCategories);

  useEffect(() => {
    if (mainCategory) {
      handleGetSubCategories();
    } else {
      setSubCategoryOptions([]);
    }
    setServices((prev) => prev.map((item) => ({ ...item, categoryType: "" })));
  }, [mainCategory]);

  const handleGetSubCategories = async () => {
    try {
      const data = await getRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/sub_categories`,
        {
          filters: {
            category: mainCategory.value,
          },
        }
      );

      setSubCategoryOptions(
        data.map((item) => ({
          title: item.name,
          value: item.id,
        }))
      );
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    }
  };

  const handleCreateServiceType = async (serviceType) => {
    try {
      const res = await postRequest(
        `stores/${userData.stores[0].id}/service_types`,
        {
          service_type: {
            name: serviceType,
          },
        }
      );
      if (res.status === 201) {
        dispatch(fetchServiceTypes(userData.stores[0].id));
        dispatch(addToast("Service Type(s) added successfully."));
      }
    } catch (error) {
      dispatch(addToast(error));
    }
  }

  const handleCreateSubCategory = async () => {
    try {
      const payload = [{
        name: newCategoryType,
        status: status.value,
        category: mainCategory.value,
      }];

      const res = await postRequest(
        `stores/${userData.stores[0].id}/sub_categories`,
        { sub_categories: payload }
      );

      if (res.status === 201) {
        dispatch(addToast("Category Type(s) added successfully."));
        handleGetSubCategories();
        setNewCategoryType("");
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    }
  };

  const handleCreateService = async () => {
    try {
      const payload = services.flatMap((item) => {
        const { serviceName, costPerItem, price, serviceType, categoryType } = item;
        return categoryType.map((category) => ({
          name: serviceName,
          cost_per_item: costPerItem,
          price,
          service_type: serviceType.value,
          sub_category_id: category.value,
        }));
      });

      const res = await postRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/services`,
        { services: payload }
      );

      if (res.status === 201) {
        navigate(-1);
        dispatch(addToast("Service(s) added successfully."));
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    }
  };

  const handleInputChange = (index, name, value) => {
    const updatedServices = [...services];
    updatedServices[index][name] = (name === "costPerItem" || name === "price")
      ? parseFloat(value) : value;
    setServices(updatedServices);
  };

  const handleAddMore = () => {
    setServices([{ ...INITIAL_SERVICE }, ...services]);
  };

  const handleRemove = (index) => {
    const updatedServices = services.filter((_, i) => i !== index);
    setServices(updatedServices);
  };

  const isAllServiceFieldsFilled = services.every((service) => {
    const { serviceId, ...serviceWithoutId } = service;
    return Object.values(serviceWithoutId).every((value) =>
      Array.isArray(value)
        ? value.length > 0
        : typeof value === "string"
        ? value.trim() !== ""
        : value
    );
  });

  const isFormFilled = isAllServiceFieldsFilled && !!status && !!mainCategory;

  return (
    <EntityPage title="Add Service" breadcrumbs stickyBottomBar>
      <FormPage>
        <FieldGroup label="Category Details">
          <FieldItem>
            <Typography width="30%" variant="body">
              Main Category
            </Typography>
            <Selector
              label="Please select"
              options={allCategories?.map((item) => ({
                title: item?.name,
                value: item?.name,
              }))}
              value={mainCategory}
              onChange={(_e, value) => setMainCategory(value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Status
            </Typography>
            <Selector
              label="Please select"
              options={Object.entries(COMMON_STATUS).map(([key, value]) => ({
                title: value,
                value: key,
              }))}
              value={status}
              onChange={(_e, value) => setStatus(value)}
            />
          </FieldItem>

          <FieldItem submit={true}>
            <Typography
              variant="body"
              sx={{
                "@media (min-width: 640px)": { width: "30%" },
              }}
            >
              Category Type(s)
              <p className="text-[#939291] text-xs font-normal font-['Questrial'] leading-none mt-2">
                *Enter only when you’d like to create new category types.{" "}
              </p>
            </Typography>
            <Box className="w-full">
              <TextInput
                value={newCategoryType}
                onChange={(e) => setNewCategoryType(e.target.value)}
              />
              <Button
                disabled={!newCategoryType || !mainCategory}
                variant="textPrimary"
                startIcon={
                  <PlusCircle
                    fill={!newCategoryType || !mainCategory ? "gray" : "green"}
                  />
                }
                sx={{
                  width: "fit-content",
                  marginTop: "0.5rem",
                }}
                onClick={handleCreateSubCategory}
              >
                Add category type
              </Button>
            </Box>
          </FieldItem>
        </FieldGroup>

        <hr className="my-10" />

        <Box className="flex justify-between items-center">
          <Typography variant="h6">Service Details</Typography>
          <Button
            variant="textPrimary"
            startIcon={
              <PlusCircle fill={!isAllServiceFieldsFilled ? "gray" : "green"} />
            }
            onClick={handleAddMore}
            disabled={!isAllServiceFieldsFilled}
          >
            Add more
          </Button>
        </Box>

        {services.map((service, index) => (
          <React.Fragment key={index}>
            <FieldGroup>
              <FieldItem>
                <Typography width="30%" variant="body">
                  Service Name
                </Typography>
                <TextInput
                  name="serviceName"
                  value={service.serviceName}
                  onChange={(e) =>
                    handleInputChange(index, "serviceName", e.target.value)
                  }
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Service Type
                </Typography>
                <Selector
                  name="serviceType"
                  label="Please select"
                  options={allServiceTypes?.map((item) => ({
                    title: item?.name,
                    value: item?.name,
                  }))}
                  value={service.serviceType}
                  onChange={(_e, value) =>
                    handleInputChange(index, "serviceType", value)
                  }
                  newItemTitle="Add new service type"
                  handleNewItemClick={(e) => handleCreateServiceType(e)}
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Category Type
                </Typography>
                <Selector
                  name="categoryType"
                  label="Please select"
                  multiSelect
                  options={subCategoryOptions}
                  value={service.categoryType}
                  onChange={(_e, value) =>
                    handleInputChange(index, "categoryType", value)
                  }
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Cost per item
                </Typography>
                <TextInput
                  name="costPerItem"
                  type="number"
                  value={service.costPerItem}
                  onChange={(e) =>
                    handleInputChange(index, "costPerItem", e.target.value)
                  }
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Price
                </Typography>
                <TextInput
                  name="price"
                  type="number"
                  value={service.price}
                  onChange={(e) =>
                    handleInputChange(index, "price", e.target.value)
                  }
                />
              </FieldItem>
            </FieldGroup>
            <Box
              className="flex justify-end items-center my-4"
              sx={{ position: "relative" }}
            >
              {index !== services.length - 1 && (
                <Button
                  variant="textPrimary"
                  startIcon={
                    <CrossCircle
                      fill={hoverIndex === index ? "green" : "gray"}
                    />
                  }
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={() => handleRemove(index)}
                >
                  Remove
                </Button>
              )}
            </Box>
            {index < services.length - 1 && <hr className="my-10" />}
          </React.Fragment>
        ))}

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="containedPrimary"
              disabled={!isFormFilled}
              onClick={handleCreateService}
            >
              Create
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default CreateService;
