import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";

const StatsCard = ({
  bgColor,
  title,
  titleIcon: TitleIcon,
  total,
  descriptionIcon: DescriptionIcon,
  statistics,
  description,
  navigateTo
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  };
  return (
    <div
      style={{ backgroundColor: bgColor }}
      className="rounded-2xl px-4 py-4 flex gap-1 justify-between items-center  cursor-pointer transform transition-transform duration-200 hover:scale-105"
      onClick={handleClick}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center gap-2">
          {TitleIcon && <TitleIcon />}
          <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            {title}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-neutral-800 text-2xl font-bold font-['Montserrat'] leading-[33.60px]">
            {total}
          </p>
          <div className="flex flex-row gap-1">
            {DescriptionIcon && <DescriptionIcon />}
            <p>
              <span className="text-teal-500 text-base font-normal font-['Questrial'] leading-snug">
                {statistics}
              </span>{" "}
              <span className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                {description}
              </span>
            </p>
          </div>
        </div>
      </div>
      {navigateTo && <ChevronRightIcon />}
    </div>
  );
};

export default StatsCard;
