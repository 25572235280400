import React, { useState } from "react";
import ComponentHeader from "../common/components/ComponentHeader";
import BasicTabs from "../common/components/Tabs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  TextField,
} from "@mui/material";
import GreenButton from "../components/Button/GreenButton";
import img from "../images/campaign.jpeg";
import { ArrowDownIcon, ArrowUpIcon, MoreIcon } from "../common/icons";
import { Link } from "react-router-dom";
import { CUSTOMERS_EMAIL_CAMPAIGNS_NEW } from "../constants/FrontendRoutes";

export const EmailCampaignsPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState(null);
  const tabList = ["All campaigns", "Recurring", "One time"];

  const handleChange = (value) => {
    setExpanded(expanded === value ? null : value);
  };

  return (
    <>
      <div className="max-w-screen-xl mx-auto">
        <ComponentHeader name={"Email campaigns"} />
      </div>
      <div className="w-full bg-white">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex flex-col gap-5 p-2">
            <BasicTabs list={tabList} setValue={setTabValue} value={tabValue} />
            <div className="flex flex-row justify-between">
              <p className="text-lg font-bold">Total 3 Campaigns</p>
              <div className="flex flex-row gap-2 items-center">
                <TextField label="Search by name" type="text" />
                <Link to={CUSTOMERS_EMAIL_CAMPAIGNS_NEW}>
                  <GreenButton text={"+ New Campaigns"} />
                </Link>
              </div>
            </div>
            <div className="border">
              <div className="p-3 flex flex-col gap-6">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <img src={img} className="w-[95px] h-[95px] rounded-lg" />
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row gap-3 items-center">
                        <p className="font-bold">
                          What's New at Coblrshop - March Edition
                        </p>
                        <p className="px-2 py-1 rounded-3xl bg-[#e8e8e8] text-xs">
                          Draft
                        </p>
                      </div>
                      <p className="text-sm text-[#939291]">
                        Last edited on March 25, 2024 at 17:27
                      </p>
                    </div>
                  </div>
                  <MoreIcon />
                </div>
                <Divider />
                <p>This campaign hasn’t been published yet.</p>
              </div>
            </div>
            <div className="border">
              <div className="p-3 flex flex-col gap-6">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <img src={img} className="w-[95px] h-[95px] rounded-lg" />
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row gap-3 items-center">
                        <p className="font-bold">
                          What's New at Coblrshop - March Edition
                        </p>
                        <p className="px-2 py-1 rounded-3xl bg-[#d8ede0] text-[#5a8a6e] text-xs">
                          Sent
                        </p>
                      </div>
                      <p className="text-sm text-[#939291]">
                        Last edited on March 25, 2024 at 17:27
                      </p>
                    </div>
                  </div>
                  <MoreIcon />
                </div>
                <Divider />
                <Accordion
                  expanded={expanded === "panel1"}
                  sx={{ boxShadow: "none" }}
                  onChange={() => handleChange("panel1")}
                  className="w-full border-0"
                >
                  <AccordionSummary className="w-full">
                    <div className="flex flex-row justify-between w-full">
                      <div className="flex flex-row w-2/4 justify-between">
                        <div className="flex flex-col">
                          <p className="font-bold text-sm">317</p>
                          <p className="text-sm">Opened</p>
                        </div>
                        <p className="border-e"></p>
                        <div className="flex flex-col">
                          <p className="font-bold text-sm">17</p>
                          <p className="text-sm">Clicked</p>
                        </div>
                        <p className="border-e"></p>
                        <div className="flex flex-col">
                          <p className="font-bold text-sm">21</p>
                          <p className="text-sm">Site sessions</p>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <div className="flex flex-row gap-3 items-center">
                          {expanded === "panel1" ? (
                            <ArrowUpIcon />
                          ) : (
                            <ArrowDownIcon />
                          )}
                          <p className="underline hover:text-[#4c8c4a]">
                            Show Details
                          </p>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-col gap-10">
                      <div className="flex flex-col gap-5">
                        <p className="font-bold">Delivery stats</p>
                        <p>
                          This email was sent to{" "}
                          <span className="font-bold">822</span> contacts, check
                          how they interact with it:
                        </p>
                        <div className="flex flex-row w-2/4 justify-between">
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">827</p>
                            <p className="text-sm">Delivered</p>
                          </div>
                          <p className="border-e"></p>
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">317</p>
                            <p className="text-sm">Opened</p>
                          </div>
                          <p className="border-e"></p>
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">62</p>
                            <p className="text-sm">Clicked</p>
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-5">
                        <p className="font-bold">Traffic</p>
                        <p>This data is refreshed every 2-4 hours</p>
                        <div className="flex flex-row w-1/3 justify-between">
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">16</p>
                            <p className="text-sm">Unique visitors</p>
                          </div>
                          <p className="border-e"></p>
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">17</p>
                            <p className="text-sm">Site sessions</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div className="border">
              <div className="p-3 flex flex-col gap-6">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <img src={img} className="w-[95px] h-[95px] rounded-lg" />
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row gap-3 items-center">
                        <p className="font-bold">
                          What's New at Coblrshop - March Edition
                        </p>
                        <p className="px-2 py-1 rounded-3xl bg-[#d8ede0] text-[#5a8a6e] text-xs">
                          Sent
                        </p>
                      </div>
                      <p className="text-sm text-[#939291]">
                        Last edited on March 25, 2024 at 17:27
                      </p>
                    </div>
                  </div>
                  <MoreIcon />
                </div>
                <Divider />
                <Accordion
                  expanded={expanded === "panel2"}
                  sx={{ boxShadow: "none" }}
                  onChange={() => handleChange("panel2")}
                  className="w-full border-0"
                >
                  <AccordionSummary className="w-full">
                    <div className="flex flex-row justify-between w-full">
                      <div className="flex flex-row w-2/4 justify-between">
                        <div className="flex flex-col">
                          <p className="font-bold text-sm">317</p>
                          <p className="text-sm">Opened</p>
                        </div>
                        <p className="border-e"></p>
                        <div className="flex flex-col">
                          <p className="font-bold text-sm">17</p>
                          <p className="text-sm">Clicked</p>
                        </div>
                        <p className="border-e"></p>
                        <div className="flex flex-col">
                          <p className="font-bold text-sm">21</p>
                          <p className="text-sm">Site sessions</p>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <div className="flex flex-row gap-3 items-center">
                          {expanded === "panel2" ? (
                            <ArrowUpIcon />
                          ) : (
                            <ArrowDownIcon />
                          )}
                          <p className="underline hover:text-[#4c8c4a]">
                            Show Details
                          </p>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-col gap-10">
                      <div className="flex flex-col gap-5">
                        <p className="font-bold">Delivery stats</p>
                        <p>
                          This email was sent to{" "}
                          <span className="font-bold">822</span> contacts, check
                          how they interact with it:
                        </p>
                        <div className="flex flex-row w-2/4 justify-between">
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">827</p>
                            <p className="text-sm">Delivered</p>
                          </div>
                          <p className="border-e"></p>
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">317</p>
                            <p className="text-sm">Opened</p>
                          </div>
                          <p className="border-e"></p>
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">62</p>
                            <p className="text-sm">Clicked</p>
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-5">
                        <p className="font-bold">Traffic</p>
                        <p>This data is refreshed every 2-4 hours</p>
                        <div className="flex flex-row w-1/3 justify-between">
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">16</p>
                            <p className="text-sm">Unique visitors</p>
                          </div>
                          <p className="border-e"></p>
                          <div className="flex flex-col">
                            <p className="font-bold text-sm">17</p>
                            <p className="text-sm">Site sessions</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
