import React, { useState } from "react";
import { groupedBy } from "../../../utils";
import BasicTabs, { CustomTabPanel } from "../../../common/components/Tabs";
import ItemCard from "./ItemCard";

const ProductItem = ({ products, currentProduct, setCurrentProduct }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const groupedProducts = groupedBy(products, "category");
  const uniqueMainCategories = Object.keys(groupedProducts);

  const handleTabChange = (e) => {
    setCurrentProduct();
    setCurrentTab(e);
  };

  const isProductSelected = (product) => {
    return (
      currentProduct &&
      currentProduct.id === product.id &&
      currentProduct.name === product.name &&
      currentProduct.price === product.price
    );
  };

  return (
    <React.Fragment>
      <BasicTabs
        setValue={(e) => handleTabChange(e)}
        value={currentTab}
        list={uniqueMainCategories.map((ct) => ct)}
      />
      {uniqueMainCategories.map((category, index) => (
        <CustomTabPanel value={currentTab} index={index} key={index}>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-4 my-5">
            {groupedProducts[category].map((product, scIndex) => (
              <ItemCard
                data={product}
                isSelected={isProductSelected(product)}
                onClick={() =>
                  setCurrentProduct({
                    id: product.id,
                    name: product.name,
                    price: product.price,
                  })
                }
                key={scIndex}
              />
            ))}
          </div>
        </CustomTabPanel>
      ))}
    </React.Fragment>
  );
};

export default ProductItem;
