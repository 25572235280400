import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import BasicTabs, { CustomTabPanel } from "../../../common/components/Tabs";
import PriceCard from "./PriceCard";
import ItemCard from "./ItemCard";
import {
  POS_CREATE_OPTION,
  POS_CREATE_SERVICE,
} from "../../../constants/FrontendRoutes";
import { groupedBy } from "../../../utils";

const ServiceItem = ({
  subCategories,
  currentServices,
  setCurrentServices,
  currentOptions,
  setCurrentOptions,
  currentCategoryType,
  setCurrentCategoryType,
  resetSelections,
}) => {
  const [currentCategoryTab, setCurrentCategoryTab] = useState(0);
  const [currentServiceTypeTab, setCurrentServiceTypeTab] = useState(0);

  const [allServices, setAllServices] = useState([]);
  const [allOptions, setAllOptions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentCategoryType) {
      const { options, services } = currentCategoryType;

      setAllServices(groupedBy(services, "service_type"));
      setAllOptions(options);
    }
  }, [currentCategoryType]);

  const handleCategoryTab = (e) => {
    resetSelections();
    setCurrentCategoryTab(e);
  };

  const groupedSubCategories = groupedBy(subCategories, "category");
  const uniqueMainCategories = Object.keys(groupedSubCategories);

  const toggleSelection = (setState, item, key = "id") => {
    setState((prevState) => {
      const isItemSelected = prevState.some((i) => i[key] === item[key]);
      if (isItemSelected) {
        return prevState.filter((i) => i[key] !== item[key]);
      } else {
        return [...prevState, item];
      }
    });
  };

  return (
    <React.Fragment>
      <BasicTabs
        setValue={(e) => handleCategoryTab(e)}
        value={currentCategoryTab}
        list={uniqueMainCategories.map((ct) => ct)}
      />
      {uniqueMainCategories.map((category, index) => (
        <CustomTabPanel value={currentCategoryTab} index={index} key={index}>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-4 my-5">
            {groupedSubCategories[category].map((sc, scIndex) => (
              <ItemCard
                data={sc}
                isSelected={currentCategoryType?.id === sc.id}
                onClick={() => setCurrentCategoryType(sc)}
                key={scIndex}
              />
            ))}
          </div>
        </CustomTabPanel>
      ))}

      {currentCategoryType && (
        <React.Fragment>
          <hr className="w-full" />
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between items-center">
              <Typography variant="subTitle">Service</Typography>
              <Button
                variant="textSecondary"
                onClick={() => navigate(POS_CREATE_SERVICE)}
                size="small"
                startIcon={<Add />}
              >
                Add manually
              </Button>
            </div>

            {currentCategoryType.services &&
            currentCategoryType.services.length > 0 ? (
              <React.Fragment>
                <BasicTabs
                  setValue={setCurrentServiceTypeTab}
                  value={currentServiceTypeTab}
                  list={Object.keys(allServices).map((c) => c)}
                />

                {Object.keys(allServices).map((serviceType, index) => (
                  <CustomTabPanel
                    value={currentServiceTypeTab}
                    index={index}
                    key={index}
                  >
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-4 my-5">
                      {allServices[serviceType].map((service, sIndex) => (
                        <PriceCard
                          data={service}
                          isSelected={currentServices.some(
                            (s) =>
                              s.id === service.id &&
                              s.name === service.name &&
                              s.price === parseFloat(service.price) &&
                              s.type === service.service_type
                          )}
                          onClick={() =>
                            toggleSelection(setCurrentServices, {
                              id: service.id,
                              name: service.name,
                              price: parseFloat(service.price),
                              type: service.service_type,
                            })
                          }
                          key={sIndex}
                        />
                      ))}
                    </div>
                  </CustomTabPanel>
                ))}
              </React.Fragment>
            ) : (
              <Typography variant="info" className="text-center my-5">
                No Service(s)
              </Typography>
            )}
          </div>

          <hr className="mt-5 w-full" />
          <div className="flex flex-col w-full gap-1 md:gap-5 ">
            <div className="flex flex-row justify-between items-center">
              <Typography variant="subTitle">Options</Typography>
              <Button
                variant="textSecondary"
                startIcon={<Add />}
                onClick={() => navigate(POS_CREATE_OPTION)}
              >
                Add manually
              </Button>
            </div>
            {allOptions && allOptions.length > 0 ? (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-4 my-5">
                {allOptions.map((option, oIndex) => (
                  <PriceCard
                    data={option}
                    isAdditional
                    isSelected={currentOptions.some(
                      (o) =>
                        o.id === option.id &&
                        o.name === option.name &&
                        o.price === parseFloat(option.price)
                    )}
                    onClick={() =>
                      toggleSelection(setCurrentOptions, {
                        id: option.id,
                        name: option.name,
                        price: parseFloat(option.price),
                      })
                    }
                    key={oIndex}
                  />
                ))}
              </div>
            ) : (
              <Typography variant="info" className="text-center">
                No Option(s)
              </Typography>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ServiceItem;
