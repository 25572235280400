import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MediaQuery, { useMediaQuery } from "react-responsive";

import { EditIcon, LeftArrowIcon, TrashIcon2 } from "../../../common/icons";
import SwitchIosStyle from "../../Switch/SwitchIosStyle";
import DropdownButton from "../../Button/DropdownButton";
import {
  calculateSubTotal,
  calculateTotal,
  groupedBy,
} from "../../../utils";
import {
  CART_TYPES,
  ORDER_TYPE_ENUM,
  POS_NEW_ORDER_STEPS,
  SERVICE_TYPE,
} from "../../../constants";
import { selectCurrentUser } from "../../../redux/authSlice";
import { Link } from "react-router-dom";
import { CUSTOMER_DETAILS } from "../../../constants/FrontendRoutes";
import { createPayment, posCreateOrder } from "../../../api/pos";
import MenuOptionButton from '../../Button/MenuOptionButton';
import CartBadge from "./CartBadge";
import { deleteRequest } from "../../../api";
import { addToast } from "../../../redux/toastSlice";

const EditablePrice = ({ value, onSave, onCancel }) => {
  const [price, setPrice] = useState(parseFloat(value));

  const handleSave = () => {
    onSave(price);
    onCancel();
  };

  const handleCancel = () => {
    setPrice(parseFloat(value));
    onCancel();
  };

  return (
    <div className="flex items-center border rounded-lg px-1 py-1">
      <InputAdornment position="start" className="text-gray-500">
        $
      </InputAdornment>
      <input
        type="number"
        className="text-black text-right focus:outline-none w-[1.6rem] no-arrows"
        value={price}
        onChange={(e) => setPrice(parseFloat(e.target.value))}
        style={{ MozAppearance: "textfield" }}
      />
      <div className="flex items-center ml-2">
        <IconButton onClick={handleCancel} sx={{ m: 0, p: 0 }}>
          <CloseIcon fontSize="small" className="text-red-500" />
        </IconButton>
        <IconButton onClick={handleSave} sx={{ m: 0, p: 0 }}>
          <CheckIcon size="small" className="text-green-500" />
        </IconButton>
      </div>
    </div>
  );
};

const Item = ({
  item,
  index,
  currentStep,
  onSave,
  onCancel,
  isEditing,
  onEdit,
}) => {
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  return (
    <div className="flex flex-col space-y-2 mb-1" key={index}>
      <div className="flex justify-between items-center text-[#272523]">
        <p className="w-[60%] pr-1">{item?.name}</p>
        <div className="flex justify-between items-center">
          {isEditing ? (
            <EditablePrice
              value={item?.price}
              onSave={onSave}
              onCancel={onCancel}
            />
          ) : (
            <div className="flex items-center">
              <span>${parseFloat(item?.price).toFixed(2)}</span>
              <IconButton
                size="small"
                onClick={onEdit}
                sx={{ m: 0, p: 0 }}
                disabled={
                  isDesktopScreen && (currentStep !== POS_NEW_ORDER_STEPS.ADD_ITEM)
                }
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ServiceLineItem = ({
  item,
  index,
  currentStep,
  onUpdateService,
  editingId,
  setEditingId,
}) => {
  const { category, categoryType, services, options, quantity, description } = item;
  const groupedServices = groupedBy(services, "type");

  return (
    <>
      <Divider />
      <div className="mx-4 mt-5">
        <h2 className="text-xs text-[#5D5C5A] mb-1">
          Item {index + 1} - {category}
        </h2>
        <p className="text-[#272523]">{categoryType}</p>
        {quantity && (
          <div className="text-[#5d5c5a] text-xs font-normal font-['Questrial'] leading-none mt-1">
            Quantity: {quantity}
          </div>
        )}
        <Divider sx={{ my: 1 }} />
        {Object.keys(groupedServices).map((key) => (
          <React.Fragment key={key}>
            <h2 className="text-xs text-[#5D5C5A]">{key}</h2>
            {groupedServices[key].map((service, idx) => (
              <Item
                item={service}
                index={idx}
                currentStep={currentStep}
                key={service.id}
                isEditing={editingId == `${service.id}-${index}`}
                onEdit={() => setEditingId(`${service.id}-${index}`)}
                onSave={(newPrice) =>
                  onUpdateService(service.id, newPrice, false, index)
                }
                onCancel={() => setEditingId(null)}
              />
            ))}
          </React.Fragment>
        ))}

        {!!options?.length > 0 && (
          <>
            <Divider sx={{ my: 1 }} />
            <h3 className="text-xs text-[#5D5C5A]">Options</h3>
            {options?.map((option, idx) => (
              <Item
                item={option}
                index={idx}
                currentStep={currentStep}
                isEditing={editingId == `${option.id}-${index}`}
                onEdit={() => setEditingId(`${option.id}-${index}`)}
                onSave={(newPrice) =>
                  onUpdateService(option.id, newPrice, true, index)
                }
                onCancel={() => setEditingId(null)}
              />
            ))}
          </>
        )}
        {!!description && (
          <>
            <Divider sx={{ my: 2 }} />
            <h3 className="text-xs text-[#5D5C5A] mb-1">Descriptions</h3>
            <p className="text-[#272523] mb-4">{description}</p>
          </>
        )}
      </div>
    </>
  );
};

const ProductLineItem = ({
  item,
  index,
  currentStep,
  onUpdateProduct,
  editingId,
  setEditingId,
}) => {
  return (
    <div className="mx-4">
      {item?.id && (
        <Item
          item={item}
          currentStep={currentStep}
          isEditing={editingId === index}
          onEdit={() => setEditingId(index)}
          onSave={(newPrice) => onUpdateProduct(newPrice, index)}
          onCancel={() => setEditingId(null)}
        />
      )}
      {!!item?.description && (
        <h3 className="text-xs text-[#5D5C5A] mb-1">{item?.description}</h3>
      )}

      {!!item?.quantity && (
        <div className="text-[#5d5c5a] text-xs font-normal font-['Questrial'] leading-none">
          Quantity: {item?.quantity}
        </div>
      )}
    </div>
  );
};

const PriceDetail = ({ cart }) => {
  const {
    serviceLineItems,
    productLineItems,
    discount,
    serviceSalesTaxRate,
    productSalesTaxRate,
  } = cart;

  if (!serviceLineItems?.length && !productLineItems?.length) return null;

  return (
    <div className="flex flex-col gap-2 pt-5 w-full">
      <div className="flex flex-row justify-between">
        <p className="text-[#5D5C5A]">Subtotal</p>
        <p className="text-lg font-semibold">
          ${parseFloat(calculateSubTotal(cart)).toFixed(2)}
        </p>
      </div>

      {!!discount && (
        <div className="flex flex-row justify-between">
          <p className="text-[#5D5C5A]">Discount</p>
          <p className="text-lg font-semibold">
            - ${cart.totalDiscount.toFixed(2)}
          </p>
        </div>
      )}

      {!!serviceLineItems?.length && (
        <div className="flex flex-row justify-between">
          <p className="text-[#5D5C5A]">{`Service Tax (${(
            serviceSalesTaxRate * 100
          ).toFixed(2)}%)`}</p>
          <p className="text-lg font-semibold">
            + ${cart.serviceSalesTax.toFixed(2)}
          </p>
        </div>
      )}

      {!!productLineItems?.length && (
        <div className="flex flex-row justify-between">
          <p className="text-[#5D5C5A]">{`Product Tax (${(
            productSalesTaxRate * 100
          ).toFixed(2)}%)`}</p>
          <p className="text-lg font-semibold">
            + ${cart.productSalesTax.toFixed(2)}
          </p>
        </div>
      )}

      <Divider sx={{ my: 2 }} />

      <div className="flex flex-row justify-between">
        <p className="text-[#5D5C5A]">Total</p>
        <p className="text-lg font-semibold">
          ${parseFloat(calculateTotal(cart)).toFixed(2)}
        </p>
      </div>
    </div>
  );
};

const OrderDetail = ({
  defaultCart,
  cart,
  setCart,
  currentStep,
  setCurrentStep,
  onUpdateService,
  onUpdateProduct,
}) => {
  const { serviceLineItems, productLineItems } = cart;
  const [editingId, setEditingId] = useState(null);
  const [enableStripeCharge, setEnableStripeCharge] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const handleDropdownClick = ({ cartType }) => {
    if (cart?.attached_customer) {
      setCart({ ...cart, type: cartType });
      createPayment(cart, setCart, userData, dispatch);

      if (cartType === CART_TYPES.checkout) {
        setCurrentStep(POS_NEW_ORDER_STEPS.PAYMENT_METHOD);
      } else if (cartType === CART_TYPES.quote) {
        posCreateOrder(
          ORDER_TYPE_ENUM.online,
          cart,
          setCart,
          setCurrentStep,
          userData,
          dispatch
        );
      }
    } else {
      setCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER);
    }
  };

  const menuButtonConfig = [
    {
      name: "Attach customer",
      function: () => setCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER),
      disabled:
        currentStep !== POS_NEW_ORDER_STEPS.ADD_ITEM &&
        currentStep !== POS_NEW_ORDER_STEPS.ORDER_CART &&
        currentStep !== POS_NEW_ORDER_STEPS.CREATE_NEW_DISCOUNT &&
        currentStep !== POS_NEW_ORDER_STEPS.ADD_DISCOUNT,
    },
    {
      name: "Add discount",
      function: () => setCurrentStep(POS_NEW_ORDER_STEPS.ADD_DISCOUNT),
      disabled:
        currentStep !== POS_NEW_ORDER_STEPS.ADD_ITEM &&
        currentStep !== POS_NEW_ORDER_STEPS.ORDER_CART &&
        currentStep !== POS_NEW_ORDER_STEPS.SELECT_CUSTOMER &&
        currentStep !== POS_NEW_ORDER_STEPS.CREATE_CUSTOMER
    },
    {
      name: "Discard",
      disabled: currentStep == POS_NEW_ORDER_STEPS.SCAN_CARD,
      function: async () => {
        try {
          if (cart?.payment_id) {
            await deleteRequest(
              `stores/${userData.stores[0].id}/payments/${cart?.payment_id}`
            );
          }
        } catch (error) {
          dispatch(addToast(error));
        } finally {
          setCart((prev) => ({
            ...defaultCart,
            productSalesTaxRate: prev.productSalesTaxRate,
            serviceSalesTaxRate: prev.serviceSalesTaxRate,
            rushOrder: { ...prev.rushOrder, isRush: false },
          }));
          setCurrentStep(isDesktopScreen ? POS_NEW_ORDER_STEPS.ADD_ITEM : POS_NEW_ORDER_STEPS.ORDER_CART);
        }
      },
    },
  ];

  useEffect(() => {
    if (userData) {
      setEnableStripeCharge(
        userData?.organization?.connect_acc_charges_enabled
      );
    }
  }, [userData?.organization]);

  return (
    <div className="flex flex-col w-full sm:w-auto min-w-[330px] justify-between font-normal font-questrial lg:p-5 text-base bg-[#F8F8F8] lg:bg-white rounded-lg h-full sm:mx-auto">
      <div className="flex flex-col gap-4 flex-grow overflow-auto">
        <CartBadge
          cart={cart}
          setCart={setCart}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />

        <div className="flex flex-col justify-between gap-1">
          {cart.attached_customer && (
            <Link
              to={CUSTOMER_DETAILS.replace(":id", cart?.attached_customer?.id)}
              className="flex gap-2 justify-end lg:justify-start"
            >
              <span className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
                Customer:
              </span>
              <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug hover:text-[#4C8C4A] w-fit">
                {cart?.attached_customer?.name}
              </span>
            </Link>
          )}
          <MediaQuery minWidth={1024}>
            <div className="flex justify-between gap-4 items-center">
              <p className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                Cart (
                {cart?.serviceLineItems?.reduce(
                  (acc, item) => acc + item.quantity,
                  0
                ) || 0}{" "}
                items)
              </p>
              <MenuOptionButton data={menuButtonConfig} />
            </div>
          </MediaQuery>
        </div>

        <div className="flex flex-col border rounded-lg overflow-y-auto">
          <div className="flex flex-row justify-between px-3 py-5 text-[#272523] text-xs">
            <span>Item detail</span>
            <span>Price</span>
          </div>
          {serviceLineItems?.length > 0 || productLineItems?.length > 0 ? (
            <>
              {serviceLineItems?.map((serviceLineItem, lineItemIndex) => (
                <ServiceLineItem
                  item={serviceLineItem}
                  index={lineItemIndex}
                  currentStep={currentStep}
                  onUpdateService={onUpdateService}
                  editingId={editingId}
                  setEditingId={setEditingId}
                />
              ))}

              {productLineItems?.length > 0 && (
                <>
                  <Divider />
                  <div className="my-2">
                    {productLineItems?.map((productLineItem, lineItemIndex) => (
                      <ProductLineItem
                        item={productLineItem}
                        index={lineItemIndex}
                        currentStep={currentStep}
                        onUpdateProduct={onUpdateProduct}
                        editingId={editingId}
                        setEditingId={setEditingId}
                      />
                    ))}
                  </div>
                </>
              )}

              <Divider />
              <div className="flex gap-4 justify-between items-center mx-4 my-5 text-[#272523]">
                <div className="flex flex-row gap-2 items-center">
                  <p className="mr-3">Rush Order</p>
                  <SwitchIosStyle
                    checked={cart?.rushOrder?.isRush}
                    disabled={
                      isDesktopScreen &&
                      currentStep != POS_NEW_ORDER_STEPS.ADD_ITEM
                    }
                    onChange={(e) =>
                      setCart((prev) => ({
                        ...prev,
                        rushOrder: {
                          ...prev.rushOrder,
                          isRush: e.target.checked,
                        },
                      }))
                    }
                  />
                </div>
                <span>${parseFloat(cart?.rushOrder?.price).toFixed(2)}</span>
              </div>

              {!!cart?.discount && (
                <>
                  <Divider />
                  <div className="mx-4 my-5">
                    <h3 className="text-xs text-[#5D5C5A] mb-1">Discount</h3>
                    <div className="flex gap-4 justify-between items-center text-[#272523]">
                      <p>{cart?.discount?.name}</p>
                      <div className="flex gap-2 items-center">
                        <span>- ${cart.totalDiscount.toFixed(2)}</span>
                        <IconButton
                          size="small"
                          onClick={() => setCart({ ...cart, discount: null })}
                          disabled={
                            isDesktopScreen &&
                            currentStep != POS_NEW_ORDER_STEPS.ADD_ITEM
                          }
                        >
                          <TrashIcon2 fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <Divider />
              <p className="px-3 py-5 text-center text-[#272523]">
                Nothing added yet
              </p>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-none flex-col gap-8 justify-end">
        <PriceDetail cart={cart} />
        <div className="flex justify-between w-full">
          <DropdownButton
            button={{
              text: "Check Out",
              returnedValue: {
                cartType: CART_TYPES.checkout,
              },
            }}
            menuItems={[
              {
                text: "Check out",
                returnedValue: {
                  cartType: CART_TYPES.checkout,
                },
              },
              {
                text: "Create a quote",
                returnedValue: {
                  cartType: CART_TYPES.quote,
                },
                disabled: !enableStripeCharge,
              },
            ]}
            onClick={(returnedValue) => handleDropdownClick(returnedValue)}
            disabled={
              (!cart?.serviceLineItems?.length &&
                !cart?.productLineItems?.length) ||
              (isDesktopScreen && currentStep !== POS_NEW_ORDER_STEPS.ADD_ITEM)
            }
          />
          <MediaQuery maxWidth={1023}>
            <MenuOptionButton data={menuButtonConfig} />
          </MediaQuery>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
