import React from "react";
import { Typography } from "@mui/material";
import { NotificationIcon } from "../icons";
import theme from "../../theme";

const ComponentHeader = ({ name }) => {
  return (
    <div className="flex flex-row justify-between items-center w-full my-5">
      <div>
        <Typography style={theme.typography.title}>{name}</Typography>
      </div>
      <div className="flex flex-row gap-5 items-center">
        <NotificationIcon />
      </div>
    </div>
  );
};

export default ComponentHeader;
