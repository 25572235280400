import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/authSlice";
import { Paper, InputBase, IconButton } from "@mui/material";
import { Add, AddCircleRounded, EmojiEmotions } from "@mui/icons-material";
import { useActionCable } from "../../customHooks/useActionCable";
import { getRequest, postRequest } from "../../api";
import OutgoingMessage from "../OutgoingMessage";
import IncomingMessage from "../IncomingMessage";
import { FlagIcon, MoreIcon } from "../../common/icons";
import { categorizeMessages } from "../../utils";
import  MessageSendIcon from "../../images/MessageSendIcon.svg"
import { STORES, ORDERS } from "../../constants/BackendRoutes";

function Detail({ customer, setCustomer }) {
  const { id } = useParams();
  const [newMessages] = useActionCable("ChatSupportChannel", { order_id: id });
  const userData = useSelector(selectCurrentUser);

  const [content, setContent] = useState("");
  const [messages, setMessages] = useState([]);
  const [prevMessages, setPrevMessages] = useState([]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const userId = userData?.id;

  const orderURL = `${STORES}/${userData?.stores[0].id}${ORDERS}/${id}`;
  const orderMessagesURL = `${orderURL}/messages`;

  useEffect(() => {
    if (id) {
      fetchCustomer();
    }
  }, [id]);

  useEffect(() => {
    if (userData) {
      fetchMessages();
    }
  }, [id, userData]);

  useEffect(() => {
    if (newMessages) {
      updateMessages(newMessages);
    }
  }, [newMessages]);

  const fetchCustomer = async () => {
    try {
      const data = await getRequest(`${orderURL}/customer`);
      setCustomer(data);
    } catch (error) {
      console.log("Error fetching customer:", error);
    }
  };

  const fetchMessages = async () => {
    try {
      const data = await getRequest(orderMessagesURL);
      const categorizedRecords = categorizeMessages(data);
      setMessages(categorizedRecords);
      setPrevMessages(
        Object.keys(categorizedRecords)
          .filter((key) => key !== "Today" && key !== "Yesterday")
          .sort((a, b) => new Date(a) - new Date(b))
      );
    } catch (error) {
      console.log("Error fetching messages:", error);
    }
  };

  const updateMessages = (newMessages) => {
    setMessages((prevMessages) => {
      const todaysMessages = prevMessages["Today"] || [];
      todaysMessages.push(newMessages);

      return {
        ...prevMessages,
        Today: todaysMessages,
      };
    });
  };

  const sendMessage = async () => {
    if (content.trim()) {
      try {
        await postRequest(orderMessagesURL, {
          message: {
            content,
            order_id: id,
            store_id: userData.stores[0].id,
            user_id: userId,
          },
        });
        setContent("");
      } catch (error) {
        console.log("Error sending message:", error);
      }
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col gap-5 mt-2 overflow-x-hidden sm:overflow-auto">
        {(Array.isArray(prevMessages) && prevMessages).map(
          (date) =>
            messages[date] &&
            messages[date].length > 0 && (
              <div key={date}>
                <h3 className="text-[#C9C8C8] text-sm sm:text-base mb-4 sm:mb-0 text-center">{date}</h3>
                {(messages[date] || []).map((message, index) => (
                  <div key={message.id}>
                    {message?.user_id === userId ? (
                      <OutgoingMessage
                        text={message.content}
                        time={message.created_at}
                      />
                    ) : (
                      <IncomingMessage
                        text={message.content}
                        time={message.created_at}
                      />
                    )}
                  </div>
                ))}
              </div>
            )
        )}

        {messages["Yesterday"] && messages["Yesterday"].length > 0 && (
          <div>
            <h3 className="text-[#939291] text-center text-xs mb-9">
              Yesterday
            </h3>
            {messages["Yesterday"].map((message, index) => (
              <div key={`${message.id}-${index}`}>
                {message?.user_id === userId ? (
                  <OutgoingMessage
                    text={message.content}
                    time={message.created_at}
                  />
                ) : (
                  <IncomingMessage
                    text={message.content}
                    time={message.created_at}
                  />
                )}
              </div>
            ))}
          </div>
        )}

        {messages["Today"] && messages["Today"].length > 0 && (
          <div>
            <h3 className="text-[#939291] text-center text-xs mb-9">Today</h3>
            {messages["Today"].map((message, index) => (
              <div key={`${message.id}-${index}`}>
                {message?.user_id === userId ? (
                  <OutgoingMessage
                    text={message.content}
                    time={message.created_at}
                  />
                ) : (
                  <IncomingMessage
                    text={message.content}
                    time={message.created_at}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div onKeyDown={handleKeyPress} className="flex gap-1 mt-auto p-4">
        <Paper className="w-4/5 flex items-center p-1 flex-grow">
          <InputBase
            value={content}
            className="ml-1 flex-1"
            onChange={(e) => {
              setContent(e.target.value);
            }}
            placeholder="Type your message here..."
          />
          {/* <IconButton
            type="button"
            style={{ color: "#4C8C4A" }}
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <EmojiEmotions />
          </IconButton>

          <IconButton
            type="button"
            style={{ color: "#4C8C4A" }}
            sx={{ p: "10px" }}
            aria-label="directions"
          >
            <AddCircleRounded />
          </IconButton> */}
        </Paper>

        <button
          className="rounded-[8px] w-1/5"
          onClick={sendMessage}
          disabled={!content.trim()}
        >
          <div className="bg-[#4C8C4A] inline-flex items-center justify-center gap-[8px] px-[16px] py-[10px] relative flex-[0_0_auto] rounded-[8px] overflow-hidden border border-solid border-gray-300 shadow-shadow-xs">
            <img src={MessageSendIcon} alt="+" className="w-5 h-5" />
            <div className="hidden sm:block relative w-fit mt-[-1.00px] font-body font-[number:var(--body-font-weight)] text-white text-[length:var(--body-font-size)] tracking-[var(--body-letter-spacing)] leading-[var(--body-line-height)] whitespace-nowrap [font-style:var(--body-font-style)]">
              Send message
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default Detail;
