import React from "react";
import { Typography } from "@mui/material";
import theme from "../../theme";

const OrderInfoCard = ({
  LeftIcon = <></>,
  bgColor = "",
  headerText,
  mHeaderText,
  count,
  info,
  ...props
}) => {
  return (
    <div
      className={`flex flex-row ${bgColor} md:p-4 py-2 px-3 md:w-44 items-center rounded-lg gap-2 text-nowrap`}
      {...props}
    >
      <div className="md:block hidden">{LeftIcon}</div>
      <div className="flex flex-col justify-center items-center">
        <Typography className="hidden md:block" style={theme.typography.info}>
          {headerText}
        </Typography>
        <Typography className="md:hidden block" style={theme.typography.info}>
          {mHeaderText}
        </Typography>
        <div className="flex flex-row gap-1 items-center">
          <Typography style={theme.typography.subTitle}>{count}</Typography>
          <Typography className="md:block hidden" style={theme.typography.body}>
            {info}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default OrderInfoCard;
