import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Page from "./routes";
import { emptyToast } from "./redux/toastSlice";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import LoadingOverlay from "./components/LoadingOverlay";

function App() {
  const dispatch = useDispatch();

  const { showToast, toastMessage } = useSelector((state) => state.toast);
  const { isLoading } = useSelector((state) => state.loading);

  useEffect(() => {
    if (!showToast) return;
    toast(toastMessage);
    dispatch(emptyToast());
  }, [toastMessage]);

  return (
    <ThemeProvider theme={theme}>
      <Page />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        progressClassName="blue-progress-bar"
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnHover={false}
        closeOnClick
        draggable
        toastClassName="toast-container"
      />
      <LoadingOverlay />
    </ThemeProvider>
  );
}

export default App;
