import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import Lightning from "../../images/lightning.svg";
import {
  Paper,
  Box,
  Typography,
  LinearProgress,
  Modal,
  Divider,
} from "@mui/material";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Checkbox,
} from "@mui/material";
import { KeyboardArrowDown, Add, CircleSharp } from "@mui/icons-material";
import {
  ORDERS,
  ORDER_LINE_ITEMS,
  RAILS_APP_API_URL,
  STORES,
  STORE_TOKEN,
  UPDATE_IMAGE,
} from "../../constants/BackendRoutes";
import {
  getRequest,
  updateRequest,
  postRequest,
  deleteRequest,
} from "../../api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import QRCodeModal from "./QRCodeModal";
import {
  calculateDaysRemaining,
  convertDateFormat,
  filterLineItems,
  formatTo24Hour,
  generateRandomString,
  groupServicesByType,
} from "../../utils";
import { selectCurrentUser } from "../../redux/authSlice";
import GenerateQRCodeModal from "./GenerateQRCodeModal";
import LabelModal from "./LabelModal";
import EntityPage from "../EntityPage";
import { ORDERS_OVERVIEW } from "../../constants/FrontendRoutes";
import Capsule from "../Capsule";
import {
  ButtonIcon,
  CloudUploadIcon,
  GreenTick,
  QRIcon,
} from "../../common/icons";
import OrderDetailsFooter from "./OrderDetailsFooter";
import theme from "../../theme";
import ImageSliderModal from "./ImageSliderModal";
import DropdownButton from "../Form/DropdownButton";
import { addToast } from "../../redux/toastSlice";
import { ORDER_STATUS_ENUM } from "../../constants";
import { OrderStatus, OrderStatusMap } from "../../constants/OrderStatus";
import { fetchUserData } from "../../redux/authActions";
import { resizeFile } from "../../utils/imageResizer";
import { setLoading } from "../../redux/loadingSlice";
import QrScanner from "./QrScanner";

const OrderDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const inputRef = useRef(null);
  const [order, setOrder] = useState({});
  const [user, setUser] = useState({});
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openQr, setQrOpen] = useState(false);

  const [openQrCodeModal, setOpenQrCodeModal] = useState(false);
  const [token, setToken] = useState("");
  const [isGenerateQRCode, setIsGenerateQRCode] = useState(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [showGreenTick, setShowGreenTick] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    data: null,
    status: "",
    name: "",
    itemId: 0,
  });

  const handleRowClick = (index) => {
    setOpenRowIndex(openRowIndex === index ? null : index);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleQrOpen = () => setQrOpen(true);
  const handleQrClose = () => setQrOpen(false);
  const handleLabelModal = (value) => setIsLabelModalOpen(value);
  const handleImageModal = (
    isOpen,
    data,
    status = "",
    name = "",
    itemId = 0
  ) => {
    setImageModal({
      isOpen,
      data,
      status,
      name,
      itemId,
    });
  };

  const tableHeaderclasses = "font-questrial text-xs text-gray-800";

  const fetchOrder = async () => {
    try {
      const fields =
        "order_line_item.id,order_line_item.unique_identifier,order_line_item.details,order_line_item.line_item_type,order_line_item.before_images_data,order_line_item.after_images_data";
      const data = await getRequest(
        `${STORES}/${currentUser?.stores?.[0]?.id}${ORDERS}/${id}`,
        {},
        `user.addresses,order_line_items,shipping_labels&fields=${fields}`
      );
      setOrder(data);
      setDaysRemaining(calculateDaysRemaining(data?.estimated_completion));
      setUser(data?.user);
      setOrderLineItems(data?.order_line_items);
    } catch (error) {
      dispatch(addToast(error.message || "Something went Wrong!"));
      console.error("Error fetching order:", error);
    }
  };

  useEffect(() => {
    if (token) {
      postToken();
    }
  }, [token]);

  const postToken = async () => {
    const response = await postRequest(`${STORE_TOKEN}`, {
      user_id: currentUser?.id,
      token,
    });

    if (response.data.status === "success") {
      handleOpen();
    }
  };

  const handleScan = async () => {
    setToken(generateRandomString(20));
    handleOpen();
  };

  const handleGenerateQRCode = () => {
    setOpenQrCodeModal(true);
    handleClose();
  };

  const handleScanQRCode = () => {
    handleQrOpen();
  };

  const handleCloseQrCodeModal = () => {
    setOpenQrCodeModal(false);
    setIsGenerateQRCode(false);
  };

  const calculateProgress = () => {
    if (ORDER_STATUS_ENUM[order.status] === 0) return 0;
    const progressPercentage = parseInt(
      (ORDER_STATUS_ENUM[order.status] / 5) * 100
    );
    if (progressPercentage < 0 || progressPercentage > 100) return 0;
    return progressPercentage;
  };

  function UploadInput({ repairLineItemId, isBefore, isMobile }) {
    const inputId = `contained-button-file-${repairLineItemId}-${
      isBefore ? "before" : "after"
    }`;
    const imageType = isBefore
      ? "order_line_item[before_images]"
      : "order_line_item[after_images]";

    const uploadPicture = async (event) => {
      try {
        dispatch(setLoading(true));
        const files = event.target.files;
        const formData = new FormData();
        for (const file of files) {
          const resizedImage = await resizeFile(file);
          formData.append(`${imageType}[]`, resizedImage);
        }
        await updateRequest(
          `${STORES}/${currentUser?.stores?.[0]?.id}${ORDERS}/${id}/${ORDER_LINE_ITEMS}/${repairLineItemId}${UPDATE_IMAGE}`,
          formData,
          { "Content-Type": "multipart/form-data" }
        );
        await fetchOrder();
        dispatch(addToast("Images Uploaded Successfully!"));
      } catch (error) {
        dispatch(
          addToast(
            error.message || "Something went Wrong! \nImage is not uploaded!"
          )
        );
        console.error("Error uploading images:", error);
      } finally {
        dispatch(setLoading(false));
      }
    };

    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <label htmlFor={inputId}>
          <input
            accept="image/*"
            id={inputId}
            multiple
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            onChange={uploadPicture}
          />
          <UploadButton
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            sx={{ maxWidth: "140px", maxHeight: isMobile && "40px" }}
          >
            {isMobile ? (
              <div>Upload</div>
            ) : (
              <p className="text-[#4C8C4A] font-normal">
                Browse file
                <span className=" text-gray-500"> or drag & drop</span>
              </p>
            )}
          </UploadButton>
        </label>
      </Box>
    );
  }

  const handleImageDelete = async (itemId, imageType, imageId) => {
    deleteRequest(
      `/stores/${currentUser?.stores?.[0]?.id}/orders/${id}/order_line_items/${itemId}/delete_images`,
      {
        data: { [`${imageType}_image_id`]: imageId },
      }
    );
    fetchOrder();
  };

  const handleClick = (_, trackingNumber) => {
    const selectedIndex = selected.indexOf(trackingNumber);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, trackingNumber);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex, 1)
      );
    }

    setSelected(newSelected);
  };

  function calculateTotalSum(object) {
    if (object.price) return object.price;

    const optionsSum =
      (object.options &&
        object.options.reduce((total, option) => total + option.price, 0)) ||
      0;
    const servicesSum =
      (object.services &&
        object.services.reduce((total, service) => total + service.price, 0)) ||
      0;

    return optionsSum + servicesSum;
  }

  const handleQRCode = (value) => {
    if (value === "scanQR") {
      handleScanQRCode();
    } else if (value === "generateQR") {
      handleGenerateQRCode();
    } else if (value === "scanBarcode") {
      handleScan();
    }
  };

  const formatDate = (dateString) => {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Get the month, day, and year
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    // Return the formatted date string
    return `${month}/${day}/${year}`;
  };

  const handleTrackingId = () => {
    if (!order.shipping_labels?.length) {
      return "-------";
    }

    const trackingNumbers = order.shipping_labels
      .map((shipment) => ({
        leg: `Leg #${shipment.shipping_leg.split("_")[1]}`,
        number: shipment.tracking_number,
        legNumber: parseInt(shipment.shipping_leg.split("_")[1], 10),
      }))
      .sort((a, b) => a.legNumber - b.legNumber);

    return (
      <>
        <div className="hidden md:block">
          {trackingNumbers.map((item, index) => (
            <div key={index}>
              {item.leg}:{" "}
              <a
                href={`https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${item.number}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {item.number}
              </a>
            </div>
          ))}
        </div>
        <div className="md:hidden font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-[min-content,1fr] gap-[12px]">
          <div className="text-[#939291] text-nowrap row-span-2">
            Tracking #
          </div>
          {trackingNumbers.map((item, index) => (
            <div key={index}>
              {item.leg}:{" "}
              <a
                href={`https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${item.number}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {item.number}
              </a>
            </div>
          ))}
        </div>
      </>
    );
  };

  const organizeServicesData = (value) => {
    return groupServicesByType(value);
  };

  const isSelected = (trackingNumber) =>
    selected.indexOf(trackingNumber) !== -1;

  useEffect(() => {
    dispatch(fetchUserData(currentUser?.id));
    fetchOrder();
  }, []);

  return (
    <EntityPage title="Order Details" breadcrumbs={{ href: ORDERS_OVERVIEW }}>
      <Box className="flex flex-col items-center gap-7">
        <Modal open={openQr} onClose={handleQrClose}>
          <QrScanner handleClose={handleQrClose} />
        </Modal>
        <Modal open={open} onClose={handleClose}>
          <QRCodeModal token={token} handleClose={handleClose} />
        </Modal>
        <Modal open={openQrCodeModal} onClose={handleCloseQrCodeModal}>
          <GenerateQRCodeModal
            lineItems={filterLineItems(orderLineItems, "service")}
            dueDate={order.shipping_date}
            handleClose={handleCloseQrCodeModal}
            isGenerateQRCode={isGenerateQRCode}
            user={user}
            order={order}
            orderId={id}
          />
        </Modal>
        <Modal open={isLabelModalOpen} onClose={() => handleLabelModal(false)}>
          <LabelModal
            isRepairedOrder={
              [OrderStatus.REPAIRED, OrderStatus.DELIVERED].includes(
                order.status
              )
                ? true
                : false
            }
            handleClose={() => handleLabelModal(false)}
            customer={user}
            orderId={id}
          />
        </Modal>
        <Modal
          open={imageModal.isOpen}
          onClose={() => handleImageModal(false, null)}
        >
          <ImageSliderModal
            handleClose={() => handleImageModal(false, null)}
            orderId={id}
            modalData={imageModal}
            handleDelete={handleImageDelete}
            currentUser={currentUser}
            handleUpdateImage={fetchOrder}
          />
        </Modal>
        <div className="lg:hidden max-w-[1550px] w-full items-center justify-between">
          <div className="flex-row w-full justify-between items-center">
            <div className="flex flex-col gap-[8px]">
              <div className="flex gap-2">
                {order && order.rush_fee !== 0 && (
                  <>
                    <img
                      alt="LightningLogo"
                      src={Lightning}
                      className="w-5 h-5 mr-2 mt-[2px]"
                    />
                  </>
                )}
                <Capsule
                  textColor={`${
                    OrderStatusMap[order?.status] === "Delayed"
                      ? "text-[#CA8A68]"
                      : "text-[#5A8A6E]"
                  }`}
                  text={OrderStatusMap[order?.status]}
                  capsuleColor={` ${
                    OrderStatusMap[order?.status] === "Delayed"
                      ? "bg-[#F8E2C9]"
                      : "bg-[#D8EDE0]"
                  }`}
                  RightIcon={<KeyboardArrowDown />}
                />
                {order.placement_type === "online" && (
                  <Capsule
                    textColor="text-[#4968A6]"
                    capsuleColor="bg-[#D8E1F8]"
                    text="Online order"
                    LeftIcon={
                      <CircleSharp
                        style={{ fontSize: "0.5rem" }}
                        className="text-[#4968A6]"
                      />
                    }
                  />
                )}
              </div>
              <div className="flex items-center justify-between">
                <Typography variant="text-[#272523] font-montserrat text-lg font-semibold">
                  Order #{order.id}
                </Typography>
                <div className="flex flex-row gap-2">
                  <div className="flex gap-5">
                    <button
                      disabled={
                        !currentUser?.organization?.shipstation_token?.trim()
                      }
                      className="rounded"
                      onClick={() => handleLabelModal(true)}
                    >
                      <div className="inline-flex items-center justify-center gap-[8px] p-[8px] relative bg-white rounded-[8px] overflow-hidden border border-solid border-gray-300 shadow-shadow-xs">
                        <Add className="w-5 h-5" />
                        <div className="relative w-fit mt-[-1.00px] font-body text-gray-700 font-['Questrial'] text-[16px] font-normal whitespace-nowrap">
                          Create label
                        </div>
                      </div>
                    </button>
                    <DropdownButton
                      button={{
                        text: <QRIcon />,
                        returnedValue: "scanQR",
                      }}
                      menuItems={[
                        {
                          text: "Scan QR code",
                          returnedValue: "scanQR",
                        },
                        {
                          text: "Generate QR code",
                          returnedValue: "generateQR",
                        },
                      ]}
                      onClick={handleQRCode}
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-row items-center h-[17px] gap-2">
                <LinearProgress
                  className="flex-grow h-[5px] min-h-[5px] rounded-[100px]"
                  variant="determinate"
                  color="success"
                  value={calculateProgress()}
                />
                <div className="text-[12px] text-[#5D5C5A] font-normal font-['Questrial']">
                  {calculateProgress()}%
                </div>
              </div>
            </div>
          </div>
          <Box className="w-full">
            <Paper className="w-full rounded-t-lg-[10px] mt-10 mb-[2px]">
              <Table aria-labelledby="tableTitle">
                <TableBody>
                  {orderLineItems?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const {
                      name,
                      category,
                      categoryType,
                      description,
                      services,
                      options,
                    } = row.details;
                    const isProduct = row.line_item_type === "product";
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <React.Fragment key={row.trackingNumber}>
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.trackingNumber}
                          selected={isItemSelected}
                          style={{
                            border: "none",
                          }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            className="max-w-64"
                          >
                            <Collapse
                              in={openRowIndex === index}
                              timeout={500}
                              unmountOnExit
                            >
                              <div className="flex flex-row w-full">
                                <div className="flex flex-grow flex-col gap-2">
                                  <div
                                    onClick={() => handleRowClick(index)}
                                    className="flex flex-row items-center"
                                  >
                                    <KeyboardArrowUpIcon className="max-h-[16px] text-[#4C8C4A] max-w-[16px] mr-[8px] opacity-75" />
                                    <div>
                                      <p className="font-questrial text-[12px] text-[#4C8C4A]">
                                        {isProduct ? name : `${category}`}
                                      </p>
                                      <p className="font-questrial text-base text-[#4C8C4A]">
                                        {categoryType} -{" "}
                                        {Object.entries(
                                          organizeServicesData(services)
                                        )
                                          .map(([type]) => type)
                                          .join(", ")}
                                      </p>
                                    </div>
                                  </div>
                                  <Divider className="w-full flex-grow mx-10" />
                                  <div className="flex flex-col gap-3">
                                    <div className="flex flex-col gap-3">
                                      {isProduct ? (
                                        <div className="pl-[36px]">
                                          <p className="font-questrial text-xs text-[#939291]">
                                            Product Name
                                          </p>
                                          <p className="font-questrial text-base">
                                            {name}
                                          </p>
                                        </div>
                                      ) : (
                                        <>
                                          <div className="flex flex-col gap-2">
                                            {Object.entries(
                                              organizeServicesData(services)
                                            ).map(([type, names]) => (
                                              <div key={type}>
                                                <p className="pl-[36px] font-questrial font-normal text-[16px] text-[#939291]">
                                                  {type}
                                                </p>
                                                {names.length > 0 ? (
                                                  names.map((name, index) => (
                                                    <p
                                                      key={index}
                                                      className="pl-[36px] font-questrial font-normal text-[16px] text-[#272523]"
                                                    >
                                                      {name}
                                                    </p>
                                                  ))
                                                ) : (
                                                  <p className="pl-[36px] font-questrial text-base">
                                                    ----
                                                  </p>
                                                )}
                                                <Divider className="pt-2" />
                                              </div>
                                            ))}
                                          </div>
                                          <div className="pl-[36px]">
                                            <p className="font-questrial text-[16px] text-[#939291]">
                                              Option(s)
                                            </p>
                                            {options?.length ? (
                                              options.map(({ name }, index) => (
                                                <p
                                                  key={index}
                                                  className="font-questrial text-base"
                                                >
                                                  {name}
                                                </p>
                                              ))
                                            ) : (
                                              <p className="font-questrial text-base">
                                                ---
                                              </p>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <Divider />
                                    <div className="pl-[36px] flex flex-col gap-1">
                                      <p className="font-questrial text-[16px] text-[#939291]">
                                        Description
                                      </p>
                                      <p className="font-questrial text-base">
                                        {description === ""
                                          ? "No description provided"
                                          : description}
                                      </p>
                                    </div>
                                    <Divider />
                                    <div className="font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-2 gap-[12px]">
                                      <div className="text-[#939291]">
                                        Order created date
                                      </div>
                                      <div className="text-[#272523]">
                                        {convertDateFormat(order.created_at)}
                                      </div>
                                      {order.placement_type === "online" && (
                                        <div className="text-[#939291]">
                                          Shipping date
                                        </div>
                                      )}
                                      {order.placement_type === "online" && (
                                        <div className="text-[#272523]">
                                          {convertDateFormat(
                                            order.shipping_date
                                          )}
                                        </div>
                                      )}
                                      <div className="text-[#939291]">
                                        In progress date
                                      </div>
                                      <div className="text-[#272523]">
                                        {convertDateFormat(
                                          order.in_progress_date
                                        )}
                                      </div>
                                    </div>
                                    <Divider />
                                    <div className="font-['Questrial'] text-[16px] text-[#939291] font-normal flex flex-row pl-[36px] justify-between mr-10">
                                      <div className="flex flex-col gap-2">
                                        <div>Before image(s)</div>
                                        {row?.before_images_data?.length >
                                          0 && (
                                          <div
                                            className="relative w-[112px] h-[59px] cursor-pointer"
                                            onClick={() =>
                                              handleImageModal(
                                                true,
                                                row.before_images_data,
                                                "before",
                                                isProduct ? name : categoryType,
                                                row.id
                                              )
                                            }
                                          >
                                            <img
                                              className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                              src={`${RAILS_APP_API_URL}${row.before_images_data[0].url}`}
                                              alt="Before"
                                            />
                                            <div
                                              className={`absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold ${
                                                row?.before_images_data
                                                  ?.length > 0 &&
                                                "bg-black bg-opacity-50 rounded-md"
                                              }`}
                                            >
                                              {row?.before_images_data?.length -
                                                1 !==
                                                0 && (
                                                <div className="flex text-[12px] text-center flex-col">
                                                  <div className="font-['Montserrat'] font-[700]">
                                                    +{" "}
                                                    {row?.before_images_data
                                                      ?.length - 1}{" "}
                                                    more
                                                  </div>
                                                  <div className="font-['Questrial'] font-normal">
                                                    See all
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        <div>
                                          <UploadInput
                                            isBefore={true}
                                            repairLineItemId={row.id}
                                            isMobile={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="flex flex-col gap-2">
                                        <div>After image(s)</div>
                                        {row?.after_images_data?.length > 0 && (
                                          <div
                                            className="relative w-[112px] h-[59px] cursor-pointer"
                                            onClick={() =>
                                              handleImageModal(
                                                true,
                                                row.after_images_data,
                                                "after",
                                                isProduct ? name : categoryType,
                                                row.id
                                              )
                                            }
                                          >
                                            <img
                                              className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                              src={`${RAILS_APP_API_URL}${row.after_images_data[0].url}`}
                                              alt="Before"
                                            />
                                            <div
                                              className={`absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold ${
                                                row?.after_images_data?.length >
                                                  1 &&
                                                "bg-black bg-opacity-50 rounded-md"
                                              }`}
                                            >
                                              <div className="flex flex-col">
                                                {row?.after_images_data
                                                  ?.length -
                                                  1 !==
                                                  0 && (
                                                  <div className="flex text-[12px] text-center flex-col">
                                                    <div className="font-['Montserrat'] font-[700]">
                                                      +{" "}
                                                      {row?.after_images_data
                                                        ?.length - 1}{" "}
                                                      more
                                                    </div>
                                                    <div className="font-['Questrial'] font-normal">
                                                      See all
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <div>
                                          <UploadInput
                                            isBefore={false}
                                            repairLineItemId={row.id}
                                            isMobile={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <Divider />
                                    <div className="font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-2 gap-[12px]">
                                      <div className="text-[#939291]">
                                        Price
                                      </div>
                                      <div className="font-['Montserrat'] text-[18px] font-[600] text-[#272523]">
                                        ${calculateTotalSum(row.details)}
                                      </div>
                                    </div>
                                    {order.placement_type === "online" && (
                                      <Divider />
                                    )}
                                    {order.placement_type === "online" &&
                                      handleTrackingId()}
                                  </div>
                                  {order.placement_type === "online" && (
                                    <div className="font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-2 gap-[12px]">
                                      <div className="self-center text-[#939291]">
                                        Shipped
                                      </div>
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        onClick={(event) =>
                                          handleClick(event, row.id)
                                        }
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Collapse>
                            <Collapse
                              in={openRowIndex !== index}
                              timeout={500}
                              unmountOnExit
                            >
                              <div
                                onClick={() => handleRowClick(index)}
                                className="flex flex-row items-center"
                              >
                                <KeyboardArrowDownIcon className="max-h-[16px] max-w-[16px] mr-[8px] opacity-75" />
                                <div className="flex flex-col gap-2">
                                  <p className="font-questrial text-[12px] text-[#5D5C5A]">
                                    {isProduct ? name : `${category}`}
                                  </p>
                                  <p>
                                    {categoryType} -{" "}
                                    {Object.entries(
                                      organizeServicesData(services)
                                    )
                                      .map(([type]) => type)
                                      .join(", ")}
                                  </p>
                                </div>
                              </div>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Box>
        </div>
        <Paper className="lg:block hidden max-w-[1550px] w-full p-5 items-center justify-between">
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                {order && order.rush_fee !== 0 && (
                  <>
                    <img
                      alt="LightningLogo"
                      src={Lightning}
                      className="w-5 h-5 mr-2 mt-[2px]"
                    />
                  </>
                )}
                <Typography variant="text-[#272523] font-montserrat text-lg font-semibold">
                  Order #{order.id}
                </Typography>
                <Capsule
                  textColor={` ${
                    OrderStatusMap[order?.status] === "Delayed"
                      ? "text-[#CA8A68]"
                      : "text-[#5A8A6E]"
                  }`}
                  text={OrderStatusMap[order?.status]}
                  capsuleColor={` ${
                    OrderStatusMap[order?.status] === "Delayed"
                      ? "bg-[#F8E2C9]"
                      : "bg-[#D8EDE0]"
                  }`}
                  RightIcon={<KeyboardArrowDown className="mt-[2px]" />}
                />
                {order.placement_type === "online" && (
                  <Capsule
                    textColor="text-[#4968A6]"
                    capsuleColor="bg-[#D8E1F8]"
                    text="Online order"
                    LeftIcon={
                      <CircleSharp
                        style={{ fontSize: "0.5rem" }}
                        className="text-[#4968A6]"
                      />
                    }
                  />
                )}
              </div>
              <div className="flex flex-row gap-2">
                <Typography className="">Progress of Order</Typography>
                <div className="flex items-center lg:w-[15rem]">
                  <div className="w-full mr-1">
                    <LinearProgress
                      className="rounded-[20%]"
                      variant="determinate"
                      color="success"
                      value={calculateProgress()}
                    />
                  </div>
                  <Typography>{calculateProgress()}%</Typography>
                </div>
                <div className="w-[1px] mx-3 mt-1 h-10 bg-[#DFDFDF] text-lg"></div>
                <div className="flex flex-col items-start justify-start w-[40%]">
                  <Typography className="text-[#939291]">
                    Est. completion date:
                  </Typography>
                  {order.estimated_completion && (
                    <div className="flex">
                      <Typography>
                        <p
                          className={`${
                            daysRemaining < 7 && "text-red-600"
                          } mr-3`}
                        >
                          {formatDate(order.estimated_completion)}
                        </p>
                      </Typography>
                      {daysRemaining !== null && daysRemaining < 7 && (
                        <Typography className="text-red-600 mx-auto">
                          Due in {daysRemaining} day
                          {daysRemaining !== 1 ? "s" : ""}
                        </Typography>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <div className="flex justify-between gap-2">
                <div className="flex items-center gap-2">
                  <div
                    className={`transition-opacity duration-1000 ${
                      showGreenTick ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    <GreenTick />
                  </div>
                  <Typography color="textSecondary">
                    Last saved on {formatTo24Hour(order.updated_at)}
                  </Typography>
                </div>
              </div>
              <div className="flex gap-5">
                <button
                  disabled={
                    !currentUser?.organization?.shipstation_token?.trim()
                  }
                  className="rounded"
                  onClick={() => handleLabelModal(true)}
                >
                  <div className="inline-flex items-center justify-center gap-[8px] px-[16px] py-[10px] relative bg-white rounded-[8px] overflow-hidden border border-solid border-gray-300 shadow-shadow-xs">
                    <Add className="w-5 h-5" />
                    <div className="relative w-fit mt-[-1.00px] font-body text-gray-700 whitespace-nowrap">
                      Create label
                    </div>
                  </div>
                </button>
                <DropdownButton
                  button={{
                    text: "Scan QR code",
                    returnedValue: "scanQR",
                  }}
                  menuItems={[
                    {
                      text: "Scan QR code",
                      returnedValue: "scanQR",
                    },
                    {
                      text: "Generate QR code",
                      returnedValue: "generateQR",
                    },
                  ]}
                  onClick={handleQRCode}
                />
              </div>
            </div>
          </div>
          <Box className="w-full">
            <Paper className="w-full rounded-t-lg-[10px] mt-10 mb-[2px]">
              <TableContainer>
                <Table className="min-w-[750px]" aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      <TableCell className={tableHeaderclasses}>
                        Order Items
                      </TableCell>
                      <TableCell className={tableHeaderclasses}>
                        Order Created
                      </TableCell>
                      {order.placement_type === "online" && (
                        <TableCell className={tableHeaderclasses}>
                          Shipping Date
                        </TableCell>
                      )}
                      <TableCell className={tableHeaderclasses}>
                        In progress date
                      </TableCell>
                      <TableCell className={tableHeaderclasses}>
                        Before image(s)
                      </TableCell>
                      <TableCell className={tableHeaderclasses}>
                        After image(s)
                      </TableCell>
                      <TableCell className={tableHeaderclasses}>
                        Price
                      </TableCell>
                      {order.placement_type === "online" && (
                        <TableCell className={tableHeaderclasses}>
                          Tracking #
                        </TableCell>
                      )}
                      {order.placement_type === "online" && (
                        <TableCell className={tableHeaderclasses}>
                          Shipped
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderLineItems?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const {
                        name,
                        category,
                        categoryType,
                        description,
                        services,
                        options,
                      } = row.details;
                      const isProduct = row.line_item_type === "product";
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <React.Fragment key={row.trackingNumber}>
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.trackingNumber}
                            selected={isItemSelected}
                            style={{
                              border: "none",
                            }}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              className="max-w-64"
                              onClick={() => handleRowClick(index)}
                            >
                              <Collapse
                                in={openRowIndex === index}
                                timeout={500}
                                unmountOnExit
                              >
                                <div className="flex flex-row items-center">
                                  <KeyboardArrowUpIcon />
                                  <div className="flex flex-col gap-2">
                                    <p className="font-questrial text-xs text-[#5D5C5A]">
                                      Item {index + 1}
                                    </p>
                                    <p className="font-questrial text-base text-[#4C8C4A]">
                                      {isProduct
                                        ? name
                                        : `${category} - 
                                      ${categoryType}`}
                                    </p>
                                    <Divider className="mx-10" />
                                    <div className="flex flex-col gap-2 pl-2">
                                      <div className="flex flex-col gap-1">
                                        {isProduct ? (
                                          <>
                                            <p className="font-questrial text-xs text-[#939291]">
                                              Product Name
                                            </p>
                                            <p className="font-questrial text-base">
                                              {name}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <div>
                                              {Object.entries(
                                                organizeServicesData(services)
                                              ).map(([type, names]) => (
                                                <div key={type}>
                                                  <p className="font-questrial text-xs text-[#939291]">
                                                    {type}
                                                  </p>
                                                  {names.length > 0 ? (
                                                    names.map((name, index) => (
                                                      <p
                                                        key={index}
                                                        className="font-questrial text-base"
                                                      >
                                                        {name}
                                                      </p>
                                                    ))
                                                  ) : (
                                                    <p className="font-questrial text-base">
                                                      ----
                                                    </p>
                                                  )}
                                                </div>
                                              ))}
                                            </div>
                                            <Divider />
                                            <div>
                                              <p className="font-questrial text-xs text-[#939291]">
                                                Option(s)
                                              </p>
                                              {options?.length ? (
                                                options.map(
                                                  ({ name }, index) => (
                                                    <p
                                                      key={index}
                                                      className="font-questrial text-base"
                                                    >
                                                      {name}
                                                    </p>
                                                  )
                                                )
                                              ) : (
                                                <p className="font-questrial text-base">
                                                  ---
                                                </p>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <Divider />
                                      <div className="flex flex-col gap-1">
                                        <p className="font-questrial text-xs text-[#939291]">
                                          Description
                                        </p>
                                        <p className="font-questrial text-base">
                                          {description === ""
                                            ? "No description provided"
                                            : description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                              <Collapse
                                in={openRowIndex !== index}
                                timeout={500}
                                unmountOnExit
                              >
                                <div className="flex flex-row items-center">
                                  <KeyboardArrowDownIcon />
                                  <div className="flex flex-col gap-2">
                                    <p className="font-questrial text-xs text-gray-700">
                                      Item {index + 1}
                                    </p>
                                    <p className="font-questrial text-base text-gray-900">
                                      {isProduct
                                        ? name
                                        : `${category} - 
                                      ${categoryType}`}
                                    </p>
                                  </div>
                                </div>
                              </Collapse>
                            </TableCell>
                            <TableCell>
                              {convertDateFormat(order.created_at)}
                            </TableCell>
                            {order.placement_type === "online" && (
                              <TableCell>
                                {convertDateFormat(order.shipping_date)}
                              </TableCell>
                            )}
                            <TableCell>
                              {convertDateFormat(order.in_progress_date)}
                            </TableCell>
                            <TableCell>
                              <Box className="flex flex-col items-center gap-2">
                                {row?.before_images_data?.length > 0 && (
                                  <div
                                    className="relative w-[131px] h-[68px] cursor-pointer"
                                    onClick={() =>
                                      handleImageModal(
                                        true,
                                        row.before_images_data,
                                        "before",
                                        isProduct ? name : categoryType,
                                        row.id
                                      )
                                    }
                                  >
                                    <img
                                      className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                      src={`${RAILS_APP_API_URL}${row.before_images_data[0].url}`}
                                      alt="Before"
                                    />
                                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold">
                                      {row?.before_images_data?.length - 1 !==
                                        0 && (
                                        <div className="flex flex-col">
                                          <Typography
                                            style={theme.typography.body}
                                          >
                                            +{" "}
                                            {row?.before_images_data?.length -
                                              1}{" "}
                                            more
                                          </Typography>
                                          <Typography className="font-montserrat text-xs text-center">
                                            See all
                                          </Typography>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <UploadInput
                                  isBefore={true}
                                  repairLineItemId={row.id}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box className="flex flex-col items-center gap-2">
                                <Box className="flex flex-col">
                                  {row?.after_images_data?.length > 0 && (
                                    <div
                                      className="relative w-[131px] h-[68px] cursor-pointer"
                                      onClick={() =>
                                        handleImageModal(
                                          true,
                                          row.after_images_data,
                                          "after",
                                          isProduct ? name : categoryType,
                                          row.id
                                        )
                                      }
                                    >
                                      <img
                                        className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                        src={`${RAILS_APP_API_URL}${row.after_images_data[0].url}`}
                                        alt="Before"
                                      />
                                      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold">
                                        <div className="flex flex-col">
                                          {row?.after_images_data?.length -
                                            1 !==
                                            0 && (
                                            <div className="flex flex-col">
                                              <Typography
                                                style={theme.typography.body}
                                              >
                                                +{" "}
                                                {row?.after_images_data
                                                  ?.length - 1}
                                                more
                                              </Typography>
                                              <Typography className="font-montserrat text-xs text-center">
                                                See all
                                              </Typography>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Box>
                                <UploadInput
                                  isBefore={false}
                                  repairLineItemId={row.id}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              $ {calculateTotalSum(row.details)}
                            </TableCell>
                            {order.placement_type === "online" && (
                              <TableCell>{handleTrackingId()}</TableCell>
                            )}
                            {order.placement_type === "online" && (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onClick={(event) =>
                                    handleClick(event, row.id)
                                  }
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Paper>
        <OrderDetailsFooter
          user={user}
          order={order}
          setShowGreenTick={setShowGreenTick}
        />
      </Box>
    </EntityPage>
  );
};

export default OrderDetailsPage;

const UploadButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.background.paper),
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  boxShadow: "none",
  textTransform: "none",
  fontSize: 12,
  padding: "10px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  borderColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
}));
