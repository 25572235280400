import React, { useState } from "react";
import { Scanner, useDevices } from "@yudiel/react-qr-scanner";
import { CrossIcon } from "../../common/icons";

const QrScanner = ({ handleClose }) => {
  const devices = useDevices();

  const [selectedDevice, setSelectedDevice] = useState(null);

  React.useEffect(() => {
    if (devices.length > 0) {
      setSelectedDevice(devices[0].deviceId);
    }
  }, [devices]);

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  const handleScan = (result) => {
    const rawValue = result[0]?.rawValue;
    if (rawValue) {
      window.open(rawValue, "_blank");
    }
  };

  return (
    <div className="absolute top-1/2 left-1/2 md:w-[535px] md:max-w-[535px] max-w-[400px] rounded-lg w-[calc(100vw-100px)] transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg px-4 py-3 text-[16px]">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row w-full justify-between items-center">
          <p className="font-bold font-['Montserrat'] text-[18px] md:text-2xl">
            Scan QR code
          </p>
          <div className="cursor-pointer flex flex-row gap-2">
            <CrossIcon
              className="md:w-auto md:h-auto h-[28px] w-[28px]"
              onClick={handleClose}
            />
          </div>
        </div>
        <div>
          <label htmlFor="device-select">Select Device:</label>
          <select
            id="device-select"
            value={selectedDevice || ""}
            onChange={handleDeviceChange}
          >
            {devices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${device.deviceId}`}
              </option>
            ))}
          </select>
        </div>
        <Scanner
          constraints={{ deviceId: selectedDevice }}
          onScan={handleScan}
          onError={(error) => console.error(error)}
        />
      </div>
    </div>
  );
};

export default QrScanner;
