import { Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../api";
import EntityPage from "../components/EntityPage";
import AutoCompleteInput from "../components/Form/Field/AutoComplete";
import CustomDatePicker from "../components/Form/Field/DatePicker";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";
import { COMMON_STATUS } from "../constants";
import { selectCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";
import { selectAllCategories } from "../redux/storeSlice";

const INITIAL_PRODUCT = {
  itemName: "",
  productId: "",
  dateOfLastOrder: null,
  brand: "",
  color: "",
  description: "",
  supplier: "",
  costPerItem: "",
  price: "",
  stock: "",
  reorderLevel: "",
};

const CreateProduct = () => {
  const [product, setProduct] = useState({ ...INITIAL_PRODUCT });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector(selectCurrentUser);
  const allCategories = useSelector(selectAllCategories);

  const handleInputChange = (name, value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const transformProductInput = (product) => {
    const {
      category,
      status,
      itemName,
      productId,
      brand,
      description,
      color,
      costPerItem,
      price,
      stock,
      reorderLevel,
      supplier,
      dateOfLastOrder,
    } = product;
    return {
      brand,
      color,
      price: parseFloat(price),
      stock: parseInt(stock, 10),
      description,
      supplier,
      category: category?.value,
      status: status?.value,
      name: itemName,
      product_id: productId,
      cost_per_item: parseFloat(costPerItem),
      reorder_level: parseInt(reorderLevel, 10),
      last_order: dateOfLastOrder,
    };
  };

  const handleCreateProduct = async () => {
    try {
      const res = await postRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/products`,
        { product: transformProductInput(product) }
      );

      if (res.status === 201) {
        dispatch(addToast("Product added successfully."));
        setProduct({ ...INITIAL_PRODUCT });
        navigate(-1);
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    }
  };

  const isAllFieldsFilled = Object.values(product).every((value) => {
    const { productId, ...productWithoutId } = product;
    return Object.values(productWithoutId).every((field) =>
      field !== null && (typeof field === "string" ? field.trim() !== "" : true)
    );
  });

  return (
    <EntityPage title="Add Product" breadcrumbs stickyBottomBar>
      <FormPage>
        <FieldGroup label="Category Details">
          <FieldItem>
            <Typography width="30%" variant="body">
              Main Category
            </Typography>
            <AutoCompleteInput
              label="Please select"
              options={allCategories?.map((item) => ({
                title: item?.name,
                value: item?.name,
              }))}
              value={product.category}
              onChange={(_e, value) => handleInputChange("category", value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Status
            </Typography>
            <AutoCompleteInput
              label="Please select"
              options={Object.entries(COMMON_STATUS).map(([key, value]) => ({
                title: value,
                value: key,
              }))}
              value={product.status}
              onChange={(_e, value) => handleInputChange("status", value)}
            />
          </FieldItem>
        </FieldGroup>

        <hr className="my-10" />

        <FieldGroup label="Product Details">
          {[
            { label: "Item Name", name: "itemName" },
            { label: "Product ID", name: "productId" },
            { label: "Brand", name: "brand" },
            { label: "Color", name: "color" },
            { label: "Description", name: "description" },
            { label: "Supplier", name: "supplier" },
          ].map((field) => (
            <FieldItem key={field.name}>
              <Typography width="30%" variant="body">
                {field.label}
              </Typography>
              <TextInput
                value={product[field.name]}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
              />
            </FieldItem>
          ))}

          <FieldItem>
            <Typography width="30%" variant="body">
              Date of last order
            </Typography>
            <CustomDatePicker
              value={product.dateOfLastOrder}
              onChange={(date) => handleInputChange("dateOfLastOrder", date)}
            />
          </FieldItem>
        </FieldGroup>

        <hr className="my-10" />

        <FieldGroup label="Pricing Details">
          {[
            { label: "Cost per item", name: "costPerItem" },
            { label: "Price", name: "price" },
            { label: "Stock", name: "stock" },
            { label: "Reorder level", name: "reorderLevel" },
          ].map((field) => (
            <FieldItem key={field.name}>
              <Typography width="30%" variant="body">
                {field.label}
              </Typography>
              <TextInput
                name={field.name}
                type="number"
                value={product[field.name]}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
              />
            </FieldItem>
          ))}
        </FieldGroup>

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="containedPrimary"
              disabled={!isAllFieldsFilled}
              onClick={handleCreateProduct}
            >
              Create
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default CreateProduct;
