import React from "react";

function formatDate(isoDateStr) {
  const date = new Date(isoDateStr);

  // Define formatting options for month, day, and year
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  // Extract month, day, and year
  const [month, rawDay, year] = formattedDate.split(" ");

  // Get the day as a number to determine the ordinal suffix
  const day = parseInt(rawDay);
  const ordinalSuffix = getOrdinalSuffix(day);

  // Return the formatted date with the ordinal suffix for the day
  return `${month} ${day}${ordinalSuffix}, ${year}`;
}

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return "th"; // covers 11th to 20th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

const ContactHistory = ({ userData }) => {
  const addresses = [
    {
      contact_type: "Phone number",
      previous_data: userData?.phone_number || "-",
      edited_on: "-", // Default value if no edit information is available
    },
    ...(userData?.addresses || []).map((address) => ({
      contact_type: "Address",
      previous_data: address?.human_readable || "-",
      edited_on: formatDate(address?.updated_at) || "-",
    })),
  ];
  return (
    <section className="bg-white px-[24px] py-[32px] rounded-[8px]">
      <div className="font-['Montserrat'] text-[24px] font-bold text-[#272523] mb-[16px]">
        Contact history
      </div>
      <div className="grid grid-cols-4 border border-[#DFDFDF] rounded-[8px] items-center">
        <div className="font-['Questrial'] px-[16px] self-center w-full h-full border-b border-[#DFDFDF] py-[20px] text-[12px] font-normal text-[#272523]">
          Contact type
        </div>
        <div className="font-['Questrial'] px-[16px] text-[12px] py-[20px] font-normal w-full h-full border-b border-[#DFDFDF] text-[#272523] col-span-2">
          Previous data
        </div>
        <div className="font-['Questrial'] px-[16px] text-[12px] py-[20px] font-normal w-full h-full border-b border-[#DFDFDF] text-[#272523]">
          Edited on
        </div>

        {addresses.map((address) => (
          <>
            <div className="font-['Questrial'] px-[16px] py-[20px] text-[16px] font-normal w-full h-full border-b border-[#DFDFDF] text-[#272523]">
              {address.contact_type}
            </div>
            <div className="font-['Questrial'] px-[16px] text-[16px] py-[20px] font-normal w-full h-full border-b border-[#DFDFDF] text-[#272523] col-span-2">
              {address.previous_data}
            </div>
            <div className="font-['Questrial'] px-[16px] text-[16px] py-[20px] font-normal w-full h-full border-b border-[#DFDFDF] text-[#272523]">
              {address.edited_on}
            </div>
          </>
        ))}
      </div>
    </section>
  );
};

export default ContactHistory;
