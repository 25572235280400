import moment from 'moment';
import { CUSTOMER_FIELDS, USER_ROLES_ENUM } from "../constants";

export const parseAddress = (address) => {
  const addressRegex = /"([^"]+),([^"]+),([^"]+),([^"]+)"/;
  const match = address.match(addressRegex);

  if (match) {
    return {
      line: match[1].trim(),
      city: match[2].trim(),
      state: match[3].trim().split(" ")[0],
      zipcode: match[3].trim().split(" ")[1],
      country: match[4].trim(),
    };
  }

  return {
    line: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  };
};

export const formatPhoneNumber = (phone) => {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone;
};

export const isValidAddress = (address) => {
  return Object.values(address).every(
    (field) =>
      field !== null &&
      (typeof field === "string" ? field.trim() !== "" : field?.value.trim() !== "")
  );
};

export const processCSVData = (csvData) => {
  const rows = csvData.split("\n").map((row) => row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/));
  const headers = rows[0].map(header => header.trim());

  const expectedHeaders = Object.values(CUSTOMER_FIELDS);

  if (!expectedHeaders.every((header) => headers.includes(header))) {
    throw new Error('Invalid CSV format. Please upload a file with the correct template.');
  }

  return rows.slice(1).map((row) => {
    const rowData = {};
    headers.forEach((header, index) => {
      rowData[header] = row[index] ? row[index].trim() : "";
    });

    return {
      name: rowData[CUSTOMER_FIELDS.NAME],
      email: rowData[CUSTOMER_FIELDS.EMAIL],
      phone_number: formatPhoneNumber(rowData[CUSTOMER_FIELDS.PHONE_NUMBER]),
      role: USER_ROLES_ENUM.customer,
      last_signup: moment(rowData[CUSTOMER_FIELDS.SIGNUP_DATE], "MM/DD/YYYY").format("DD/MM/YYYY"),
      addresses_attributes: [
        { ...parseAddress(rowData[CUSTOMER_FIELDS.SHIPPING_ADDRESS]), address_type: "shipping" },
        { ...parseAddress(rowData[CUSTOMER_FIELDS.BILLING_ADDRESS]), address_type: "billing" }
      ].filter(isValidAddress)
    };
  });
};

export const extractOriginalCSVData = (csvData) => {
  const rows = csvData.split("\n").map((row) => row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/));
  const headers = rows[0].map(header => header.trim());
  const data = rows.slice(1).map(row => row.map(cell => cell.replace(/(^"|"$)/g, '').trim()));

  const expectedHeaders = Object.values(CUSTOMER_FIELDS);

  if (!expectedHeaders.every((header) => headers.includes(header))) {
    return { error: "CSV headers do not match the expected format." };
  }

  return { headers, data };
};

export const downloadTemplate = () => {
  const csvContent = `Full name,Email,Phone number,Last signup,Shipping address,Billing address\nJohn Doe,john.doe@example.com,1234567890,01/21/2023,"123 Main St, Anytown, NY 12345, USA","456 Elm St, Othertown, CA 67890, USA"`;
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "Customer Upload CSV Template.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
