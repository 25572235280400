import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  Input,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { CUSTOMERS_EMAIL_CAMPAIGNS } from "../../constants/FrontendRoutes";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const users = [
  { name: "Oliver Hansen", email: "oliver.hansen@gmail.com" },
  { name: "Van Henry", email: "van.henry@gmail.com" },
  { name: "April Tucker", email: "april.tucker@gmail.com" },
  { name: "Ralph Hubbard", email: "ralph.hubbard@gmail.com" },
  { name: "Omar Alexander", email: "omar.alexander@gmail.com" },
  { name: "Carlos Abbott", email: "carlos.abbott@gmail.com" },
  { name: "Miriam Wagner", email: "miriam.wagner@gmail.com" },
  { name: "Bradley Wilkerson", email: "bradley.wilkerson@gmail.com" },
  { name: "Virginia Andrews", email: "virginia.andrews@gmail.com" },
  { name: "Kelly Snyder", email: "kelly.snyder@gmail.com" },
];

const groups = [
  "All (882)",
  "Subscribed (691)",
  "Tag 1 (311)",
  "Tag 2 (253)",
  "Tag 3 (431)",
];

const CampaignSetup = () => {
  const [personName, setPersonName] = useState([]);
  const [isSheduledCampaign, setIsSheduledCampaign] = useState(false);
  const [isRecurringCampaign, setIsRecurringCampaign] = useState(false);
  const [campaignSchedule, setCampaignSchedule] = useState({
    date: "03/03/2024",
    time: "00:00",
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSheduledCampaign = (event) => {
    setIsSheduledCampaign(event.target.checked);
  };

  const handleRecurringCampaign = (event) => {
    setIsRecurringCampaign(event.target.checked);
  };

  const handleScheduleDate = (event) => {
    setCampaignSchedule({
      ...campaignSchedule,
      date: event.target.value,
    });
  };

  const handleScheduleTime = (event) => {
    setCampaignSchedule({
      ...campaignSchedule,
      time: event.target.value,
    });
  };

  return (
    <div className="border rounded-xl">
      <div className="p-6">
        <div className="flex flex-col gap-3">
          <p className="font-semibold">Recipients</p>
          <p>Choose who to send this email campaign to:</p>
          <div className="flex flex-row items-center justify-between">
            <p>Send to</p>
            <FormControl sx={{ m: 1, width: 700 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Add recipients by name, email address, or groups
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={personName}
                onChange={handleChange}
                input={
                  <OutlinedInput label="Add recipients by name, email address, or groups" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <p className="m-2">Groups</p>
                {groups.map((group) => (
                  <MenuItem key={group} value={group}>
                    <Checkbox checked={personName.indexOf(group) > -1} />
                    <ListItemText primary={group} />
                  </MenuItem>
                ))}
                <Divider className="mx-2" />
                <p className="m-2">Users</p>
                {users.map((user) => (
                  <MenuItem key={user.email} value={user.email}>
                    <div className="flex flex-row gap-3">
                      <Checkbox checked={personName.indexOf(user.email) > -1} />
                      <div className="flex flex-col gap-1">
                        <p className="text-[#939291]">{user.name}</p>
                        <ListItemText primary={user.email} />
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Divider />
          <p className="font-semibold">Sender</p>
          <p>Choose which email you’d like to use as the sender:</p>
          <div className="flex flex-row items-center justify-between">
            <p>From</p>
            <Input sx={{ m: 1, width: 700 }} />
          </div>
          <Divider />
          <p className="font-semibold">Subject</p>
          <p>What’s the subject line for this campaign?</p>
          <div className="flex flex-row items-center justify-between">
            <p>Subject</p>
            <Input sx={{ m: 1, width: 700 }} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <p>Preview text</p>
            <Input sx={{ m: 1, width: 700 }} />
          </div>
          <Divider />
          <p className="font-semibold">Settings</p>
          <div className="p-4 flex flex-col">
            <div className="flex flex-row justify-between w-1/3">
              <Checkbox
                color="success"
                checked={isSheduledCampaign}
                onChange={handleSheduledCampaign}
              />
              <p>Schedule Delivery Time</p>
            </div>
            {isSheduledCampaign && (
              <div className="flex flex-row gap-3 mx-4">
                <p>Send on</p>
                <input
                  type="date"
                  value={campaignSchedule.date}
                  onChange={handleScheduleDate}
                  id="datepicker"
                  name="datepicker"
                />
                <p>at</p>
                <input
                  type="time"
                  value={campaignSchedule.time}
                  onChange={handleScheduleTime}
                  id="timepicker"
                  name="timepicker"
                />
              </div>
            )}
            <div className="flex flex-row justify-between w-1/3">
              <Checkbox
                color="success"
                checked={isRecurringCampaign}
                onChange={handleRecurringCampaign}
              />
              <p>Send as recurring email</p>
            </div>
            {isRecurringCampaign && (
              <div className="flex flex-row gap-3 items-center mx-4">
                <FormControl sx={{ m: 1, width: 150 }}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    autoWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                  </Select>
                </FormControl>
                <p>on</p>
                <FormControl sx={{ m: 1, width: 150 }}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    autoWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="monday">Monday</MenuItem>
                    <MenuItem value="tuesday">Tuesday</MenuItem>
                    <MenuItem value="wednesday">Wednesday</MenuItem>
                    <MenuItem value="thrusday">Thrusday</MenuItem>
                    <MenuItem value="friday">Friday</MenuItem>
                  </Select>
                </FormControl>
                <p>at</p>
                <input type="time" id="timepicker" name="timepicker" />
              </div>
            )}
          </div>
          <Divider />
          <div className="flex justify-end items-end">
            <button
              type="button"
              className="text-black bg-white border font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            >
              <Link to={CUSTOMERS_EMAIL_CAMPAIGNS}>Cancel</Link>
            </button>
            <button
              type="button"
              className="text-white bg-[#4c8c4a] border border-[#50c04c] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            >
              <Link to={CUSTOMERS_EMAIL_CAMPAIGNS}>Next</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignSetup;
