import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { UploadIcon } from "../../common/icons";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


export default function InputFileUpload({ onChange }) {
  const handleInputChange = (event) => {
    onChange && onChange(event);
  };

  return (
    <Button
      component="label"
      role={undefined}
      variant="outlined"
      tabIndex={-1}
      startIcon={<UploadIcon />}
      sx={{
        color: "black",
        font: "Questrial",
        fontSize: "1rem",
        borderRadius: "0.5rem",
        borderColor: "rgb(214, 211, 209)",
        textTransform: "none",
        "&:hover": {
          borderColor: "black",
          backgroundColor: "transparent",
        },
      }}
    >
      Import Data
      <VisuallyHiddenInput
        type="file"
        accept=".csv"
        onChange={handleInputChange}
      />
    </Button>
  );
}
