export const REACT_APP_URL = process.env.REACT_APP_FRONTEND_URL;
export const RAILS_APP_API_URL = process.env.REACT_APP_BACKEND_URL;
export const API_BASE = `${RAILS_APP_API_URL}/api`;

export const VALIDATE_TOKEN = "/sessions/validate_token";
export const USERS = "/users";
export const REPAIR_PRODUCTS = "/repair_products";
export const NEW_PRODUCTS = "/new_products";
export const PRODUCTS = "/products";
export const CATEGORIES = "/categories";
export const REPAIR_LINE_ITEMS = "/repair_line_items";
export const REPAIR_ORDERS = "/repair_orders";
export const REPAIR_SERVICES = "/repair_services";
export const PRODUCT_ORDERS = "/product_orders";
export const PRODUCT_LINE_ITEMS = "/product_line_items";
export const PARENT_CATEGORIES = "/parent_categories";
export const UPDATE_IMAGE = "/update_images";
export const ORDER_LINE_ITEMS = "/order_line_items";
export const ADDRESSES = "/addresses";
export const SHIPPING_LABELS = "/shipping_labels";
export const ORDER_LINE_ITEMS_DETAILS = `${API_BASE}/order_line_item_details`;

export const ORDERS = "/orders";
export const STORES = "stores";
export const UPDATE_ORDER_SRATUS_AND_SHIPPING_INFO =
  "update_order_status_and_shipping_info";

export const QR_CODES = "qr_codes";
export const SET_TOKEN = "set_token";
export const GET_JWT_TOKEN = "get_jwt_token";
export const NEXT_STEP_BROADCAST = "next_step";

export const API_BASE_USERS = `${API_BASE}${USERS}`;

export const USERS_BASE = `${RAILS_APP_API_URL}/users`;

export const USERS_SIGN_IN = `${RAILS_APP_API_URL}/login`;
export const USERS_SIGN_OUT = `${RAILS_APP_API_URL}/logout`;
export const USERS_PASSWORD_NEW = `${RAILS_APP_API_URL}/password/new`;
export const USERS_PASSWORD_EDIT = `${RAILS_APP_API_URL}/password/edit`;
export const USERS_PASSWORD = `${RAILS_APP_API_URL}/password`;
export const USERS_CANCEL = `${RAILS_APP_API_URL}/cancel`;
export const USERS_EDIT = `${RAILS_APP_API_URL}/edit`;
export const USER_VALIDATE_TOKEN = `${RAILS_APP_API_URL}${VALIDATE_TOKEN}`;

export const REPAIR_PRODUCTS_REPAIR_LINE_ITEMS = `${API_BASE}${REPAIR_PRODUCTS}${REPAIR_LINE_ITEMS}`;
export const REPAIR_PRODUCT_REPAIR_LINE_ITEM = `${REPAIR_PRODUCTS_REPAIR_LINE_ITEMS}`;
export const REPAIR_PRODUCTS_REPAIR_ORDERS = `${API_BASE}${REPAIR_PRODUCTS}${REPAIR_ORDERS}/`;
export const REPAIR_PRODUCT_REPAIR_ORDER = `${REPAIR_PRODUCTS_REPAIR_ORDERS}:id`;
export const REPAIR_PRODUCTS_REPAIR_SERVICES = `${API_BASE}${REPAIR_PRODUCTS}${REPAIR_SERVICES}`;
export const REPAIR_PRODUCT_REPAIR_SERVICE = `${REPAIR_PRODUCTS_REPAIR_SERVICES}/:id`;

export const NEW_PRODUCTS_PRODUCTS = `${API_BASE}${NEW_PRODUCTS}${PRODUCTS}`;
export const NEW_PRODUCT = `${NEW_PRODUCTS_PRODUCTS}/:id`;
export const NEW_PRODUCTS_CATEGORIES = `${API_BASE}${NEW_PRODUCTS}${CATEGORIES}`;
export const NEW_PRODUCT_CATEGORY = `${NEW_PRODUCTS_CATEGORIES}/:id`;
export const NEW_PRODUCTS_PRODUCT_ORDERS = `${API_BASE}${NEW_PRODUCTS}${PRODUCT_ORDERS}`;
export const NEW_PRODUCT_ORDER = `${NEW_PRODUCTS_PRODUCT_ORDERS}/:id`;
export const NEW_PRODUCTS_PRODUCT_ORDERS_LINE_ITEMS = `${NEW_PRODUCTS_PRODUCT_ORDERS}/:product_order_id${PRODUCT_LINE_ITEMS}`;
export const NEW_PRODUCTS_PRODUCT_ORDER_LINE_ITEM = `${NEW_PRODUCTS_PRODUCT_ORDERS_LINE_ITEMS}/:id`;

export const REPAIR_PRODUCTS_REPAIR_OPTIONS = `${REPAIR_PRODUCTS_REPAIR_SERVICES}/:repair_service_id${CATEGORIES}/:repair_category_id/repair_options`;
export const REPAIR_PRODUCT_REPAIR_OPTION = `${REPAIR_PRODUCTS_REPAIR_OPTIONS}/:id`;

export const PARENT_CATEGORIES_BASE = `${API_BASE}/${PARENT_CATEGORIES}`;

export const STORE_TOKEN = `${API_BASE}/${QR_CODES}/${SET_TOKEN}`;
export const SCAN_QR_AND_GET_JWT = `${API_BASE}/${QR_CODES}/${GET_JWT_TOKEN}`;
export const NEXT_STEP_BROADCASTING = `${API_BASE}/${QR_CODES}/${NEXT_STEP_BROADCAST}`;
export const LINE_ITEM = `${API_BASE}${REPAIR_LINE_ITEMS}`;

export const GET_ALL_TEAM_MEMBERS = `${API_BASE}/${STORES}/1${USERS}`;
