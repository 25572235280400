import React from "react";

export default function LabelText({ text, classes }) {
  return (
    <p
      className={`font-montserrat md:text-lg text-[16px] md:font-semibold ${classes}`}
    >
      {text}
    </p>
  );
}
