import { Alert, Button } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import React from 'react';

const AlertTile = ({ text, date, textAfterDate, buttonText, OnSubmit, isButtonVisible }) => {
  return (
    <Alert
      variant="outlined"
      iconMapping={{
        success: <InfoIcon fontSize="inherit" />,
      }}
      sx={{
        borderRadius: '0.5rem',
        backgroundColor: 'rgba(250, 240, 230, 0.5)',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        color: '#57534e',
        '& .MuiAlert-icon': {
          color: '#4C8C4A',
        },
      }}
      action={
        isButtonVisible &&
        <Button
          size="small"
          onClick={() => OnSubmit(true)}
          sx={{
            paddingX: '12px',
            backgroundColor: '#4C8C4A',
            borderRadius: '0.375rem',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)',
            gap: '8px',
            color: 'white',
            '&:hover': {
              border: '0px solid #447E42',
              backgroundColor: '#447E42',
            },
          }}
        >
          {buttonText}
        </Button>
      }
    >
      {`${text} ${date} ${textAfterDate}`}
    </Alert>
  );
};

export default AlertTile;
