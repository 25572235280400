import axiosInstance from "./axiosInstance";

const parseError = (error) => {
  console.error("Request error:", error);
  let defaultMessage = "An unknown error occurred.";

  if (error.response && error.response.data) {
    const responseData = error.response.data;

    if (responseData.message) {
      defaultMessage = responseData.message;
    } else if (responseData.messages && responseData.messages.length > 0) {
      defaultMessage = responseData.messages[0];
    } else if (responseData.error) {
      defaultMessage = responseData.error;
    } else if (responseData.errors && responseData.errors.length > 0) {
      defaultMessage = responseData.errors[0];
    }
  }

  return defaultMessage;
};

export const getRequest = async (url, params = {}, includes = "") => {
  try {
    if (includes) {
      url = `${url}?include=${includes}`;
    }

    const response = await axiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    throw parseError(error);
  }
};

export const postRequest = async (url, data = {}) => {
  try {
    return await axiosInstance.post(url, data);
  } catch (error) {
    throw parseError(error);
  }
};

export const deleteRequest = async (url, data = {}) => {
  try {
    const response = await axiosInstance.delete(url, data);
    return response.data;
  } catch (error) {
    throw parseError(error);
  }
};

export const updateRequest = async (url, data = {}, headers = {}) => {
  try {
    const response = await axiosInstance.patch(url, data, {
      headers,
    });

    return response;
  } catch (error) {
    throw parseError(error);
  }
};
