import React from 'react';
import { Navigate } from 'react-router-dom';
import { retrieveTokenFromLocalStorage } from "../utils/localStorage";

const PublicRoute = ({ component: Component, redirect=true, ...rest }) => {
  const isAuthenticated = retrieveTokenFromLocalStorage();
  return isAuthenticated && redirect ? <Navigate to="/" /> : <Component {...rest} />;
};

export default PublicRoute;
