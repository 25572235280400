import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../api";
import EntityPage from "../components/EntityPage";
import AutoCompleteInput from "../components/Form/Field/AutoComplete";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";
import { COUNTRIES, USER_ROLES, USER_ROLES_ENUM, US_STATES } from "../constants";
import { selectCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";
import { validateEmail, validatePhoneNumber } from "../utils";

const INITIAL_CUSTOMER = {
  name: "",
  email: "",
  phone_number: "",
  shippingAddress: {
    street: "",
    zipcode: "",
    city: "",
    state: { title: "", value: "" },
    country: { title: "", value: "" },
  },
  billingAddress: {
    street: "",
    zipcode: "",
    city: "",
    state: { title: "", value: "" },
    country: { title: "", value: "" },
  },
  addressesSame: false,
};

const checkAddress = (address, checkType) => {
  const checkFunction = checkType === "partial" ? "some" : "every";
  return Object.values(address)[checkFunction](
    (field) =>
      field !== null &&
      (typeof field === "string" ? field.trim() !== "" : field.value.trim() !== "")
  );
};

const transformAddress = (address, type) => ({
  line: address.street,
  zipcode: address.zipcode,
  city: address.city,
  state: address.state.value,
  country: address.country.value,
  address_type: type,
});

const transformCustomerInput = (customer) => {
  const {
    name,
    email,
    phone_number,
    shippingAddress,
    billingAddress,
    addressesSame,
  } = customer;
  return {
    name,
    email,
    phone_number,
    role: USER_ROLES_ENUM.customer,
    addresses_attributes: [
      transformAddress(shippingAddress, "shipping"),
      transformAddress(
        addressesSame ? shippingAddress : billingAddress,
        "billing"
      ),
    ].filter((address) => checkAddress(address, "every")),
  };
};

const AddNewCustomer = () => {
  const [customer, setCustomer] = useState({ ...INITIAL_CUSTOMER });
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  const handleInputChange = (section, name, value) => {
    if (section === "basic") {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        [name]: value,
      }));
    } else {
      setCustomer((prevCustomer) => {
        const updatedCustomer = {
          ...prevCustomer,
          [section]: {
            ...prevCustomer[section],
            [name]: value,
          },
        };

        if (prevCustomer.addressesSame && section === "shippingAddress") {
          updatedCustomer.billingAddress = {
            ...updatedCustomer.shippingAddress,
          };
        }

        return updatedCustomer;
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      addressesSame: isChecked,
      billingAddress: isChecked
        ? { ...prevCustomer.shippingAddress }
        : { ...INITIAL_CUSTOMER.billingAddress },
    }));
  };

  const handleAddNewCustomer = async () => {
    try {
      const res = await postRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/users`,
        { users: [transformCustomerInput(customer)] }
      );

      if (res && res.status === 201) {
        dispatch(addToast(res.data?.message));
        setCustomer({ ...INITIAL_CUSTOMER });
        navigate(-1);
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message || error}`, { appearance: "error" }));
    }
  };

  const isAllFieldsFilled = () => {
    const isShippingAddressRequired = checkAddress(customer.shippingAddress, "partial");
    const isBillingAddressRequired = checkAddress(customer.billingAddress, "partial");

    return (
      customer.name.trim() !== "" &&
      validateEmail(customer.email) &&
      validatePhoneNumber(customer.phone_number) &&
      (!isShippingAddressRequired || checkAddress(customer.shippingAddress, "every")) &&
      (!isBillingAddressRequired || checkAddress(customer.billingAddress, "every"))
    );
  };

  const renderAddressFields = (section, address) =>
    [
      { label: "Street address", name: "street", placeholder: "123 Main St" },
      { label: "Zipcode", name: "zipcode", placeholder: "12345" },
      { label: "City", name: "city", placeholder: "New York" },
      {
        label: "State",
        name: "state",
        type: "select",
        options: US_STATES,
        placeholder: "Select state",
      },
      {
        label: "Country",
        name: "country",
        type: "select",
        options: COUNTRIES,
        placeholder: "Select country",
      },
    ].map((field) => (
      <FieldItem key={`${field.name}-${section}`}>
        <Typography width="30%" variant="body">
          {field.label}
        </Typography>
        {field.type === "select" ? (
          <AutoCompleteInput
            options={field.options}
            value={address[field.name]}
            onChange={(_e, value) =>
              handleInputChange(section, field.name, value)
            }
            label={field.placeholder}
            isDisabled={section === "billingAddress" && customer.addressesSame}
          />
        ) : field.name === "zipcode" ? (
          <InputMask
            mask="99999"
            value={address[field.name]}
            onChange={(e) =>
              handleInputChange(section, field.name, e.target.value)
            }
            disabled={section === "billingAddress" && customer.addressesSame}
          >
            {() => (
              <TextInput
                id={field.name}
                name={field.name}
                type="tel"
                placeholder={field.placeholder}
                variant="outlined"
                value={address[field.name]}
                disabled={section === "billingAddress" && customer.addressesSame}
              />
            )}
          </InputMask>
        ) : (
          <TextInput
            value={address[field.name]}
            onChange={(e) =>
              handleInputChange(section, field.name, e.target.value)
            }
            placeholder={field.placeholder}
            disabled={section === "billingAddress" && customer.addressesSame}
          />
        )}
      </FieldItem>
    ));

  return (
    <EntityPage title="Add a new customer" breadcrumbs stickyBottomBar>
      <FormPage>
        <FieldGroup label="Basic Information">
          <FieldItem>
            <Typography width="30%" variant="body">
              Name
            </Typography>
            <TextInput
              value={customer.name}
              onChange={(e) =>
                handleInputChange("basic", "name", e.target.value)
              }
              placeholder="John Doe"
            />
          </FieldItem>
          <FieldItem>
            <Typography width="30%" variant="body">
              Email address
            </Typography>
            <TextInput
              value={customer.email}
              onChange={(e) =>
                handleInputChange("basic", "email", e.target.value)
              }
              placeholder="john.doe@example.com"
              type="email"
            />
          </FieldItem>
          <FieldItem>
            <Typography width="30%" variant="body">
              Phone number
            </Typography>
            <InputMask
              mask="(999) 999-9999"
              value={customer.phone_number}
              onChange={(e) =>
                handleInputChange("basic", "phone_number", e.target.value)
              }
            >
              {() => (
                <TextInput
                  id="phone_number"
                  name="phone_number"
                  type="tel"
                  placeholder="(123) 456-7890"
                  variant="outlined"
                />
              )}
            </InputMask>
          </FieldItem>
        </FieldGroup>

        <FieldGroup label="Shipping Information">
          {renderAddressFields("shippingAddress", customer.shippingAddress)}
        </FieldGroup>

        <FieldGroup label="">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
              Billing Information
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer.addressesSame}
                  onChange={handleCheckboxChange}
                  color="primary"
                  disabled={!checkAddress(customer.shippingAddress, "every")}
                />
              }
              label="Same as shipping address"
            />
          </Box>
          {renderAddressFields("billingAddress", customer.billingAddress)}
        </FieldGroup>

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="containedPrimary"
              disabled={!isAllFieldsFilled()}
              onClick={handleAddNewCustomer}
            >
              Create
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default AddNewCustomer;
