import React from "react";

import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

export default function SearchInput({ value, onChange }) {
  return (
    <TextField
      placeholder="Search by name"
      type="text"
      size="small"
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#D0D5DD",
            borderRadius: "8px",
          },
        },
        width: "280px",
        "@media (max-width: 640px)": {
          width: "100%",
        },
        height: "42px",
      }}
    />
  );
}
