import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MoreHorizSharp } from "@mui/icons-material";
import { getRequest, updateRequest } from "../../api";
import { useActionCable } from "../../customHooks/useActionCable";
import { formatTimeDifference } from "../../utils";
import { MESSAGES_ORDERS_DETAILS } from "../../constants/FrontendRoutes";
import { selectCurrentUser } from "../../redux/authSlice";
import { STORES } from "../../constants/BackendRoutes";
import CircleIcon from "@mui/icons-material/Circle";

const List = () => {
  const { id } = useParams();
  const [orders, setOrders] = useState([]);
  const userData = useSelector(selectCurrentUser);
  const [newMessage] = useActionCable("OrdersListChannel");

  const markMessagesReadURL = "messages/mark_all_messages_as_read";

  const orderId = parseInt(id);
  const userId = userData?.id;

  const markMessagesRead = async (orderID) => {
    await updateRequest(
      `${STORES}/${userData.stores[0].id}/orders/${orderID}/${markMessagesReadURL}`
    );
  };

  const fetchOrder = async () => {
    try {
      const repairOrders = await getRequest(
        `${STORES}/${userData.stores[0].id}/orders`,
        {},
        "user,messages.user"
      );
      const orders = await Promise.all(
        repairOrders.map(async (order) => {
          const messages = order?.messages?.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          );
          const lastMessage = messages.slice(-1)[0];

          let highlighted =
            lastMessage?.user?.id === userId ? false : !lastMessage?.is_read;

          if (!lastMessage) {
            highlighted = false;
          }

          if (
            orderId === order.id &&
            lastMessage?.user?.id !== userId &&
            !lastMessage?.is_read &&
            highlighted
          ) {
            markMessagesRead(id);
            highlighted = false;
          }
          return {
            id: order.id,
            orderNumber: order.id,
            customerName: order.user.name,
            message: lastMessage?.content,
            messageId: lastMessage?.id,
            time: lastMessage?.created_at,
            highlighted,
          };
        })
      );
      setOrders(orders);
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  };
  useEffect(() => {
    if (userData) fetchOrder();
  }, [userData]);

  useEffect(() => {
    if (newMessage.order_id === orderId && newMessage.user_id !== userData.id) {
      markMessagesRead(id);
    }
    setOrders(
      orders.map((order) => {
        if (order.id === newMessage.order_id) {
          return {
            ...order,
            highlighted: orderId !== newMessage.order_id,
            message: newMessage.content,
            time: newMessage.created_at,
          };
        } else {
          return order;
        }
      })
    );
  }, [newMessage]);

  const handleClick = async (orderId) => {
    const order = orders.find((order) => order.id === orderId);
    if (order.highlighted) {
      await markMessagesRead(orderId);
    }
    setOrders(
      orders.map((msg) =>
        msg.orderNumber === orderId ? { ...msg, highlighted: false } : msg
      )
    );
  };

  return (
    <div className="flex flex-col">
      {orders.map((order) => {
        return (
          <Link
            className={`${order.id === orderId ? "sm:bg-[#F3F0E8]" : ""}`}
            key={order.id}
            to={MESSAGES_ORDERS_DETAILS.replace(":id", order?.id)}
            style={{ cursor: "pointer" }}
            onClick={() => handleClick(order.id)}
          >
            <div
              className={
                "mx-5 px-0 py-2 sm:py-5 border-b border-solid border-[#F3F0E8] "
              }
            >
              <div className="flex justify-between text-[#939291">
                <div className="text-stone-400 text-[11px] font-normal">
                  Order #{order.orderNumber}
                </div>
                <div className="text-right text-stone-400 text-xs font-normal font-['Questrial'] leading-none">
                  {formatTimeDifference(order.time)}
                </div>
              </div>
              <div className="flex justify-between relative">
                <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                  {order.customerName}
                </div>
                {order.highlighted && (
                  <div
                    style={{
                      position: "absolute",
                      transform: "translate(0, 5px)",
                      left: "-5%",
                    }}
                  >
                    <CircleIcon
                      sx={{
                        width: "10px",
                        height: "10px",
                        color: "#D84141",
                        position: "absolute",
                      }}
                    />
                  </div>
                )}
                {/* <MoreHorizSharp style={{ color: "#939291" }} /> */}
              </div>
              <div className="text-xs mt-2">{order.message}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default List;
