import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { getRequest, postRequest } from "../api";

import { Plus, UploadIcon } from "../common/icons";

import EntityPage from "../components/EntityPage";
import FileUploadModal from "../components/Modal/FileUploadModal";
import CustomTable from "../components/Table";

import { CUSTOMER_DETAILS, CUSTOMERS_NEW } from "../constants/FrontendRoutes";

import { selectCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";
import { setLoading } from "../redux/loadingSlice";

import { processCSVData } from "../utils/customerList";
import { USER_ROLES } from "../constants";

const TABLE_COLUMNS = [
  { field: "id", label: "ID", sortable: true, width: "100px" },
  {
    field: "name",
    label: "Name",
    sortable: true,
    searchable: true,
    width: "100px",
  },
  { field: "email", label: "Email", searchable: true, width: "150px" },
  {
    field: "phone_number",
    label: "Phone Number",
    searchable: true,
    width: "150px",
  },
  {
    field: "number_of_orders",
    label: "# of orders",
    sortable: true,
    width: "100px",
  },
  {
    field: "amount_spent",
    label: "Amount Spent",
    sortable: true,
    width: "100px",
  },
  {
    field: "signup_date",
    label: "Sign Up Date",
    sortable: true,
    width: "100px",
  },
];

const transformData = (data) =>
  data?.map((customer) => ({
    id: customer.id,
    name: customer.name,
    email: customer.email,
    phone_number: customer.phone_number,
    number_of_orders: customer.orders.filter(
      (order) => order.status !== "quote"
    ).length,
    amount_spent: `$${customer.orders
      .filter((order) => order.status !== "quote")
      .reduce((sum, order) => sum + order.net_total, 0)
      .toFixed(2)}`,
    signup_date: new Date(
      customer.last_signup ? customer.last_signup : customer.created_at
    ).toLocaleDateString(),
    link: CUSTOMER_DETAILS.replace(":id", customer.id),
  }));

const CustomerList = () => {
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      fetchStoreCustomers();
    }
  }, [userData]);

  const fetchStoreCustomers = async () => {
    try {
      dispatch(setLoading(true));
      const data = await getRequest(
        `stores/${userData.stores[0].id}/users`,
        { filters: { role: [USER_ROLES.CUSTOMER] } },
        "orders"
      );
      if (data?.length) {
        setRows(transformData(data));
      } else {
        dispatch(addToast("Customers not Found", { appearance: "error" }));
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const csvData = e.target.result;
      const customers = processCSVData(csvData);

      try {
        dispatch(setLoading(true));
        const res = await postRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/stores/${userData.stores[0].id}/users`,
          { users: customers }
        );

        if (res && res.status === 201) {
          dispatch(addToast(res.data?.message));
          fetchStoreCustomers();
        }
      } catch (error) {
        dispatch(
          addToast(`Error adding customers: ${error?.message || error}`, {
            appearance: "error",
          })
        );
      } finally {
        dispatch(setLoading(false));
      }
    };

    reader.readAsText(file);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <EntityPage title="Customer List" breadcrumbs stickyBottomBar>
      <div className="flex flex-col justify-between">
        <CustomTable
          columns={TABLE_COLUMNS}
          rows={rows}
          sortable
          searchable
          actions={
            <>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<UploadIcon />}
                sx={{
                  color: "black",
                  font: "Questrial",
                  fontSize: "1rem",
                  borderRadius: "0.5rem",
                  borderColor: "rgb(214, 211, 209)",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleOpenModal}
              >
                Import Data
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Plus />}
                onClick={() => navigate(CUSTOMERS_NEW)}
              >
                Add Customer
              </Button>
            </>
          }
        />
      </div>
      <FileUploadModal
        open={modalOpen}
        onClose={handleCloseModal}
        onFileUpload={handleFileUpload}
      />
    </EntityPage>
  );
};

export default CustomerList;
