import React from 'react'
import { convertTo24HourFormat } from "../utils";
import { Typography } from "@mui/material";

const IncomingMessage = ({ text, time }) => {
  return (
    <div className='ml-3 my-4 sm:my-0'>
      <div className="border border-[#F3F0E8] rounded-t-[30px] rounded-tr-[30px] rounded-br-[30px] sm:rounded-t-[45px] sm:rounded-tr-[45px] sm:rounded-br-[45px] bg-[#F3F0E8] p-5 w-[221px] text-stone-800 text-xs font-extralight sm:font-normal font-['Questrial'] leading-none">
        <Typography>{text}</Typography>
      </div>
      <span className="text-[#939291] text-xs">{convertTo24HourFormat(time)}</span>
    </div>
  )
}

export default IncomingMessage
