export const getDaysInMonth = (year, monthIndex) => {
  return new Date(year, monthIndex + 1, 0).getDate();
};

export const sumNetTotalForEachDay = (orders, monthIndex) => {
  const year = new Date().getFullYear();
  const daysInMonth = getDaysInMonth(year, monthIndex);

  const netTotalPerDay = Array.from({ length: daysInMonth + 1 }, (_, i) =>
    i === 0 ? null : 0
  );

  orders.forEach((order) => {
    const orderDate = new Date(order.created_at);
    const day = orderDate.getUTCDate();
    const orderMonth = orderDate.getUTCMonth();
    const orderYear = orderDate.getUTCFullYear();

    if (
      orderYear === year &&
      orderMonth === monthIndex &&
      day >= 1 &&
      day <= daysInMonth
    ) {
      if (netTotalPerDay[day] === null) {
        netTotalPerDay[day] = 0;
      }
      netTotalPerDay[day] += order.net_total || 0;
    }
  });

  return netTotalPerDay;
};

/** getOrdersForMonth(orders, 7, 2023)
 * return the orders for july 2023 */
export const getOrdersForMonth = (orders, targetMonth, targetYear) => {
  return orders.filter((order) => {
    const orderDate = new Date(order.created_at);
    orderDate.setUTCHours(0, 0, 0, 0);

    const targetDate = new Date(Date.UTC(targetYear, targetMonth - 1, 1));
    const targetMonthEnd = new Date(Date.UTC(targetYear, targetMonth, 1));

    return orderDate >= targetDate && orderDate < targetMonthEnd;
  });
};