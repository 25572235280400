import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const LoadingOverlay = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-[5000]">
          <CircularProgress sx={{ color: "white" }} size={75} />
        </div>
      )}
    </>
  );
};

export default LoadingOverlay;
