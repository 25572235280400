import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4C8C4A",
    },
    secondary: {
      main: "#C9C8C8",
    },
    error: {
      main: "#dc3545", // currently (red) should be define in future
    },
    warning: {
      main: "#ffc107", // currently (yellow) should be define in future
    },
    info: {
      main: "#17a2b8", // currently (cyan) should be define in future
    },
    success: {
      main: "#28a745", // currently (green) should be define in future
    },
    abc: {
      main: "#272523", // currently (green) should be define in future
    },
    text: {
      primary: "#272523",
      secondary: "#939291",
      disabled: "#999999", // should be define in future
      hint: "#bbbbbb", // should be define in future
      white: "#FFF",
    },
    background: {
      white: "#FFF",
      primary: "#4c8c4a1a",
      secondary: "#939291",
    },
    border: {
      primary: "#4C8C4A",
      secondary: "#C9C8C8",
    },
  },
  typography: {
    fontFamily: "Questrial, Roboto, Arial, sans-serif",
    header: {
      fontFamily: "Montserrat",
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "140%",
    },
    title: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "140%",
    },
    subTitle: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "140%",
    },
    info: {
      fontFamily: "Questrial",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "140%",
    },
    body: {
      fontFamily: "Questrial",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "140%",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        containedPrimary: {
          fontSize: "16px",
          padding: "10px 16px",
          border: "1px solid #4C8C4A",
          backgroundColor: "#4C8C4A",
          color: "#fff",
          "&:hover": {
            border: "1px solid #447E42",
            backgroundColor: "#447E42",
          },
          "&.Mui-disabled": {
            border: "1px solid #C9C8C8",
            backgroundColor: "#c9c8c880",
            color: "#939291",
          },
        },
        outlinedPrimary: {
          fontSize: "16px",
          padding: "10px 16px",
          border: "1px solid #4C8C4A",
          color: "#4C8C4A",
          "&:hover": {
            backgroundColor: "rgba(76, 140, 74, 0.1)",
          },
          "&.Mui-disabled": {
            borderColor: "#E0E0E0",
            color: "#A0A0A0",
          },
        },
        outlinedSecondary: {
          fontSize: "16px",
          padding: "10px 16px",
          border: "1px solid #C9C8C8",
          color: "#272523",
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "black",
          },
          "&.Mui-disabled": {
            borderColor: "#E0E0E0",
            color: "#A0A0A0",
          },
        },
        outlinedTertiary: {
          fontSize: "24px",
          padding: "10px 16px",
          fontWeight: 700,
          lineHeight: "33.60px",
          fontFamily: "Montserrat",
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "black",
          },
          "&.Mui-disabled": {
            borderColor: "#E0E0E0",
            color: "#A0A0A0",
          },
        },
        textSecondary: {
          fontSize: "16px",
          padding: "0px",
          margin: "16px",
          textTransform: "none",
          textDecoration: "underline",
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
            color: "#4C8C4A",
            "& .MuiSvgIcon-root": {
              color: "#4C8C4A",
            },
          },
        },
        selectablePrimary: {
          fontSize: "18px",
          fontWeight: 600,
          fontFamily: "Montserrat",
          textTransform: "none",
          whiteSpace: "nowrap",
          padding: "16px",
          border: "1px solid #C9C8C8",
          backgroundColor: "#FFF",
          color: "#272523",
          "&:hover": {
            border: "1px solid #C9C8C8",
            backgroundColor: "#FFF",
          },
          "&.Mui-disabled": {
            border: "1px solid #C9C8C8",
            backgroundColor: "#c9c8c880",
            color: "#939291",
          },
        },
        selectableSecondary: {
          fontSize: "18px",
          fontWeight: 600,
          fontFamily: "Montserrat",
          textTransform: "none",
          whiteSpace: "nowrap",
          padding: "10px 16px",
          border: "1px solid #C9C8C8",
          backgroundColor: "#FFF",
          color: "#272523",
          "&:hover": {
            border: "1px solid #C9C8C8",
            backgroundColor: "#FFF",
          },
          "&.Mui-disabled": {
            border: "1px solid #C9C8C8",
            backgroundColor: "#c9c8c880",
            color: "#939291",
          },
        },
      },
      variants: [
        {
          props: { variant: "selectablePrimary", isSelected: true },
          style: {
            border: "1px solid #4C8C4A",
            backgroundColor: "#4c8c4a1a",
            color: "#4C8C4A",
            "&:hover": {
              border: "1px solid #4C8C4A",
              backgroundColor: "#4c8c4a1a",
            },
          },
        },
        {
          props: { variant: "selectableSecondary", isSelected: true },
          style: {
            border: "1px solid #4C8C4A",
            backgroundColor: "#4c8c4a1a",
            "&:hover": {
              border: "1px solid #4C8C4A",
              backgroundColor: "#4c8c4a1a",
            },
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: "#939291",
          "&.Mui-selected": {
            color: "#272523",
          },
          "&.Mui-disabled": {
            color: "#999999",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#c9c8c8",
            borderRadius: "8px",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#B3B29A",
            boxShadow: "0 0 0 4px rgba(179, 178, 154, 0.25) inset",
          },
          "&.Mui-disabled fieldset": {
            borderColor: "#e0e0e0",
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-input": {
              color: "rgba(0, 0, 0, 0.38)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.38)",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "rgba(0, 0, 0, 0.38)",
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#c9c8c8",
        },
      },
    },
  },
});

export default theme;
