import React, { useState } from "react";
import ComponentHeader from "../common/components/ComponentHeader";
import { RightArrowIcon } from "../common/icons";
import SelectTemplate from "../components/NewCampaign/SelectTemplate";
import EditCampaign from "../components/NewCampaign/EditCampaign";
import CampaignSetup from "../components/NewCampaign/CampaignSetup";

export const NewCampaignPage = () => {
  const [selectedCard, setSelectedCard] = useState(-1);
  const [currentStep, setCurrentStep] = useState(1);

  const calculateColor = (step) => {
    if (step === currentStep) {
      return "#4c8c4a";
    } else if (step < currentStep) {
      return "black";
    } else {
      return "#939291";
    }
  };

  return (
    <>
      <div className="max-w-screen-xl mx-auto">
        <ComponentHeader name={"New campaign"} />
      </div>
      <div className="w-full bg-white">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex flex-row items-center gap-3  p-4">
            <p className={`text-lg text-[${calculateColor(1)}] font-semibold`}>
              1. Select a template
            </p>
            <RightArrowIcon />
            <p className={`text-lg text-[${calculateColor(2)}] font-semibold`}>
              2. Edit campaign
            </p>
            <RightArrowIcon />
            <p className={`text-lg text-[${calculateColor(3)}] font-semibold`}>
              3. Campaign setup
            </p>
            <RightArrowIcon />
            <p className={`text-lg text-[${calculateColor(4)}] font-semibold`}>
              4. Create
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto">
        <div className="flex flex-col p-2 gap-3">
          {currentStep == 1 && (
            <SelectTemplate
              setSelectedCard={setSelectedCard}
              selectedCard={selectedCard}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 2 && (
            <EditCampaign setCurrentStep={setCurrentStep} />
          )}
          {currentStep === 3 && <CampaignSetup />}
        </div>
      </div>
    </>
  );
};
