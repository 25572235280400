import React from "react";
import { convertTo24HourFormat } from "../utils";
import { Typography } from "@mui/material";

const OutgoingMessage = ({ text, time }) => {
  return (
    <div className="mr-3 flex flex-col items-end my-4 sm:my-0">
      <div className="border rounded-t-[30px] rounded-tr-[30px] rounded-bl-[30px] sm:rounded-t-[45px] sm:rounded-tr-[45px] sm:rounded-bl-[45px] bg-[#4C8C4A] p-4 sm:p-5 w-[221px] text-white text-xs font-extralight sm:font-normal font-['Questrial'] leading-none">
        <Typography>{text}</Typography>
      </div>
      <span className="text-[#939291] text-xs">{convertTo24HourFormat(time)}</span>
    </div>
  );
};

export default OutgoingMessage;
